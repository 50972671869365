import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  IconButton,
  Snackbar,
  SnackbarContent,
  Icon,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Close as CloseIcon } from '@mui/icons-material';
import { removeError } from '@pingum/app-state-management/error/actions';

export const styles = (theme) => ({
  close: { padding: theme.spacing(0.5) },
  snackbar: { backgroundColor: theme.palette.error.dark },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const ErrorContainerComponent = (props) => (
  <div>
    {
      props.errors.map((error) => {
        const close = () => {
          props.removeError(error.id);
        };
        setTimeout(close, props.errorTimeout);
        return (
          <Snackbar
            key={error.id}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open
            onClose={close}
          >
            <SnackbarContent
              className={props.classes.snackbar}
              aria-describedby="message-id"
              message={(
                <span id="message-id" className={props.classes.message}>
                  <Icon className={props.classes.icon}> error </Icon>
                  {error.message}
                </span>
              )}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={props.classes.close}
                  onClick={close}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            />
          </Snackbar>
        );
      })
    }
  </div>
);

ErrorContainerComponent.propTypes = {
  classes: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    message: PropTypes.string,
    dismissable: PropTypes.bool,
  })),
  errorTimeout: PropTypes.number,
  removeError: PropTypes.func,
};

ErrorContainerComponent.defaultProps = {
  classes: {},
  errors: [],
  errorTimeout: 8000,
  removeError: () => {},
};

export const mapStateToProps = (state) => ({ errors: state.error.errorMessages });

export const mapDispatchToProps = (dispatch) => ({ removeError: (id) => dispatch(removeError(id)) });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ErrorContainerComponent));
