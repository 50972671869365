import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filter } from 'lodash';
import {
  Popover,
  List,
  ListItem,
  IconButton,
  Icon,
  Divider,
  Button,
  CircularProgress,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { hideNotifications as hideNotificationsAction } from '@pingum/app-state-management/app/actions';
import {
  markNotificationsAsViewed as markNotificationsAsViewedAction,
  getNextPageOfNotifications as getNextPageOfNotificationsAction,
  getUserNotifications as getUserNotificationsAction,
} from '@pingum/app-state-management/userNotification/actions';
import EmptyList from '../EmptyList';
import Notification from '../Notification';
import styles from './styles';

export class NotificationListComponent extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open && this.props.profile && this.props.profile.user_id) {
      // notifications opened so get new load of data
      this.props.getUserNotifications(this.props.profile.user_id);
    }
  }

  render() {
    const {
      notifications,
      getNextPageOfNotifications,
      open,
      anchorElement,
      hideNotifications,
      classes,
      markNotificationsAsViewed,
      profile,
      isFetching,
      fullScreen,
      onClose,
    } = this.props;
    const unviewedNotifications
      = filter(notifications.content, (notification) => notification.viewedTimestamp === 0);
    if (unviewedNotifications.length > 0 && open) {
      markNotificationsAsViewed(profile.user_id, unviewedNotifications);
    }

    return (
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorElement}
        aria-labelledby="notifications-list"
        anchorEl={anchorElement}
        onClose={() => { hideNotifications(); onClose(); }}
        classes={{
          paper:
          fullScreen
            ? classes.popoverPaperFullScreen
            : null,
        }}
      >
        <div
          className={
          fullScreen
            ? classes.notificationContainerFullScreen
            : classes.notificationContainer
        }
        >
          <div className={classes.notificationListHeader}>
            <h5
              className={classes.notificationListHeaderTitle}
              size="small"
            >
              Notifications
            </h5>
            <IconButton onClick={() => { hideNotifications(); onClose(); window.location.href = '/settings/notifications'; }}>
              <Icon className={classes.close}>settings</Icon>
            </IconButton>
            <div>
              <IconButton onClick={() => { hideNotifications(); onClose(); }}>
                <Icon className={classes.close}>close</Icon>
              </IconButton>
            </div>
          </div>
          <Divider />
          {
          !isFetching
            && notifications.content.length === 0
            && (
              <EmptyList
                title="You have no notifications."
                icon="info"
              />
            )
        }
          <List className={classes.list}>
            {
            notifications.content.map((notification) => (
              <Notification
                notification={notification}
                key={`notification-${notification.notificationTimestamp}`}
              />
            ))
          }
            {(
              !isFetching
              && !notifications.last
            )
              && (
                <ListItem>
                  <div className={classes.center}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => getNextPageOfNotifications(
                        profile.user_id,
                        (notifications.number + 1),
                        notifications.size,
                      )}
                    >
                      Load More
                    </Button>
                  </div>
                </ListItem>
              )}
          </List>

          {
          isFetching
            && (
              <div className={classes.centerProgress}>
                <CircularProgress />
              </div>
            )
        }

        </div>
      </Popover>
    );
  }
}

NotificationListComponent.propTypes = {
  notifications: PropTypes.object,
  open: PropTypes.bool,
  anchorElement: PropTypes.object,
  hideNotifications: PropTypes.func,
  classes: PropTypes.object,
  markNotificationsAsViewed: PropTypes.func,
  profile: PropTypes.object,
  isFetching: PropTypes.bool,
  fullScreen: PropTypes.bool,
  getNextPageOfNotifications: PropTypes.func,
  getUserNotifications: PropTypes.func,
  onClose: PropTypes.func,
};

NotificationListComponent.defaultProps = {
  notifications: {
    content: [],
    page: 0,
    size: 0,
    last: true,
  },
  open: false,
  anchorElement: null,
  hideNotifications: () => {},
  classes: {},
  markNotificationsAsViewed: () => {},
  profile: {},
  isFetching: false,
  fullScreen: false,
  getNextPageOfNotifications: () => {},
  getUserNotifications: () => {},
  onClose: () => {},
};

export const mapStateToProps = (state) => ({
  notifications: state.userNotification.userNotifications,
  open: state.pingumApp.showNotifications,
  profile: state.user.user,
  isFetching: state.userNotification.isFetchingUserNotifications,
});

export const mapDispatchToProps = (dispatch) => ({
  hideNotifications: () => dispatch(hideNotificationsAction()),
  markNotificationsAsViewed:
    (userId, notifications) => dispatch(markNotificationsAsViewedAction(userId, notifications)),
  getUserNotifications: (auth0UserId) => dispatch(getUserNotificationsAction(auth0UserId)),
  getNextPageOfNotifications:
    (userId, page, size) => dispatch(getNextPageOfNotificationsAction(userId, page, size)),
});

export default (withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationListComponent)));
