// User
export const USER_TENANTS_REQUESTED = 'USER_TENANTS_REQUESTED';
export const USER_TENANTS_SUCCESS = 'USER_TENANTS_SUCCESS';
export const USER_TENANTS_ERROR = 'USER_TENANTS_ERROR';
// Questri
export const UPDATE_ME_REQUESTED = 'UPDATE_ME_REQUESTED';
export const UPDATE_ME_SUCCESS = 'UPDATE_ME_SUCCESS';
export const UPDATE_ME_ERROR = 'UPDATE_ME_ERROR';
export const CHECK_EMAIL_REQUESTED = 'CHECK_EMAIL_REQUESTED';
export const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS';
export const CHECK_EMAIL_ERROR = 'CHECK_EMAIL_ERROR';
export const UPDATE_USER_REQUESTED = 'UPDATE_USER_REQUESTED';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const ADD_USER_REQUESTED = 'ADD_USER_REQUESTED';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';
export const DELETE_USER_REQUESTED = 'DELETE_USER_REQUESTED';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';
export const GET_ME_USER_REQUESTED = 'GET_ME_USER_REQUESTED';
export const GET_ME_USER_SUCCESS = 'GET_ME_USER_SUCCESS';
export const GET_ME_USER_ERROR = 'GET_ME_USER_ERROR';
export const GET_ME_BARN_USER_REQUESTED = 'GET_ME_BARN_USER_REQUESTED';
export const GET_ME_BARN_USER_SUCCESS = 'GET_ME_BARN_USER_SUCCESS';
export const GET_ME_BARN_USER_ERROR = 'GET_ME_BARN_USER_ERROR';
export const GET_BARN_USERS_REQUESTED = 'GET_BARN_USERS_REQUESTED';
export const GET_BARN_USERS_SUCCESS = 'GET_BARN_USERS_SUCCESS';
export const GET_BARN_USERS_ERROR = 'GET_BARN_USERS_ERROR';
export const GET_ME_HORSE_ACCESS_REQUESTED = 'GET_ME_HORSE_ACCESS_REQUESTED';
export const GET_ME_HORSE_ACCESS_SUCCESS = 'GET_ME_HORSE_ACCESS_SUCCESS';
export const GET_ME_HORSE_ACCESS_ERROR = 'GET_ME_HORSE_ACCESS_ERROR';
export const GET_BARN_USER_NOTES_REQUESTED = 'GET_BARN_USER_NOTES_REQUESTED';
export const GET_BARN_USER_NOTES_SUCCESS = 'GET_BARN_USER_NOTES_SUCCESS';
export const GET_BARN_USER_NOTES_ERROR = 'GET_BARN_USER_NOTES_ERROR';
// AUTH
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';
export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
