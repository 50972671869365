// import Joi from 'joi-browser';
import HttpService from '@pingum/api-services/HttpService';

// const numberOfDaysBetweenDates = (d1, d2) => {
//   const diff = Math.abs(d1.getTime() - d2.getTime());
//   return diff / (1000 * 60 * 60 * 24);
// };

export default class ScheduleExceptionService {
  static getScheduleExceptions(startTime, endTime, tags, page, size, sort) {
    return this.httpService.get(`?page=${page}&size=${size}${sort}&startTime=${startTime || new Date(10000)}&endTime=${endTime || new Date()}&tags=${tags || []}`);
  }

  static deleteSchedulExceptione(id) {
    if (!id) {
      return Promise.reject(new Error('Missing Schedule Exception ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.delete(`/${id}`).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }
}

ScheduleExceptionService.httpService = new HttpService({
  baseURL: `${typeof window !== 'undefined'
    ? env.REACT_APP_EVENT_API
    : ''}/scheduleExceptions`,
});
