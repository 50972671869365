import * as types from './types';
import * as horseTypes from '../horse/types';

export default (state = {
  feeds: { content: [] },
  isFetchingFeeds: false,
  horseFeeds: [],
  isFetchingHorseFeeds: false,
}, action = {}) => {
  switch (action.type) {
    case types.GET_FEED_REQUESTED:
      return {
        ...state,
        feeds: { content: [] },
        isFetchingFeeds: true,
      };
    case types.GET_FEED_SUCCESS:
      return {
        ...state,
        isFetchingFeeds: false,
        feeds: action.feeds,
      };
    case types.GET_FEED_ERROR:
      return {
        ...state,
        isFetchingFeeds: false,
      };
    case types.ADD_FEED_REQUESTED:
      return {
        ...state,
        isFetchingFeeds: true,
      };
    case types.ADD_FEED_SUCCESS:
      return {
        ...state,
        isFetchingFeeds: false,
        feeds: {
          ...state.feeds,
          content: [{
            ...action.newFeed,
            new: true, // signal in UI to show newly added feed
          }].concat(state.feeds.content),
        },
      };
    case types.ADD_FEED_ERROR:
      return {
        ...state,
        isFetchingFeeds: false,
      };
    case types.UPDATE_FEED_REQUESTED:
      return {
        ...state,
        isFetchingFeeds: true,
      };
    case types.UPDATE_FEED_SUCCESS:
      return {
        ...state,
        isFetchingFeeds: false,
        feeds: {
          ...state.feeds,
          content: state.feeds.content.map((f) => (f.id === action.updatedFeed.id
            ? action.updatedFeed
            : f)),
        }
        ,
      };
    case types.UPDATE_FEED_ERROR:
      return {
        ...state,
        isFetchingFeeds: false,
      };
    case types.DELETE_FEED_REQUESTED:
      return {
        ...state,
        isFetchingFeeds: true,
      };
    case types.DELETE_FEED_SUCCESS:
      return {
        ...state,
        isFetchingFeeds: false,
        feeds: {
          ...state.feeds,
          content: state.feeds.content.filter((f) => f.id !== action.deletedFeedId),
        },
      };
    case types.DELETE_FEED_ERROR:
      return {
        ...state,
        isFetchingFeeds: false,
      };
    case types.GET_HORSE_FEED_REQUESTED:
      return {
        ...state,
        horseFeeds: [],
        isFetchingHorseFeeds: true,
      };
    case types.GET_HORSE_FEED_SUCCESS:
      return {
        ...state,
        isFetchingHorseFeeds: false,
        horseFeeds: action.horseFeeds,
      };
    case types.GET_HORSE_FEED_ERROR:
      return {
        ...state,
        isFetchingHorseFeeds: false,
      };
    /*
    HORSE FEEDS
    */
    case horseTypes.ADD_HORSE_FEED_SUCCESS:
      return {
        ...state,
        isFetchingHorseFeeds: false,
        horseFeeds: [{
          ...action.newHorseFeed,
          new: true, // signal in UI to show newly added feed
        }].concat(state.horseFeeds),
      };
    case horseTypes.UPDATE_HORSE_FEED_SUCCESS:
      return {
        ...state,
        isFetchingHorseFeeds: false,
        horseFeeds: state.horseFeeds.map((f) => (f.id === action.updatedHorseFeed.id
          ? action.updatedHorseFeed
          : f)),
      };
    case horseTypes.DELETE_HORSE_FEED_SUCCESS:
      return {
        ...state,
        isFetchingHorseFeeds: false,
        horseFeeds: state.horseFeeds.filter((f) => !(f.horse.id === action.deletedHorseId && f.feed.id === action.deletedFeedId)),
      };
    default:
      return state;
  }
};

