import { createBrowserHistory } from 'history';
import { UserService } from '@pingum/api-services';
import { requestTenant } from '@pingum/app-state-management/tenant/actions';
import QuestriUserService from '../../services/QuestriUserService';
import BarnService from '../../services/BarnService';
import * as types from './types';
import { dispatchNetworkRequest } from '../networkDispatcher';
import { getTenantFromUrl } from '../../utils/urlHelper';
import { redirectToSubdomain } from '../../utils/tenantAuthenticationHelper';
import {
  getBarns,
  getBarnUsers,
} from '../barn/actions';
import { getStreamUser } from '../stream/actions';

export const handleLoginRequest = ({
  promise,
  history,
}) => {
  promise.then((user) => {
    if (!UserService.userHasTenants(user)) {
      history.push({ pathname: '/welcome' });
      history.go();
    } else if (!getTenantFromUrl()) {
      const tenant = global.window.localStorage.getItem('redirectTenant');
      const redirect = global.window.localStorage.getItem('redirect') || '/dashboards';
      window.localStorage.removeItem('redirectTenant');
      if (tenant) {
        redirectToSubdomain({
          tenant,
          redirect,
        });
      } else {
        history.push({ pathname: '/organizations' });
        history.go();
      }
    }
  }).catch((error) => {
    console.error(error);
  });
};

export const getMyTenants = () => (dispatch) => {
  dispatchNetworkRequest({
    dispatch,
    requestType: types.USER_TENANTS_REQUESTED,
    successType: types.USER_TENANTS_SUCCESS,
    errorType: types.USER_TENANTS_ERROR,
    promise: BarnService.getBarns(),
    defaultErrorMessage: 'There was a problem loading your organizations',
    responseName: 'tenants',
  });
};

export const getMeBarnUser = ({ history = createBrowserHistory() } = {}) => (dispatch) => {
  // eslint-disable-next-line no-console
  const promise = QuestriUserService.getMe();
  handleLoginRequest({
    promise,
    history,
  });
  dispatchNetworkRequest({
    dispatch,
    requestType: types.GET_ME_BARN_USER_REQUESTED,
    successType: types.GET_ME_BARN_USER_SUCCESS,
    errorType: types.GET_ME_BARN_USER_ERROR,
    promise: QuestriUserService.getMe(),
    defaultErrorMessage: 'There was a problem getting your Questri user profile',
    successMessage: 'Successfully got your questri user profile.',
    responseName: 'barnUser',
  });
};

export const addUser = (addUserRequest) => (dispatch) => {
  const promise = QuestriUserService.addUser(addUserRequest);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.ADD_USER_REQUESTED,
    successType: types.ADD_USER_SUCCESS,
    errorType: types.ADD_USER_ERROR,
    promise,
    defaultErrorMessage: 'There was a problem adding the user',
    successMessage: 'Successfully added the user',
    responseName: 'user',
  });
  return promise;
};

export const updateUser = (id, updateUserRequest, isSelf = false) => (dispatch) => {
  const promise = QuestriUserService.updateUser(id, updateUserRequest, isSelf);
  dispatchNetworkRequest({
    dispatch,
    requestType: isSelf
      ? types.UPDATE_ME_REQUESTED
      : types.UPDATE_USER_REQUESTED,
    successType: isSelf
      ? types.UPDATE_ME_SUCCESS
      : types.UPDATE_USER_SUCCESS,
    errorType: isSelf
      ? types.UPDATE_ME_ERROR
      : types.UPDATE_USER_ERROR,
    promise,
    defaultErrorMessage: 'There was a problem updating your user information',
    successMessage: 'Successfully updated your profile',
    responseName: 'user',
  });
  return promise;
};

export const deleteUser = (id) => (dispatch) => {
  const promise = QuestriUserService.deleteUser(id);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.DELETE_USER_REQUESTED,
    successDispatch: {
      type: types.DELETE_USER_SUCCESS,
      deletedBarnUserId: id,
    },
    successType: types.DELETE_USER_SUCCESS,
    errorType: types.DELETE_USER_ERROR,
    promise,
    defaultErrorMessage: 'There was a problem deleting user',
    successMessage: 'Successfully deleted user',
    responseName: 'user',
  });
  return promise;
};

export const getMeHorseAccess = () => (dispatch) => {
  const promise = QuestriUserService.getMeHorseAccess();
  dispatchNetworkRequest({
    dispatch,
    requestType: types.GET_ME_HORSE_ACCESS_REQUESTED,
    successType: types.GET_ME_HORSE_ACCESS_SUCCESS,
    errorType: types.GET_ME_HORSE_ACCESS_ERROR,
    promise,
    defaultErrorMessage: 'There was a problem loading your horse access',
    responseName: 'horseAccess',
  });
  return promise;
};

export const checkEmail = (email) => (dispatch) => {
  dispatch({ type: types.CHECK_EMAIL_REQUESTED });
  // eslint-disable-next-line no-console
  const promise = QuestriUserService.checkEmail(email);

  promise.then((foundAccount) => {
    if (foundAccount && foundAccount.auth0UserId == null && foundAccount.email.toLowerCase() === email.toLowerCase()) {
      // New user logging in, no linked account yet so let's link it!
      QuestriUserService.linkMeBarnUser(email).then(async (updatedBarnUser) => {
      // Account is now linked so we need to regrab some data

        dispatch(getMeBarnUser());
        setTimeout(() => {
          const tenant = getTenantFromUrl();
          dispatch(requestTenant(tenant));
          dispatch(getBarns());
          dispatch(getBarnUsers());
          dispatch(getMeHorseAccess());
          dispatch(checkEmail(email));
          dispatch(getMyTenants());
          dispatch(getStreamUser());
        }, 2000)

        dispatch({
          type: types.CHECK_EMAIL_SUCCESS,
          checkEmailUser: updatedBarnUser,
        });
      }).catch((error) => {
        console.error(error);
        dispatch({
          type: types.CHECK_EMAIL_SUCCESS,
          checkEmailUser: foundAccount,
          organizationAuth0UserLimitReached: error && (error.status === 409 || (error.data && error.data.status === 409)),
        });
      })
    } else {
      dispatch({
        type: types.CHECK_EMAIL_SUCCESS,
        checkEmailUser: foundAccount,
      });
    }
  }).catch((error) => {
    console.error(error);
    dispatch({ type: types.CHECK_EMAIL_ERROR });
  })
  return promise
};
