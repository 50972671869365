import { grey } from '../../utils/brandColors';

export default () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
    padding: '2em',
  },
  title: {
    fontSize: '1.4em',
    lineHeight: 1.2,
    margin: '.5em 0',
    color: grey[800],
  },
  description: {},
  icon: {
    color: grey[400],
    fontSize: '4em',
  },
  flex: { flex: 1 },
});
