// import { BarnService } from '@pingum/api-services';
import SearchService from '../../services/SearchService';
import * as types from './types';
import { dispatchNetworkRequest } from '../networkDispatcher';

export const searchQuestri = (search, page = 0, pageSize = 25, sort = '') => (dispatch) => {
  const promise = SearchService.search(search, page, pageSize, sort);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.SEARCH_REQUESTED,
    successType: types.SEARCH_SUCCESS,
    errorType: types.SEARCH_ERROR,
    promise,
    defaultErrorMessage: 'There was a problem trying to search for this result',
    responseName: 'search',
  });
  return promise;
};
