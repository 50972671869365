import { groupBy } from 'lodash';
import * as types from './types';
import * as horseTypes from '../horse/types';

export default (state = {
  locations: { content: [] },
  isFetchingLocations: false,
  isFetchingAllLocation: false,
  locationsMap: {},
  allLocations: [],
  selectedLocation: {},
  selectedLocationHorseNotes: { content: [] },
  isFetchingLocationHorseNotes: false,
  selectedLocationHorseFeeds: { content: [] },
  isFetchingLocationHorseFeeds: false,
  locationHorses: { content: [] },
  isFetchingLocationHorses: false,
}, action = {}) => {
  switch (action.type) {
    case types.GET_LOCATION_REQUESTED:
      return {
        ...state,
        locations: { content: [] },
        isFetchingLocations: true,
      };
    case types.GET_LOCATION_SUCCESS:
      global.window.localStorage.getItem('barn_board_location');
      return {
        ...state,
        isFetchingLocations: false,
        locations: action.locations,
      };
    case types.GET_LOCATION_ERROR:
      return {
        ...state,
        isFetchingLocations: false,
      };
    case types.GET_LOCATION_HORSES_REQUESTED:
      return {
        ...state,
        locationHorses: { content: [] },
        isFetchingLocationHorses: true,
      };
    case types.GET_LOCATION_HORSES_SUCCESS:
      return {
        ...state,
        isFetchingLocationHorses: false,
        locationHorses: action.locationHorses,
      };
    case types.GET_LOCATION_HORSES_ERROR:
      return {
        ...state,
        isFetchingLocationHorses: false,
      };
    case types.GET_ALL_LOCATIONS_REQUESTED:
      return {
        ...state,
        isFetchingAllLocation: false,
        allLocations: [],
        locationsMap: {},
      };
    case types.GET_ALL_LOCATIONS_SUCCESS:
      return {
        ...state,
        isFetchingAllLocation: false,
        allLocations: action.locations,
        locationsMap: {
          ...groupBy(action.locations, 'id'),
          ...groupBy(action.locations, 'pingumTagId'),
        },
      };
    case types.ADD_LOCATION_REQUESTED:
      return {
        ...state,
        isFetchingLocations: true,
      };
    case types.ADD_LOCATION_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const allLocation = [...state.allLocations, action.newLocation];
      return {
        ...state,
        isFetchingLocations: false,
        locations: {
          ...state.locations,
          content: [{
            ...action.newLocation,
            new: true, // signal in UI to show newly added location
          }].concat(state.locations.content),
        },
        allLocations: allLocation,
        locationsMap: {
          ...groupBy(allLocation, 'id'),
          ...groupBy(allLocation, 'pingumTagId'),
        },
      };
    case types.ADD_LOCATION_ERROR:
      return {
        ...state,
        isFetchingLocations: false,
      };
    case types.UPDATE_LOCATION_REQUESTED:
      return {
        ...state,
        isFetchingLocations: true,
      };
    case types.UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        isFetchingLocations: false,
        locations: {
          ...state.locations,
          content: state.locations.content.map((f) => (f.id === action.updatedLocation.id
            ? action.updatedLocation
            : f)),
        },
        selectedLocation: (state.selectedLocation && state.selectedLocation.id && action.updatedLocation.id === state.selectedLocation.id)
          ? action.updatedLocation
          : state.selectedLocation,
      };
    case types.UPDATE_LOCATION_ERROR:
      return {
        ...state,
        isFetchingLocations: false,
      };
    case types.DELETE_LOCATION_REQUESTED:
      return {
        ...state,
        isFetchingLocations: true,
      };
    case types.DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        isFetchingLocations: false,
        locations: {
          ...state.locations,
          content: state.locations.content.filter((f) => f.id !== action.deletedLocationId),
        },
      };
    case types.DELETE_LOCATION_ERROR:
      return {
        ...state,
        isFetchingLocations: false,
      };
    case types.SELECTED_LOCATION_CHANGED:

      return {
        ...state,
        selectedLocation: action.selectedLocation,
      };
    case types.GET_LOCATION_HORSE_NOTES_REQUESTED:
      return {
        ...state,
        isFetchingLocationHorseNotes: true,
      };
    case types.GET_LOCATION_HORSE_NOTES_SUCCESS:
      return {
        ...state,
        isFetchingLocationHorseNotes: false,
        selectedLocationHorseNotes: action.horseNotes,
      };
    case types.GET_LOCATION_HORSE_NOTES_ERROR:
      return {
        ...state,
        isFetchingLocationHorseNotes: false,
      };
    case types.GET_LOCATION_HORSE_FEEDS_REQUESTED:
      return {
        ...state,
        isFetchingLocationHorseFeeds: true,
      };
    case types.GET_LOCATION_HORSE_FEEDS_SUCCESS:
      return {
        ...state,
        isFetchingLocationHorseFeeds: false,
        selectedLocationHorseFeeds: action.horseFeeds,
      };
    case types.GET_LOCATION_HORSE_FEEDS_ERROR:
      return {
        ...state,
        isFetchingLocationHorseFeeds: false,
      };
    case horseTypes.ADD_HORSE_FEED_SUCCESS:
      return {
        ...state,
        selectedLocationHorseFeeds: {
          ...state.selectedLocationHorseFeeds,
          content: [{
            ...action.newHorseFeed,
            new: true, // signal in UI to show newly added feed
          }].concat(state.selectedLocationHorseFeeds.content),
        },
      };
    case horseTypes.UPDATE_HORSE_FEED_SUCCESS:
      return {
        ...state,
        selectedLocationHorseFeeds: {
          ...state.selectedLocationHorseFeeds,
          content: state.selectedLocationHorseFeeds.content.map((f) => (f.id === action.updatedHorseFeed.id
            ? action.updatedHorseFeed
            : f)),
        },
      };
    case horseTypes.DELETE_HORSE_FEED_SUCCESS:
      return {
        ...state,
        selectedLocationHorseFeeds: {
          ...state.selectedLocationHorseFeeds,
          content: state.selectedLocationHorseFeeds.content
            .filter((f) => !(f.horse.id === action.deletedHorseId && f.feed.id === action.deletedFeedId)),
        },
      };
    default:
      return state;
  }
};
