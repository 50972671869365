/* eslint-disable no-duplicate-case */
import * as types from './types';

export default (state = {
  tagReminders: { content: [] },
  isFetchingTagReminders: false,
  // TAG
  tagTagReminders: [],
  isFetchingTagTagReminders: false,
  // MAX DUE DATE
  tagRemindersForDueDate: [],
  isFetchingTagRemindersForDueDate: false,
}, action = {}) => {
  switch (action.type) {
    case types.GET_TAG_REMINDERS_REQUESTED:
      return {
        ...state,
        tagReminders: { content: [] },
        isFetchingTagReminders: true,
      };
    case types.GET_TAG_REMINDERS_SUCCESS:
      return {
        ...state,
        isFetchingTagReminders: false,
        tagReminders: action.tagReminders,
      };
    case types.GET_TAG_REMINDERS_ERROR:
      return {
        ...state,
        isFetchingTagReminders: false,
      };
    case types.GET_TAG_REMINDER_SUCCESS:
      return {
        ...state,
        isFetchingTagReminders: false,
        tagReminders: {
          ...state.tagReminders,
          content: state.tagReminders.content.map((tr) => ((action.tagReminder && tr.id === action.tagReminder.id)
            ? action.tagReminder
            : tr)),
        },
        tagTagReminders: state.tagTagReminders.map((tr) => ((action.tagReminder && tr.id === action.tagReminder.id)
          ? action.tagReminder
          : tr)),
      };
    case types.GET_TAG_TAG_REMINDERS_REQUESTED:
      return {
        ...state,
        tagTagReminders: [],
        isFetchingTagTagReminders: true,
      };
    case types.GET_TAG_TAG_REMINDERS_SUCCESS:
      return {
        ...state,
        isFetchingTagTagReminders: false,
        tagTagReminders: action.tagReminders,
      };
    case types.GET_TAG_TAG_REMINDERS_ERROR:
      return {
        ...state,
        isFetchingTagTagReminders: false,
      };
    case types.GET_TAG_REMINDERS_MAX_DUE_DATE_REQUESTED:
      return {
        ...state,
        tagRemindersForDueDate: [],
        isFetchingTagRemindersForDueDate: true,
      };
    case types.GET_TAG_REMINDERS_MAX_DUE_DATE_SUCCESS:
      return {
        ...state,
        isFetchingTagReminders: false,
        isFetchingTagRemindersForDueDate: action.tagRemindersForDueDate,
      };
    case types.GET_TAG_REMINDERS_MAX_DUE_DATE_ERROR:
      return {
        ...state,
        isFetchingTagRemindersForDueDate: false,
      };
    default:
      return state;
  }
};

