import PingumIcon from '../images/icon-with-padding.png';
import store from '../redux/store';

export const getUser = (user, barnUsersMap = store.getState().barn.barnUsersMap) => {
  if (user.auth0UserId === 'Questri' || user.email === 'noreply@questri.app') {
    return {
      ...user,
      avatar: PingumIcon,
      userFullName: 'Questri System',
    };
  }
  if (barnUsersMap[user.auth0UserId]) {
    return barnUsersMap[user.auth0UserId][0];
  }
  if (barnUsersMap[user.email]) {
    return barnUsersMap[user.email][0];
  }
  return user;
};
