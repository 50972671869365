// Barn
export const GET_BARNS_REQUESTED = 'GET_BARNS_REQUESTED';
export const GET_BARNS_SUCCESS = 'GET_BARNS_SUCCESS';
export const GET_BARNS_ERROR = 'GET_BARNS_ERROR';
export const SELECT_BARN = 'SELECT_BARN';
export const GET_BARN_USERS_REQUESTED = 'GET_BARN_USERS_REQUESTED';
export const GET_BARN_USERS_SUCCESS = 'GET_BARN_USERS_SUCCESS';
export const GET_BARN_USERS_ERROR = 'GET_BARN_USERS_ERROR';
export const GET_BARN_NOTICES_REQUESTED = 'GET_BARN_NOTICES_REQUESTED';
export const GET_BARN_NOTICES_SUCCESS = 'GET_BARN_NOTICES_SUCCESS';
export const GET_BARN_NOTICES_ERROR = 'GET_BARN_NOTICES_ERROR';
export const GET_BARN_HORSES_REQUESTED = 'GET_BARN_HORSES_REQUESTED';
export const GET_BARN_HORSES_SUCCESS = 'GET_BARN_HORSES_SUCCESS';
export const GET_BARN_HORSES_ERROR = 'GET_BARN_HORSES_ERROR';
export const GET_ALL_BARN_HORSES_REQUESTED = 'GET_ALL_BARN_HORSES_REQUESTED';
export const GET_ALL_BARN_HORSES_SUCCESS = 'GET_ALL_BARN_HORSES_SUCCESS';
export const GET_ALL_BARN_HORSES_ERROR = 'GET_ALL_BARN_HORSES_ERROR';

export const UPDATE_BARN_HORSE_REQUESTED = 'UPDATE_BARN_HORSE_REQUESTED';
export const UPDATE_BARN_HORSE_SUCCESS = 'UPDATE_BARN_HORSE_SUCCESS';
export const UPDATE_BARN_HORSE_ERROR = 'UPDATE_BARN_HORSE_ERROR';
export const UPDATE_BARN_REQUESTED = 'UPDATE_BARN_REQUESTED';
export const UPDATE_BARN_SUCCESS = 'UPDATE_BARN_SUCCESS';
export const UPDATE_BARN_ERROR = 'UPDATE_BARN_ERROR';
export const SET_QUESTRI_SUBSCRIPTION = 'SET_QUESTRI_SUBSCRIPTION';
