// Schedule
export const GET_SCHEDULES_REQUESTED = 'GET_SCHEDULES_REQUESTED';
export const GET_SCHEDULES_SUCCESS = 'GET_SCHEDULES_SUCCESS';
export const GET_SCHEDULES_ERROR = 'GET_SCHEDULES_ERROR';
export const GET_SCHEDULE_REQUESTED = 'GET_SCHEDULE_REQUESTED';
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export const GET_SCHEDULE_ERROR = 'GET_SCHEDULE_ERROR';
export const REMOVE_SELECTED_SCHEDULE = 'REMOVE_SELECTED_SCHEDULE';
export const GET_SCHEDULE_SCHEDULE_EXCEPTIONS_REQUESTED = 'GET_SCHEDULE_SCHEDULE_EXCEPTIONS_REQUESTED';
export const GET_SCHEDULE_SCHEDULE_EXCEPTIONS_SUCCESS = 'GET_SCHEDULE_SCHEDULE_EXCEPTIONS_SUCCESS';
export const GET_SCHEDULE_SCHEDULE_EXCEPTIONS_ERROR = 'GET_SCHEDULE_SCHEDULE_EXCEPTIONS_ERROR';
export const GET_EVENT_TEMPLATE_SCHEDULES_REQUESTED = 'GET_EVENT_TEMPLATE_SCHEDULES_REQUESTED';
export const GET_EVENT_TEMPLATE_SCHEDULES_SUCCESS = 'GET_EVENT_TEMPLATE_SCHEDULES_SUCCESS';
export const GET_EVENT_TEMPLATE_SCHEDULES_ERROR = 'GET_EVENT_TEMPLATE_SCHEDULES_ERROR';
export const UPDATE_SCHEDULE_REQUESTED = 'UPDATE_SCHEDULE_REQUESTED';
export const UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS';
export const UPDATE_SCHEDULE_ERROR = 'UPDATE_SCHEDULE_ERROR';
export const ADD_SCHEDULE_REQUESTED = 'ADD_SCHEDULE_REQUESTED';
export const ADD_SCHEDULE_SUCCESS = 'ADD_SCHEDULE_SUCCESS';
export const ADD_SCHEDULE_ERROR = 'ADD_SCHEDULE_ERROR';
export const DELETE_SCHEDULE_REQUESTED = 'DELETE_SCHEDULE_REQUESTED';
export const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS';
export const DELETE_SCHEDULE_ERROR = 'DELETE_SCHEDULE_ERROR';
// Global Modal
export const SET_SCHEDULE_DATA_FOR_MODALS = 'SET_SCHEDULE_DATA_FOR_MODALS';

// Schedule Exceptions
export const GET_SCHEDULE_EXCEPTIONS_REQUESTED = 'GET_SCHEDULE_EXCEPTIONS_REQUESTED';
export const GET_SCHEDULE_EXCEPTIONS_SUCCESS = 'GET_SCHEDULE_EXCEPTIONS_SUCCESS';
export const GET_SCHEDULE_EXCEPTIONS_ERROR = 'GET_SCHEDULE_EXCEPTIONS_ERROR';
export const ADD_SCHEDULE_EXCEPTION_REQUESTED = 'ADD_SCHEDULE_EXCEPTION_REQUESTED';
export const ADD_SCHEDULE_EXCEPTION_SUCCESS = 'ADD_SCHEDULE_EXCEPTION_SUCCESS';
export const ADD_SCHEDULE_EXCEPTION_ERROR = 'ADD_SCHEDULE_EXCEPTION_ERROR';
export const DELETE_SCHEDULE_EXCEPTION_REQUESTED = 'DELETE_SCHEDULE_EXCEPTION_REQUESTED';
export const DELETE_SCHEDULE_EXCEPTION_SUCCESS = 'DELETE_SCHEDULE_EXCEPTION_SUCCESS';
export const DELETE_SCHEDULE_EXCEPTION_ERROR = 'DELETE_SCHEDULE_EXCEPTION_ERROR';

// Schedule Responses
export const GET_SCHEDULE_RESPONSES_REQUESTED = 'GET_SCHEDULE_RESPONSES_REQUESTED';
export const GET_SCHEDULE_RESPONSES_SUCCESS = 'GET_SCHEDULE_RESPONSES_SUCCESS';
export const GET_SCHEDULE_RESPONSES_ERROR = 'GET_SCHEDULE_RESPONSES_ERROR';
export const ADD_SCHEDULE_RESPONSES_REQUESTED = 'ADD_SCHEDULE_RESPONSES_REQUESTED';
export const ADD_SCHEDULE_RESPONSES_SUCCESS = 'ADD_SCHEDULE_RESPONSES_SUCCESS';
export const ADD_SCHEDULE_RESPONSES_ERROR = 'ADD_SCHEDULE_RESPONSES_ERROR';

// Events
export const SET_SCHEDULE_EVENTS = 'SET_SCHEDULE_EVENTS';
export const SET_SCHEDULE_EVENT_TASKS = 'SET_SCHEDULE_EVENT_TASKS';
