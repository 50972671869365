/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  useLocation,
  useNavigate,
  Routes,
  Route,
} from 'react-router-dom';
import { clarity } from 'react-microsoft-clarity';
import PropTypes from 'prop-types';
import 'stream-chat-react/dist/css/v2/index.css';
import {
  getTenantUserRequests,
  requestTenant,
} from '@pingum/app-state-management/tenant/actions';
import { searchEventTemplates } from '@pingum/app-state-management/eventTemplate/actions';
import { getStatuses } from '@pingum/app-state-management/status/actions';
import { getEventData } from '@pingum/app-state-management/eventData/actions';
import { getTags } from '@pingum/app-state-management/tag/actions';
import { getTagTypes } from '@pingum/app-state-management/tagType/actions';

import { getUserSubscription } from '@pingum/app-state-management/userSubscription/actions';
import { StreamChat } from 'stream-chat';
import { Chat } from 'stream-chat-react';
import { formLabelClasses } from '@mui/material';
import moment from 'moment';
import AppContainer from './navigation/AppContainer';
import {
  getMeBarnUser,
  getMyTenants,
  getMeHorseAccess,
  checkEmail,
} from './redux/user/actions';
import { getStreamUser } from './redux/stream/actions';
import {
  getAllLocations,
  setSelectedLocation,
} from './redux/location/actions';
import {
  getBarns,
  getBarnUsers,
  getAllBarnHorses,
  getSubscriptions,
} from './redux/barn/actions';
import { setTimezones } from './redux/app/actions';
import { getTenantFromUrl } from './utils/urlHelper';
import { getEventTemplateReminders } from './redux/eventTemplateReminder/actions';

moment.updateLocale('en', {
  week: {
    dow: (window.localStorage.getItem('planning_week_style') || 'isoWeek') === 'isoWeek'
      ? 1 // First day of week is Monday
      : 0,
    doy: 4, // First week of year must contain 4 January (7 + 1 - 4)
  },
});

export const history = createBrowserHistory();
const apiKey = process.env.REACT_APP_STREAM_KEY || 'agrvjewmw5nx';
const selectedLocationResetPaths = ['/settings/tasks'];

const AppComponent = ({ pages }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tenant = getTenantFromUrl();
  const { pathname } = useLocation();

  // auth0 state
  const {
    isLoading: isLoadingAuth0,
    isAuthenticated,
    user,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  React.useEffect(() => {
    clarity.init('i9yzdty460');
  }, [])

  const [selectedBarnId, setSelectedBarnId] = React.useState(null);
  const [chatConnected, setChatConnected] = React.useState(false);
  const [unreadCount, setUnreadCount] = React.useState(0);
  const [chatClient] = React.useState(StreamChat.getInstance(apiKey, {
    enableInsights: true,
    enableWSFallback: true,
  }))

  // redux state
  const isFetchingUserTenants = useSelector((state) => state.user.isFetchingUserTenants);
  const isFetchingUser = useSelector((state) => state.user.isFetchingUser);
  const isFetchingMeHorseAccess = useSelector((state) => state.user.isFetchingMeHorseAccess);
  const permissions = useSelector((state) => state.user.permissions);
  const isCheckingEmail = useSelector((state) => state.user.isCheckingEmail);
  const isFetchingTenant = useSelector((state) => state.tenant.isFetchingTenant);
  const isFetchingBarnUsers = useSelector((state) => state.barn.isFetchingBarnUsers);
  const selectedBarn = useSelector((state) => state.barn.selectedBarn);
  const selectedLocation = useSelector((state) => state.location.selectedLocation);
  const barnUser = useSelector((state) => state.user.barnUser);
  const streamUser = useSelector((state) => state.stream.streamUser);
  // global loading state - when true will show loading placeholder
  const isLoading = isFetchingUserTenants || isFetchingUser || isFetchingTenant
    || isFetchingBarnUsers || isFetchingMeHorseAccess || isLoadingAuth0 || isCheckingEmail;

  // on path change
  React.useEffect(() => {
    const fetchData = async () => {
      if (selectedLocationResetPaths.indexOf(pathname) >= 0) {
        // remove any selected location on these paths
        dispatch(setSelectedLocation({}));
      }
      // Reauth every hour
      let lastAuthTimestamp = window.localStorage.getItem('auth_timestamp') || new Date().getTime();
      lastAuthTimestamp = parseInt(lastAuthTimestamp, 10);
      if (new Date().getTime() - lastAuthTimestamp > 3600000) {
        getAccessTokenSilently({ cacheMode: 'off' });
        const accessToken = await getAccessTokenSilently({ cacheMode: 'off' });
        global.window.localStorage.setItem('access_token', accessToken);
        global.window.localStorage.setItem('auth_timestamp', new Date().getTime());
      }
    }
    fetchData();
  }, [pathname]);

  React.useEffect(() => {
    const listenerNewMessage = chatClient?.on((event) => {
      if (event.total_unread_count !== undefined) {
        setUnreadCount(event.total_unread_count)
      }
    });

    return () => {
      if (listenerNewMessage) {
        listenerNewMessage.unsubscribe();
      }
    };
  }, [chatClient]);

  // on authenticated change or token retrieved
  React.useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        global.window.localStorage.setItem('access_token', accessToken);
        global.window.localStorage.setItem('id_token', idToken.__raw);
        global.window.localStorage.setItem('auth_timestamp', new Date().getTime());
        if (tenant) {
          dispatch(requestTenant(tenant));
          dispatch(getBarns());
          dispatch(getBarnUsers());
          dispatch(getMeHorseAccess());
          dispatch(checkEmail(user.email));
          dispatch(getUserSubscription(user.sub));
          dispatch(getTenantUserRequests(tenant))
        }
        dispatch(await getMeBarnUser({ history }));
        dispatch(getMyTenants());
        dispatch(getStreamUser());

        if (window.location.pathname.indexOf(pathname) < 0) {
          navigate(pathname)
        }
      } else if (!isLoadingAuth0) {
        global.window.localStorage.removeItem('id_token');
        global.window.localStorage.removeItem('access_token');
        global.window.localStorage.removeItem('auth_timestamp');
      }
    }
    fetchData();
  }, [isAuthenticated]);

  React.useEffect(() => {
    if (barnUser && streamUser && streamUser.auth0UserId && streamUser.streamToken) {
      // eslint-disable-next-line no-console
      console.log('Connecting to chat')
      chatClient.connectUser({
        id: streamUser.auth0UserId.replace('|', '').replace('.', ''),
        image: (barnUser && barnUser.picture),
        name: (barnUser && barnUser.userFullName) || 'Unknown User',
      }, streamUser.streamToken).then((connection) => {
        setChatConnected(connection && connection.connection_id);
        setUnreadCount(connection?.me?.total_unread_count)
      });
    } else if (chatClient && chatConnected) {
      // eslint-disable-next-line no-console
      console.log('Disconnecting from chat')
      chatClient.disconnectUser();
      setChatConnected(false);
    }
    return () => {
      if (chatClient && chatConnected) {
        // eslint-disable-next-line no-console
        console.log('Disconnecting from chat')
        chatClient.disconnectUser();
        setChatConnected(formLabelClasses);
      }
    };
  }, [barnUser, streamUser])

  React.useEffect(() => {
    const fetchData = async () => {
      if (selectedBarnId) {
        dispatch(getAllBarnHorses(selectedBarnId));
        dispatch(getAllLocations()).then((data) => {
          if (data.length === 0) {
          // No locations so must have missed out on onboarding, take to onboarding
            navigate('/onboarding')
          }
        });
        dispatch(getStatuses(false));
        dispatch(getEventData(false));
        dispatch(getTags(false, false));
        dispatch(getTagTypes(false, true));
        dispatch(getSubscriptions(selectedBarn));
        dispatch(getEventTemplateReminders());
        dispatch(searchEventTemplates(
          false,
          {
            tagConditions: [[
              selectedBarn.barnMetadata.horseTaskTypeTagId,
              selectedBarn.barnMetadata.barnTaskTypeTagId,
              selectedBarn.barnMetadata.eventTaskTypeTagId]],
          },
        ));
      }
    }
    fetchData();
  }, [selectedBarnId]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (selectedBarn && selectedBarn.id && selectedBarn.id !== selectedBarnId) {
        setSelectedBarnId(selectedBarn.id)
      }
    }
    fetchData();
  }, [selectedBarn]);

  React.useEffect(() => {
    dispatch(setTimezones(selectedLocation && selectedLocation.timezone));
  }, [selectedLocation]);

  React.useEffect(() => {
    const fetchData = async () => {
    // on page load attempt auth
      const accessToken = await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      global.window.localStorage.setItem('access_token', accessToken);
      global.window.localStorage.setItem('id_token', idToken.__raw);
      global.window.localStorage.setItem('auth_timestamp', new Date().getTime());
    }
    fetchData();
  }, []);

  return (
    <AppContainer isLoading={isLoading} navigate={navigate}>
      <Routes>
        {/* Auth or app state pages */}
        <Route exact path="/callback" element={<div>callback</div>} title="Login | Questri" />
        <Route exact path="/confirm-email" element={<pages.ConfirmEmailPage />} title="Please confirm your email address | Questri" /> {/* TODO is that page still needed? */}
        <Route exact path="/logout" element={<pages.LogoutPage />} title="Logged out | Questri" />
        <Route exact path="/authorization-error" element={<pages.AuthorizationError />} title="Unauthorized | Questri" />

        {/* Account pages */}
        <Route exact path="/settings/profile" element={<pages.AccountProfilePage navigate={navigate} />} title="Account Profile | Questri" />
        <Route exact path="/settings/notifications" element={<pages.AccountNotificationsPage navigate={navigate} />} title="Account Notifications | Questri" />

        {/* Public Schedules */}
        <Route exact path="/schedules/:id" element={<pages.SchedulePage navigate={navigate} />} title="Calendar | Questri" />
        <Route exact path="/invoices/:id" element={<pages.BillingInvoicePage navigate={navigate} />} title="Invoice | Questri" />
        <Route exact path="/public/horses/:id/shared/:shareId" element={<pages.PublicHorsePage navigate={navigate} />} title="Horse Profile | Questri" />

        {
          (permissions.owner || permissions.admin || permissions.staff) && (
            <>
              {/* Tasks */}
              <Route exact path="/" element={<pages.PlanningPage navigate={navigate} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Tasks | Questri" />
              <Route exact path="/tasks/planning" element={<pages.PlanningPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Task Planning | Questri" />
              <Route exact path="/tasks/calendar" element={<pages.CalendarPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Task Calendar | Questri" />
              <Route exact path="/tasks" element={<pages.TasksPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="All Tasks | Questri" />
              <Route exact path="/tasks/:id" element={<pages.TaskPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Task | Questri" />
              <Route exact path="/tasks/analytics" element={<pages.TasksAnalyticsPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Task Analytics | Questri" />
              <Route exact path="/tasks/reminders" element={<pages.TaskRemindersPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Task Reminders | Questri" />

              {/* Barn and Boards */}
              <Route exact path="/board" element={<pages.BarnBoardPage navigate={navigate} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Barn Board | Questri" />

              {/* Onboarding */}
              <Route exact path="/onboarding" element={<pages.OnboardingPage navigate={navigate} permissions={permissions} />} title="Welcome To Questri | Questri" />

              {/* User */}
              <Route exact path="/users/:id" element={<pages.UserPage navigate={navigate} permissions={permissions} />} title="View User | Questri" />

              {/* Billing */}
              {
                (permissions.owner || permissions.admin)
                  ? (
                    <Route exact path="/billing/dashboard" element={<pages.BillingDashboardPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Billing Dashboard | Questri" />
                  )
                  : (
                    <Route exact path="/billing/dashboard" element={<pages.NoBillingAccessPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Billing Dashboard | Questri" />
                  )
              }
              {
                (permissions.owner || permissions.admin)
                  ? (
                    <Route exact path="/billing/transactions" element={<pages.BillingBillablesPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Billing Billables | Questri" />
                  )
                  : (
                    <Route exact path="/billing/dashboard" element={<pages.NoBillingAccessPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Billing Dashboard | Questri" />
                  )
              }
              {
                (permissions.owner || permissions.admin)
                  ? (
                    <Route exact path="/billing/recurring" element={<pages.BillingRecurringBillablesPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Recurring Billables | Questri" />
                  )
                  : (
                    <Route exact path="/billing/dashboard" element={<pages.NoBillingAccessPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Billing Dashboard | Questri" />
                  )
              }
              {
                (permissions.owner || permissions.admin)
                  ? (
                    <Route exact path="/billing/invoices" element={<pages.BillingInvoicesPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Billing Invoices | Questri" />
                  )
                  : (
                    <Route exact path="/billing/dashboard" element={<pages.NoBillingAccessPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Billing Dashboard | Questri" />
                  )
              }
              {
                (permissions.owner || permissions.admin)
                  ? (
                    <Route exact path="/billing/payments" element={<pages.BillingManagePaymentsPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Billing Payments | Questri" />
                  )
                  : (
                    <Route exact path="/billing/dashboard" element={<pages.NoBillingAccessPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Billing Dashboard | Questri" />
                  )
              }
              {
                (permissions.owner || permissions.admin)
                  ? (
                    <Route exact path="/billing/payments" element={<pages.BillingManagePaymentsPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Manage Payments | Questri" />
                  )
                  : (
                    <Route exact path="/billing/dashboard" element={<pages.NoBillingAccessPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Billing Dashboard | Questri" />
                  )
              }
              <Route exact path="/billing/payments/connected" element={<pages.BillingPaymentsConnectedPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Payments Connected | Questri" />

              {/* Horses */}
              <Route exact path="/horses" element={<pages.HorsesPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="View Horses | Questri" />
              <Route exact path="/horses/:id" element={<pages.HorsePage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="View and Manage Horse | Questri" />
              <Route exact path="/horses/:id/notes/:noteId" element={<pages.HorseNotePage navigate={navigate} permissions={permissions} />} title="View and Manage Horse Note | Questri" />

              {/* Settigns pages */}
              <Route exact path="/settings/feeds" element={<pages.FeedSettingsPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Feed Settings | Questri" />
              <Route exact path="/settings/feeds/:id" element={<pages.FeedPage navigate={navigate} permissions={permissions} />} title="Feed | Questri" />
              <Route exact path="/settings/locations" element={<pages.LocationSettingsPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Location Settings | Questri" />
              <Route exact path="/settings/locations/:id" element={<pages.LocationPage navigate={navigate} permissions={permissions} />} title="Location | Questri" />
              <Route exact path="/settings/tasks" element={<pages.TaskDefinitionSettingsPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Task Settings | Questri" />
              <Route exact path="/settings/tasks/reminders" element={<pages.TaskDefinitionSettingsPage navigate={navigate} permissions={permissions} />} title="Task Settings - Reminders  | Questri" />
              <Route exact path="/settings/tasks/tags" element={<pages.TaskDefinitionSettingsPage navigate={navigate} permissions={permissions} />} title="Task Settings - Tags  | Questri" />
              <Route exact path="/settings/tasks/statuses" element={<pages.TaskDefinitionSettingsPage navigate={navigate} permissions={permissions} />} title="Task Settings - Task Statuses  | Questri" />
              <Route exact path="/settings/tasks/new" element={<pages.TaskDetailSettingsPage navigate={navigate} permissions={permissions} />} title="Task Settings - New Task Type  | Questri" />
              <Route exact path="/settings/tasks/:id" element={<pages.TaskDetailSettingsPage navigate={navigate} permissions={permissions} />} title="Task Settings - Edit Task Type | Questri" />
              <Route exact path="/settings/calendar" element={<pages.CalendarEventSettingsPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Calendar Settings | Questri" />
              <Route exact path="/settings/calendar/new" element={<pages.CalendarEventDetailSettingsPage navigate={navigate} permissions={permissions} />} title="Calendar Settings - New Calendar Type  | Questri" />
              <Route exact path="/settings/calendar/:id" element={<pages.CalendarEventDetailSettingsPage navigate={navigate} permissions={permissions} />} title="Calendar Settings - Edit Calendar Type | Questri" />

              { /* Stream page */ }
              <Route
                exact
                path="/chat"
                element={(
                  <Chat
                    client={chatClient}
                    defaultLanguage="en"
                    theme="team light"
                  >{chatConnected && chatClient && <pages.StreamPage navigate={navigate} permissions={permissions} />}
                  </Chat>
                )}
                title="Questri Chat | Questri"
              />

              {(permissions.owner || permissions.admin) && <Route exact path="/settings/users" element={<pages.UserManagementPage tenantName={tenant} navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="User Management Settings | Questri" />}
              {(permissions.owner || permissions.admin) && <Route exact path="/settings/users/requests" element={<pages.RequestsToJoinPage tenantName={tenant} navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="User Requests to Join | Questri" />}
              { /* TODO should user page be under permission restriction? It is in global search so maybe we have a non admin read only view? */}
              <Route exact path="/settings/admin/users/:id" element={<pages.UserPage tenantName={tenant} navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Manage User Settings | Questri" />
              {permissions.owner && <Route exact path="/settings/admin" element={<pages.AdminSettingsPage navigate={navigate} permissions={permissions} chatClient={chatConnected && chatClient} unreadCount={unreadCount} />} title="Admin Settings | Questri" />}
            </>
          )
        }

        {/* TODO - Catch All Not Found Page */}
        <Route element={<div>Not Found</div>} />
      </Routes>
    </AppContainer>
  );
};

AppComponent.propTypes = { pages: PropTypes.object };

AppComponent.defaultProps = {
  pages: {
    // Auth and Other Pagess
    ConfirmEmailPage: React.lazy(() => import('./pages/auth/ConfirmEmail')),
    LogoutPage: React.lazy(() => import('./pages/auth/LogoutPage')),
    AuthorizationError: React.lazy(() => import('./pages/auth/AuthorizationError')),

    // Barn Pages
    BarnBoardPage: React.lazy(() => import('./pages/barn/Board')),

    // User Pages
    UserPage: React.lazy(() => import('./pages/settings/UserPage')),

    // Horse Pages
    HorsesPage: React.lazy(() => import('./pages/horse/Horses')),
    HorsePage: React.lazy(() => import('./pages/horse/Horse')),
    HorseNotePage: React.lazy(() => import('./pages/horse/HorseNote')),
    PublicHorsePage: React.lazy(() => import('./pages/horse/PublicHorse')),

    // Planning Pages

    // Task Pages
    PlanningPage: React.lazy(() => import('./pages/task/Planning')),
    CalendarPage: React.lazy(() => import('./pages/task/Calendar')),
    TaskRemindersPage: React.lazy(() => import('./pages/task/TaskReminders')),
    TasksPage: React.lazy(() => import('./pages/task/Tasks')),
    TasksAnalyticsPage: React.lazy(() => import('./pages/task/Analytics')),
    TaskPage: React.lazy(() => import('./pages/task/Task')),

    // Schedules Pages
    SchedulePage: React.lazy(() => import('./pages/schedule/SchedulePage')),

    // Billing Pages
    BillingDashboardPage: React.lazy(() => import('./pages/billing/components/Dashboard')),
    BillingBillablesPage: React.lazy(() => import('./pages/billing/components/Billables')),
    BillingRecurringBillablesPage: React.lazy(() => import('./pages/billing/components/RecurringBillables')),
    NoBillingAccessPage: React.lazy(() => import('./pages/billing/components/NoBillingAccess')),
    BillingInvoicesPage: React.lazy(() => import('./pages/billing/components/Invoices')),
    BillingInvoicePage: React.lazy(() => import('./pages/billing/components/Invoice')),
    BillingManagePaymentsPage: React.lazy(() => import('./pages/billing/components/ManagePayments')),
    BillingPaymentsConnectedPage: React.lazy(() => import('./pages/billing/components/ManagePayments/components/Connected')),

    // Stream Pages
    StreamPage: React.lazy(() => import('./pages/stream')),

    // Account pages
    AccountProfilePage: React.lazy(() => import('./pages/account/Profile')),
    AccountNotificationsPage: React.lazy(() => import('./pages/account/Notifications')),

    // Settings Pages
    LocationSettingsPage: React.lazy(() => import('./pages/settings/LocationSettingsPage')),
    LocationPage: React.lazy(() => import('./pages/settings/LocationPage')),
    FeedSettingsPage: React.lazy(() => import('./pages/settings/FeedSettingsPage')),
    FeedPage: React.lazy(() => import('./pages/settings/FeedPage')),
    UserManagementPage: React.lazy(() => import('./pages/settings/UserManagementPage')),
    RequestsToJoinPage: React.lazy(() => import('./pages/settings/UserManagementPage/components/RequestsToJoin')),
    AdminSettingsPage: React.lazy(() => import('./pages/settings/AdminSettingsPage')),
    TaskDefinitionSettingsPage: React.lazy(() => import('./pages/settings/TaskPage')),
    TaskDetailSettingsPage: React.lazy(() => import('./pages/settings/TaskDetailSettingsPage')),
    CalendarEventSettingsPage: React.lazy(() => import('./pages/settings/CalendarEventSettingsPage')),
    CalendarEventDetailSettingsPage: React.lazy(() => import('./pages/settings/CalendarEventDetailSettingsPage')),

    // Onboarding
    OnboardingPage: React.lazy(() => import('./pages/onboarding')),
  },
};

export default AppComponent;
