/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react';
import {
  Grid,
  Box,
} from '@mui/material';

import LiveHelp from '@mui/icons-material/LiveHelp';
import BillingAndPricingFaq from './components/BillingAndPricingFaq';
import QuestriCard from '../../../../../components/QuestriCard';

export const FAQCardComponent = () => (
  <Grid container spacing={3} justifyContent="center">
    <Grid item xs={12} md={6}>
      <QuestriCard
        title="Billing and Subscription FAQs"
        avatar={<LiveHelp sx={{ color: '#ffffff' }} />}
        actionCount={null}
        actionReplacement={<Box />}
      >
        <BillingAndPricingFaq includeOrgFaq />
      </QuestriCard>
    </Grid>
  </Grid>
);

FAQCardComponent.propTypes = {};

FAQCardComponent.defaultProps = {};

export default (FAQCardComponent);
