// Billablesexport const GET_BILLABLES_REQUESTED = 'GET_BILLABLES_REQUESTED';
export const GET_BARN_BILLABLES_ROLLUP_SUCCESS = 'GET_BARN_BILLABLES_ROLLUP_SUCCESS';
export const GET_BARN_BILLABLES_ROLLUP_ERROR = 'GET_BARN_BILLABLES_ROLLUP_ERROR';
export const GET_BARN_BILLABLES_ROLLUP_REQUESTED = 'GET_BARN_BILLABLES_ROLLUP_REQUESTED';
export const GET_BARN_BILLABLES_ANALYTICS_SUCCESS = 'GET_BARN_BILLABLES_ANALYTICS_SUCCESS';
export const GET_BARN_BILLABLES_ANALYTICS_ERROR = 'GET_BARN_BILLABLES_ANALYTICS_ERROR';
export const GET_BARN_BILLABLES_ANALYTICS_REQUESTED = 'GET_BARN_BILLABLES_ANALYTICS_REQUESTED';
export const GET_BILLABLES_SUCCESS = 'GET_BILLABLES_SUCCESS';
export const GET_BILLABLES_ERROR = 'GET_BILLABLES_ERROR';
export const GET_BILLABLES_REQUESTED = 'GET_BILLABLES_REQUESTED';
export const GET_REPEATING_BILLABLES_SUCCESS = 'GET_REPEATING_BILLABLES_SUCCESS';
export const GET_REPEATING_BILLABLES_ERROR = 'GET_REPEATING_BILLABLES_ERROR';
export const GET_REPEATING_BILLABLES_REQUESTED = 'GET_REPEATING_BILLABLES_REQUESTED';
export const UPDATE_BILLABLES_REQUESTED = 'UPDATE_BILLABLES_REQUESTED';
export const UPDATE_BILLABLES_SUCCESS = 'UPDATE_BILLABLES_SUCCESS';
export const UPDATE_BILLABLES_ERROR = 'UPDATE_BILLABLES_ERROR';
export const ADD_BILLABLES_REQUESTED = 'ADD_BILLABLES_REQUESTED';
export const ADD_BILLABLES_SUCCESS = 'ADD_BILLABLES_SUCCESS';
export const ADD_BILLABLES_ERROR = 'ADD_BILLABLES_ERROR';
export const DELETE_BILLABLES_REQUESTED = 'DELETE_BILLABLES_REQUESTED';
export const DELETE_BILLABLES_SUCCESS = 'DELETE_BILLABLES_SUCCESS';
export const DELETE_BILLABLES_ERROR = 'DELETE_BILLABLES_ERROR';

export const GET_BILLABLE_INVOICES_SUCCESS = 'GET_BILLABLE_INVOICES_SUCCESS';
export const GET_BILLABLE_INVOICES_ERROR = 'GET_BILLABLE_INVOICES_ERROR';
export const GET_BILLABLE_INVOICES_REQUESTED = 'GET_BILLABLE_INVOICES_REQUESTED';
