import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AlertIcon from '@mui/icons-material/Warning';
import AppStoreButton from '../../AppStoreButton';
import accessDenied from '../../../images/unused-undraw-illustrations/undraw_access_denied_re_awnf.svg';

const defaultCardStyle = {
  width: '500px',
  margin: 'auto',
  maxWidth: '90vw',
};
const buttonContainer = {
  margin: '2rem auto 1rem',
  display: 'block',
  width: 'fit-content',
};
const imageContainer = {
  width: 200,
  display: 'block',
  margin: 'auto',
  marginBottom: '1rem',
};

const LimitedAccessCardComponent = ({ selectedBarn = { name: 'Your barn' } }) => (
  <Box sx={{ mt: 2 }}>
    <Card style={defaultCardStyle}>
      <CardHeader
        title={`${selectedBarn.name || ''} Welcomes You To Questri!`}
        subheader={(
          <Typography gutterBottom sx={{ fontWeight: 300 }}>
            The Questri web application has limited access for clients and riders.
          </Typography>
        )}
      />
      <CardContent>
        <img src={accessDenied} alt="man access denied app" style={imageContainer} />
        <Box
          sx={{
            width: '100%',
            textAlign: 'center',
            m: 1,
          }}
        >
          <Chip
            icon={<AlertIcon />}
            color="warning"
            label="Limited Access"
          />
        </Box>
        <Typography variant="body2" gutterBottom>
          Questri offers additional features through the Questri mobile app.
          View your horse information including photos, videos, riding/lesson
          schedules, shows and much more.
        </Typography>

        <Grid container justifyContent="center">
          <Grid
            item
          >
            <AppStoreButton
              store="ios"
              url="https://apps.apple.com/us/app/questri/id1634821513"
              width={150}
              height={60}
            />
            <AppStoreButton
              store="android"
              url="https://play.google.com/store/apps/details?id=app.questri"
              width={150}
              height={60}
            />
          </Grid>

        </Grid>
        <Divider
          sx={{
            mt: 2,
            mb: 2,
          }}
        />
        <Typography variant="h5" align="center" gutterBottom color="primary">
          Ask <b>{selectedBarn.name}</b> about how you can get access to the Questri mobile
          app and all those additional features.
        </Typography>
        <div style={buttonContainer}>
          <Button
            color="primary"
            size="small"
            href="/settings/profile"
            target="_top"
            variant="contained"
          >
            Go To Profile
          </Button>
        </div>
        <Typography variant="caption" component="div" align="center" gutterBottom>
          If you feel this is a mistake and you need a different role, please
          contact an admin for this Questri organization.
        </Typography>
      </CardContent>
    </Card>
  </Box>
);

LimitedAccessCardComponent.propTypes = { selectedBarn: PropTypes.object };

LimitedAccessCardComponent.defaultProps = { selectedBarn: { name: 'Your barn' } };

export default LimitedAccessCardComponent;
