/* eslint-disable no-case-declarations */

import * as types from './types';

export default (state = {
  isAddingHorseAccessRequest: false,
  isFetchingMyBarnsHorseAccessRequests: false,
  isUpdatingMyBarnsHorseAccessRequest: false,
  horseAccessRequests: { content: [] },
}, action = {}) => {
  switch (action.type) {
    case types.ADD_HORSE_ACCESS_REQUEST_REQUESTED:
      return {
        ...state,
        isAddingHorseAccessRequest: true,
      };
    case types.ADD_HORSE_ACCESS_REQUEST_SUCCESS:
      return {
        ...state,
        isAddingHorseAccessRequest: false,
      };
    case types.ADD_HORSE_ACCESS_REQUEST_ERROR:
      return {
        ...state,
        isAddingHorseAccessRequest: false,
      };
    case types.UPDATE_HORSE_ACCESS_REQUEST_REQUESTED:
      return {
        ...state,
        isUpdatingMyBarnsHorseAccessRequest: true,
      };
    case types.UPDATE_HORSE_ACCESS_REQUEST_SUCCESS:
      return {
        ...state,
        isUpdatingMyBarnsHorseAccessRequest: false,
      };
    case types.UPDATE_HORSE_ACCESS_REQUEST_ERROR:
      return {
        ...state,
        isUpdatingMyBarnsHorseAccessRequest: false,
      };
    case types.GET_HORSE_ACCESS_REQUESTS_REQUESTED:
      return {
        ...state,
        isFetchingMyBarnsHorseAccessRequests: true,
        horseAccessRequests: { content: [] },
      };
    case types.GET_HORSE_ACCESS_REQUESTS_SUCCESS:
      return {
        ...state,
        isFetchingMyBarnsHorseAccessRequests: false,
        horseAccessRequests: action.horseAccessRequests,
      };
    case types.GET_HORSE_ACCESS_REQUESTS_ERROR:
      return {
        ...state,
        isFetchingMyBarnsHorseAccessRequests: false,
        horseAccessRequests: { content: [] },
      };
    default:
      return state;
  }
};

