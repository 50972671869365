
import EventTemplateReminderService from '../../services/EventTemplateReminderService';
import EventTemplateService from '../../services/EventTemplateService';
import * as types from './types';
import {
  dispatchNetworkRequest,
  dispatchNetworkRequestDynamicSuccessDispatch,
} from '../networkDispatcher';

export const getEventTemplateReminders = () => (dispatch) => {
  const promise = EventTemplateReminderService.getEventTemplateReminders();
  dispatchNetworkRequest({
    dispatch,
    requestType: types.GET_EVENT_TEMPLATE_REMINDER_REQUESTED,
    successType: types.GET_EVENT_TEMPLATE_REMINDER_SUCCESS,
    errorType: types.GET_EVENT_TEMPLATE_REMINDER_ERROR,
    promise,
    defaultErrorMessage: 'There was a problem loading task reminders for this organization',
    responseName: 'eventTemplateReminders',
  });
  return promise;
};

export const addEventTemplateReminder = (id, request) => (dispatch) => {
  const promise = EventTemplateService.addEventTemplateReminder(id, request);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.ADD_EVENT_TEMPLATE_REMINDER_REQUESTED,
    successType: types.ADD_EVENT_TEMPLATE_REMINDER_SUCCESS,
    errorType: types.ADD_EVENT_TEMPLATE_REMINDER_ERROR,
    promise,
    successMessage: 'Successfully created the task reminder',
    defaultErrorMessage: 'There was a problem adding the task reminders to the organization',
    responseName: 'newEventTemplateReminder',
  });
  return promise;
};

export const updateEventTemplateReminder = (id, request) => (dispatch) => {
  const promise = EventTemplateReminderService.updateEventTemplateReminder(id, request);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.UPDATE_EVENT_TEMPLATE_REMINDER_REQUESTED,
    successType: types.UPDATE_EVENT_TEMPLATE_REMINDER_SUCCESS,
    errorType: types.UPDATE_EVENT_TEMPLATE_REMINDER_ERROR,
    promise,
    successMessage: 'Successfully updated the task reminder',
    defaultErrorMessage: 'There was a problem updating the task reminder',
    responseName: 'updatedEventTemplateReminder',
  });
  return promise;
};

export const deleteEventTemplateReminder = (id) => (dispatch) => {
  const promise = EventTemplateReminderService.deleteEventTemplateReminder(id);
  dispatchNetworkRequestDynamicSuccessDispatch({
    dispatch,
    requestType: types.DELETE_EVENT_TEMPLATE_REMINDER_REQUESTED,
    successDispatch: {
      type: types.DELETE_EVENT_TEMPLATE_REMINDER_SUCCESS,
      deletedEventTemplateReminderId: id,
    },
    errorType: types.DELETE_EVENT_TEMPLATE_REMINDER_ERROR,
    promise,
    successMessage: 'Successfully deleted the task reminder',
    defaultErrorMessage: 'There was a problem deleting the task reminder',
    responseName: 'response',
  });
  return promise;
};

export const getEventTemplateReminderTagReminders = (id) => (dispatch) => {
  const promise = EventTemplateReminderService.getEventTemplateReminderTagReminders(id);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.GET_EVENT_TEMPLATE_REMINDER_TAG_REMINDERS_REQUESTED,
    successType: types.GET_EVENT_TEMPLATE_REMINDER_TAG_REMINDERS_SUCCESS,
    errorType: types.GET_EVENT_TEMPLATE_REMINDER_TAG_REMINDERS_ERROR,
    promise,
    defaultErrorMessage: 'There was a problem loading task reminders for this task for this organization',
    responseName: 'tagReminders',
  });
  return promise;
};
