import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { Grid } from '@mui/material';
import {
  getQueryParam,
  removeURLParameter,
} from '../../../utils/urlHelper';
import AppStoreButton from '../../AppStoreButton';
import signIn from '../../../images/unused-undraw-illustrations/undraw_sign_in_re_o58h.svg';

const defaultCardStyle = {
  width: '500px',
  margin: 'auto',
  maxWidth: '90vw',
};
const buttonContainer = {
  margin: '2rem auto 1rem',
  display: 'block',
  width: 'fit-content',
};
const imageContainer = {
  width: 250,
  display: 'block',
  margin: 'auto',
  marginTop: '1rem',
  marginBottom: '1rem',
};

const LimitedAccessCardComponent = () => {
  const { loginWithRedirect } = useAuth0();

  // We will only show app install links for instagram
  const isEmbeddedBrowser
    = navigator.userAgent.includes('Instagram') || window.innerWidth < 700;

  React.useEffect(() => {
    const connection = getQueryParam('connection');
    removeURLParameter('signupPrompt');
    removeURLParameter('connection');

    if (connection !== null && connection.length > 0) {
      loginWithRedirect({
        appState: { returnTo: '/' },
        authorizationParams: {
          screen_hint: 'signin',
          ui_locales: 'es',
          mode: 'signin',
          connection,
        },
      });
    }
  }, []);

  return (
    <Box sx={{ mt: 2 }}>
      <Card style={defaultCardStyle}>
        <CardHeader
          title={
            isEmbeddedBrowser
              ? 'Download The Questri App'
              : 'Log In or Sign Up'
          }
        />
        <CardContent>
          <Typography textAlign="center" fontWeight={300}>
            {isEmbeddedBrowser
              ? 'For the best experience on your mobile device, download our app and get started.'
              : 'Please log in or sign up to continue using Questri'}
          </Typography>

          {!isEmbeddedBrowser && (
            <img
              src={signIn}
              alt="woman ask to sign in"
              style={imageContainer}
            />
          )}
          {isEmbeddedBrowser
            ? (
              <Grid container justifyContent="center">
                <Grid item>
                  <AppStoreButton
                    store="ios"
                    url="https://apps.apple.com/us/app/questri/id1634821513"
                    width={150}
                    height={60}
                  />
                  <AppStoreButton
                    store="android"
                    url="https://play.google.com/store/apps/details?id=app.questri"
                    width={150}
                    height={60}
                  />
                </Grid>
              </Grid>
            )
            : (
              <div style={buttonContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    loginWithRedirect({
                      appState: {
                        returnTo:
                        window.location.pathname + window.location.search,
                      },
                    });
                  }}
                >
                  Log In
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    loginWithRedirect({
                      ui_locales: 'es',
                      appState: {
                        returnTo:
                        window.location.pathname + window.location.search,
                      },
                      authorizationParams: { screen_hint: 'signup' },
                    });
                  }}
                  variant="text"
                >
                  Sign Up
                </Button>
              </div>
            )}
        </CardContent>
      </Card>

      {!isEmbeddedBrowser && (
        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              mt: 2,
              mb: 2,
            }}
          >
            <Card style={defaultCardStyle}>
              <CardHeader subheader="Don't forget to download our mobile app so you can take Questri wherever you go!" />
              <CardContent>
                <Grid container justifyContent="center">
                  <Grid item>
                    <AppStoreButton
                      store="ios"
                      url="https://apps.apple.com/us/app/questri/id1634821513"
                      width={150}
                      height={60}
                    />
                    <AppStoreButton
                      store="android"
                      url="https://play.google.com/store/apps/details?id=app.questri"
                      width={150}
                      height={60}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          mt: isEmbeddedBrowser
            ? 4
            : 0,
        }}
      >
        <Card
          style={defaultCardStyle}
          sx={{
            mt: 2,
            borderRadius: 8,
            height: 275,
            background: 'url("https://imgur.com/jwUVrCu.png")',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </Box>
    </Box>
  );
};

LimitedAccessCardComponent.propTypes = {};

LimitedAccessCardComponent.defaultProps = {};

export default LimitedAccessCardComponent;
