/* eslint-disable no-case-declarations */
import {
  groupBy,
  uniq,
} from 'lodash';
import * as types from './types';
import * as barnTypes from '../barn/types';

export default (state = {
  horse: {},
  isFetchingHorse: false,
  horsesMap: {},
  isFetchingHorses: false,
  isFetchingBarnHorse: false,
  allHorseLodgings: { content: [] },
  isFetchingAllHorseLodgings: false,
  currentHorseLodging: {},
  allHorseLodgingsForHorse: [],
  // barn horse
  isFetchingHorseLodgings: false,
  // horse note
  horseNotes: { content: [] },
  isFetchingHorseNotes: false,
  horseNoteHighlights: [],
  isFetchingHorseNoteHighlights: false,
  isAddingHorseNotes: false,
  isUpdatingHorseNotes: false,
  isDeletingHorseNotes: false,
  // horse feed
  horseFeeds: { content: [] },
  isFetchingHorseFeeds: false,
  // horse location
  horseLocations: { content: [] },
  isFetchingHorseLocations: false,
  // horse files
  isAddingHorseFile: false,
  isFetchingHorseFile: false,
  // horse suggestion
  horseSuggestion: null,
  isFetchingHorseSuggestion: false,
}, action = {}) => {
  switch (action.type) {
    case types.GET_HORSE_REQUESTED:
      return {
        ...state,
        horse: { },
        isFetchingHorse: true,
      };
    case types.GET_HORSE_SUCCESS:
      return {
        ...state,
        isFetchingHorse: false,
        horse: action.horse,
      };
    case types.GET_HORSE_ERROR:
      return {
        ...state,
        isFetchingHorse: false,
      };
    case types.GET_HORSES_REQUESTED:
      return {
        ...state,
        horsesMap: { },
        isFetchingHorses: true,
      };
    case types.GET_HORSES_SUCCESS:
      return {
        ...state,
        isFetchingHorses: false,
        horsesMap: {
          ...groupBy(action.horses, 'pingumTagId'),
          ...groupBy(action.horses, 'id'),
        },
      };
    case types.GET_HORSES_ERROR:
      return {
        ...state,
        isFetchingHorses: false,
      };
    case types.UPDATE_HORSE_REQUESTED:
      return {
        ...state,
        isFetchingHorse: true,
      };
    case types.UPDATE_HORSE_SUCCESS:
      return {
        ...state,
        isFetchingHorse: false,
        horse: action.updatedHorse,
      };
    case types.UPDATE_HORSE_ERROR:
      return {
        ...state,
        isFetchingHorse: false,
      };
    case types.GET_HORSE_LODGINGS_REQUESTED:
      return {
        ...state,
        currentHorseLodging: {},
        isFetchingHorseLodgings: true,
      };
    case types.GET_HORSE_LODGINGS_SUCCESS:
      const activeLodgingsAtBarn = (action.horseLodgings && action.horseLodgings.filter((hl) => hl.barn.id === action.selectedBarnId
      && new Date(hl.startDate).getTime() < new Date().getTime() && (!hl.endDate || new Date(hl.endDate).getTime() > new Date().getTime())))
      return {
        ...state,
        isFetchingHorseLodgings: false,
        horseLodgings: action.horseLodgings,
        currentHorseLodging: activeLodgingsAtBarn.length > 0
          ? activeLodgingsAtBarn.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())[0]
          : {},
      };
    case types.GET_HORSE_LODGINGS_ERROR:
      return {
        ...state,
        isFetchingHorseLodgings: false,
      };
    case types.GET_ALL_HORSE_LODGINGS_REQUESTED:
      return {
        ...state,
        allHorseLodgings: { content: [] },
        isFetchingAllHorseLodgings: true,
      };
    case types.GET_ALL_HORSE_LODGINGS_SUCCESS:
      return {
        ...state,
        isFetchingAllHorseLodgings: false,
        allHorseLodgings: action.allHorseLodgings,
      };
    case types.GET_ALL_HORSE_LODGINGS_ERROR:
      return {
        ...state,
        isFetchingAllHorseLodgings: false,
      };
    case barnTypes.UPDATE_BARN_HORSE_REQUESTED:
      return {
        ...state,
        isFetchingBarnHorse: true,
      };
    case barnTypes.UPDATE_BARN_HORSE_SUCCESS:
      return {
        ...state,
        isFetchingBarnHorse: false,
        currentHorseLodging: state.currentHorseLodging.id === action.updatedBarnHorse.id
          ? action.updatedBarnHorse
          : state.currentHorseLodging,
      };
    case barnTypes.UPDATE_BARN_HORSE_ERROR:
      return {
        ...state,
        isFetchingBarnHorse: false,
      };

    /* HORSE NOTES */
    case types.GET_HORSE_NOTES_REQUESTED:
      return {
        ...state,
        horseNotes: { content: [] },
        isFetchingHorseNotes: true,
      };
    case types.GET_HORSE_NOTES_SUCCESS:
      return {
        ...state,
        isFetchingHorseNotes: false,
        horseNotes: action.horseNotes,
      };
    case types.GET_HORSE_NOTES_ERROR:
      return {
        ...state,
        isFetchingHorseNotes: false,
      };
    case types.GET_HORSE_NOTE_HIGHLIGHTS_REQUESTED:
      return {
        ...state,
        horseNoteHighlights: [],
        isFetchingHorseNoteHighlights: true,
      };
    case types.GET_HORSE_NOTE_HIGHLIGHTS_SUCCESS:
      return {
        ...state,
        isFetchingHorseNoteHighlights: false,
        horseNoteHighlights: action.horseNoteHighlights,
      };
    case types.GET_HORSE_NOTE_HIGHLIGHTS_ERROR:
      return {
        ...state,
        isFetchingHorseNoteHighlights: false,
      };
    case types.ADD_HORSE_NOTE_REQUESTED:
      return {
        ...state,
        isAddingHorseNotes: true,
      };
    case types.ADD_HORSE_NOTE_SUCCESS:
      return {
        ...state,
        isAddingHorseNotes: false,
        horseNotes: {
          ...state.horseNotes,
          content: [{
            ...action.newHorseNote,
            new: true, // signal in UI to show newly added feed
          }].concat(state.horseNotes.content),
        },
        horse: {
          ...state.horse,
          activeNote: action.pinToHorse
            ? action.newHorseNote
            : state.horse.activeNote,
        },
        horseNoteHighlights: action.newHorseNote.highlightOrder
          ? [...state.horseNoteHighlights, action.newHorseNote]
          : state.horseNoteHighlights,
      };
    case types.ADD_HORSE_NOTE_ERROR:
      return {
        ...state,
        isAddingHorseNotes: false,
      };
    case types.UPDATE_HORSE_NOTE_REQUESTED:
      return {
        ...state,
        isUpdatingHorseNotes: true,
      };
    case types.UPDATE_HORSE_NOTE_SUCCESS:
      let newHorseActiveNote = action.pinToHorse
        ? action.updatedHorseNote
        : state.horse.activeNote;
      if (action.pinToHorse === false && state.horse.activeNote && state.horse.activeNote.id === action.updatedHorseNote.id) {
        newHorseActiveNote = null;
      }
      return {
        ...state,
        isUpdatingHorseNotes: false,
        horseNotes: {
          ...state.horseNotes,
          content: state.horseNotes.content.map((f) => (f.id === action.updatedHorseNote.id
            ? action.updatedHorseNote
            : f)),
        },
        horse: {
          ...state.horse,
          activeNote: newHorseActiveNote,
        },
        horseNoteHighlights: uniq([action.updatedHorseNote, ...state.horseNoteHighlights.map((f) => (f.id === action.updatedHorseNote.id
          ? action.updatedHorseNote
          : f))], 'id').filter((f) => !!f.highlightOrder),
      };
    case types.UPDATE_HORSE_NOTE_ERROR:
      return {
        ...state,
        isUpdatingHorseNotes: false,
      };
    case types.DELETE_HORSE_NOTE_REQUESTED:
      return {
        ...state,
        isDeletingHorseNotes: true,
      };
    case types.DELETE_HORSE_NOTE_SUCCESS:
      return {
        ...state,
        isDeletingHorseNotes: false,
        horseNotes: {
          ...state.horseNotes,
          content: state.horseNotes.content.filter((f) => f.id !== action.deletedHorseNoteId),
        },
        horse: {
          ...state.horse,
          // eslint-disable-next-line no-nested-ternary
          activeNote: state.horse.activeNote
            ? (state.horse.activeNote.id === action.deletedHorseNoteId
              ? null
              : state.horse.activeNote)
            : null,
        },
        horseNoteHighlights: state.horseNoteHighlights.filter((f) => f.id !== action.deletedHorseNoteId),
      };
    case types.DELETE_HORSE_NOTE_ERROR:
      return {
        ...state,
        isDeletingHorseNotes: false,
      };

    case types.UPDATE_HIGHLIGHTS_LIST:
      return {
        ...state,
        horseNoteHighlights: action.horseNoteHighlights,
      };

    /* HORSE FEEDS */
    case types.GET_HORSE_FEEDS_REQUESTED:
      return {
        ...state,
        horseFeeds: { content: [] },
        isFetchingHorseFeeds: true,
      };
    case types.GET_HORSE_FEEDS_SUCCESS:
      return {
        ...state,
        isFetchingHorseFeeds: false,
        horseFeeds: action.horseFeeds,
      };
    case types.GET_HORSE_FEEDS_ERROR:
      return {
        ...state,
        isFetchingHorseFeeds: false,
      };
    case types.ADD_HORSE_FEED_REQUESTED:
      return {
        ...state,
        isFetchingHorseFeeds: true,
      };
    case types.ADD_HORSE_FEED_SUCCESS:
      return {
        ...state,
        isFetchingHorseFeeds: false,
        horseFeeds: {
          ...state.horseFeeds,
          content: [{
            ...action.newHorseFeed,
            new: true, // signal in UI to show newly added feed
          }].concat(state.horseFeeds.content),
        },
      };
    case types.ADD_HORSE_FEED_ERROR:
      return {
        ...state,
        isFetchingHorseFeeds: false,
      };
    case types.UPDATE_HORSE_FEED_REQUESTED:
      return {
        ...state,
        isFetchingHorseFeeds: true,
      };
    case types.UPDATE_HORSE_FEED_SUCCESS:
      return {
        ...state,
        isFetchingHorseFeeds: false,
        horseFeeds: {
          ...state.horseFeeds,
          content: state.horseFeeds.content.map((f) => (f.id === action.updatedHorseFeed.id
            ? action.updatedHorseFeed
            : f)),
        },
      };
    case types.UPDATE_HORSE_FEED_ERROR:
      return {
        ...state,
        isFetchingHorseFeeds: false,
      };
    case types.DELETE_HORSE_FEED_REQUESTED:
      return {
        ...state,
        isFetchingHorseFeeds: true,
      };
    case types.DELETE_HORSE_FEED_SUCCESS:
      return {
        ...state,
        isFetchingHorseFeeds: false,
      };
    case types.DELETE_HORSE_FEED_ERROR:
      return {
        ...state,
        isFetchingHorseFeeds: false,
      };

      /* HORSE Locations */
    case types.GET_HORSE_LOCATIONS_REQUESTED:
      return {
        ...state,
        horseLocations: { content: [] },
        isFetchingHorseLocations: true,
      };
    case types.GET_HORSE_LOCATIONS_SUCCESS:
      return {
        ...state,
        isFetchingHorseLocations: false,
        horseLocations: action.horseLocations,
      };
    case types.GET_HORSE_LOCATIONS_ERROR:
      return {
        ...state,
        isFetchingHorseLocations: false,
      };
    case types.ADD_HORSE_LOCATION_REQUESTED:
      return {
        ...state,
        isFetchingHorseLocations: true,
      };
    case types.ADD_HORSE_LOCATION_SUCCESS:
      return {
        ...state,
        isFetchingHorseLocations: false,
        horseLocations: {
          ...state.horseLocations,
          content: [{ ...action.newHorseLocation }].concat(state.horseLocations.content),
        },
      };
    case types.ADD_HORSE_LOCATION_ERROR:
      return {
        ...state,
        isFetchingHorseLocations: false,
      };
    case types.UPDATE_HORSE_LOCATION_REQUESTED:
      return {
        ...state,
        isFetchingHorseLocations: true,
      };
    case types.UPDATE_HORSE_LOCATION_SUCCESS:
      return {
        ...state,
        isFetchingHorseLocations: false,
        horseLocations: {
          ...state.horseLocations,
          content: state.horseLocations.content.map((f) => (f.id === action.updatedHorseLocation.id
            ? action.updatedHorseLocation
            : f)),
        }
        ,
      };
    case types.UPDATE_HORSE_LOCATION_ERROR:
      return {
        ...state,
        isFetchingHorseLocations: false,
      };
    case types.DELETE_HORSE_LOCATION_REQUESTED:
      return {
        ...state,
        isFetchingHorseLocations: true,
      };
    case types.DELETE_HORSE_LOCATION_SUCCESS:
      return {
        ...state,
        isFetchingHorseLocations: false,
        horseLocations: {
          ...state.horseLocations,
          content: state.horseLocations.content.filter((f) => f.id !== action.deletedHorseLocationId),
        },
      };
    case types.DELETE_HORSE_LOCATION_ERROR:
      return {
        ...state,
        isFetchingHorseLocations: false,
      };

    /* Horse Files */
    case types.SET_HORSE_FILE_PREVIEWS:
      return {
        ...state,
        horseNotes: {
          ...state.horseNotes,
          content: state.horseNotes.content.map((n) => (n.id === action.noteId
            ? {
              ...n,
              information: {
                ...n.information,
                filePreviews: {
                  ...(n.information.filePreviews || {}),
                  [action.file]: action.base64data,
                },
              },
            }
            : n)),
        },
        horseNoteHighlights: state.horseNoteHighlights.map((n) => (n.id === action.noteId
          ? {
            ...n,
            information: {
              ...n.information,
              filePreviews: {
                ...(n.information.filePreviews || {}),
                [action.file]: action.base64data,
              },
            },
          }
          : n)),
      };
    case types.GET_HORSE_FILE_REQUESTED:
      return {
        ...state,
        isFetchingHorseFile: true,
      };
    case types.GET_HORSE_FILE_SUCCESS:
      return {
        ...state,
        isFetchingHorseFile: false,
      };
    case types.GET_HORSE_FILE_ERROR:
      return {
        ...state,
        isFetchingHorseFile: false,
      };
    case types.ADD_HORSE_FILE_REQUESTED:
      return {
        ...state,
        isAddingHorseFile: true,
      };
    case types.ADD_HORSE_FILE_SUCCESS:
      return {
        ...state,
        isAddingHorseFile: false,
      };
    case types.ADD_HORSE_FILE_ERROR:
      return {
        ...state,
        isAddingHorseFile: false,
      };
    case types.GET_HORSE_SUGGESTION_REQUESTED:
      return {
        ...state,
        isFetchingHorseSuggestion: true,
        horseSuggestion: null,
      };
    case types.GET_HORSE_SUGGESTION_SUCCESS:
      return {
        ...state,
        isFetchingHorseSuggestion: false,
        horseSuggestion: {
          ...action.horseSuggestion,
          hiddenTaskSuggestions: action.horseSuggestion.hiddenSuggestions || [],
        },
      };
    case types.GET_HORSE_SUGGESTION_ERROR:
      return {
        ...state,
        isFetchingHorseSuggestion: false,
      };

    case types.UPDATE_HORSE_SUGGESTION_REQUESTED:
      return {
        ...state,
        isFetchingHorseSuggestion: true,
      };
    case types.UPDATE_HORSE_SUGGESTION_SUCCESS:
      return {
        ...state,
        isFetchingHorseSuggestion: false,
        horseSuggestion: action.updatedHorseSuggestion,
      };
    case types.UPDATE_HORSE_SUGGESTION_ERROR:
      return {
        ...state,
        isFetchingHorseSuggestion: false,
      };
    default:
      return state;
  }
};

