import Joi from 'joi-browser';
import HttpService from '@pingum/api-services/HttpService';

export default class LocationService {
  static isLocationRequestValid(request) {
    if (request === null || request === undefined) {
      return false;
    }
    const schema = Joi.object().keys({
      name: Joi.string().min(2).max(100).required(),
      type: Joi.string().valid('Barn', 'Competition', 'Trainer'),
      assignedPlanner: Joi.string().allow(null).allow(''),
      planningTimeUtcHour: Joi.number().allow(null).min(-1).max(23),
      boardData: Joi.string().allow(null).min(0).max(10000),
      boardLayout: Joi.string().allow(null).min(0).max(10000),
      timezone: Joi.string(),
    });

    return (Joi.validate(request, schema).error === null);
  }

  static getLocations(page, size, sort, search) {
    return this.httpService.get(`?page=${page}&size=${size}${sort}&search=${search}`);
  }

  static getLocationHorses(locationId, page, size, sort, date) {
    return this.httpService.get(`/${locationId}/horses?page=${page}&size=${size}${sort}&locationDate=${date.toISOString()}`);
  }

  static addLocation(request) {
    if (!LocationService.isLocationRequestValid(request)) {
      return Promise.reject(new Error('Invalid add location request'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.post('/', request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static deleteLocation(id) {
    if (!id) {
      return Promise.reject(new Error('Missing Location ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.delete(`/${id}`).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static updateLocation(id, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Location ID'));
    }

    if (!LocationService.isLocationRequestValid(request)) {
      return Promise.reject(new Error('Invalid edit location request'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.patch(`/${id}`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  /* *********
    Horse Location Methods
  ********** */

  static addHorseLocation(id, horseId, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Location ID'));
    }
    if (!horseId) {
      return Promise.reject(new Error('Missing Horse ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.post(`/${id}/horses/${horseId}`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static updateHorseLocation(id, horseId, request, horseLocationStartDate) {
    if (!id) {
      return Promise.reject(new Error('Missing Location ID'));
    }
    if (!horseId) {
      return Promise.reject(new Error('Missing Horse ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.patch(`/${id}/horses/${horseId}?horseLocationStartDate=${horseLocationStartDate
        ? horseLocationStartDate.toISOString()
        : ''}`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static deleteHorseLocation(id, horseId, horseLocationStartDate) {
    if (!id) {
      return Promise.reject(new Error('Missing Location ID'));
    }
    if (!horseId) {
      return Promise.reject(new Error('Missing Horse ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.delete(`/${id}/horses/${horseId}?horseLocationStartDate=${horseLocationStartDate
        ? horseLocationStartDate.toISOString()
        : ''}`).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static getLocationHorseNotes(id, {
    page = 0,
    size = 100,
    sort = '',
    type = '',
    timestamp = new Date(),
  } = {}) {
    return this.httpService.get(`${id}/notes?page=${page}&size=${size}${sort}&type=${type || ''}&timestamp=${timestamp.toISOString()}`);
  }

  static getLocationHorseFeeds(id, {
    page = 0,
    size = 100,
    sort = '',
    timestamp = new Date(),
  } = {}) {
    return this.httpService.get(`${id}/feeds?page=${page}&size=${size}${sort}&timestamp=${timestamp.toISOString()}`);
  }
}

LocationService.httpService = new HttpService({
  baseURL: `${typeof window !== 'undefined'
    ? env.REACT_APP_HORSE_API
    : ''}/locations`,
});

