import PropTypes from 'prop-types';
import React from 'react';
import Button from '@mui/material/Button';

export const StripePortalButtonComponent = ({
  tenant,
  buttonText,
  subscriptionToCancel,
  ...buttonProps
}) => (
  <form method="POST" action="/create-customer-portal-session" style={{ textAlign: 'center' }}>
    <input style={{ display: 'none' }} type="text" id="customerId" name="customerId" defaultValue={tenant && tenant.stripeCustomerId} />
    <input style={{ display: 'none' }} type="text" id="returnUrl" name="returnUrl" defaultValue={window.location.href} />
    <input style={{ display: 'none' }} type="text" id="errorUrl" name="errorUrl" defaultValue={`${window.location.origin}/authorization-error`} />
    <input style={{ display: 'none' }} type="text" id="tenant" name="tenant" defaultValue={tenant.name} />
    <input style={{ display: 'none' }} type="text" id="authorization" name="authorization" defaultValue={`bearer ${localStorage.id_token}`} />
    {
      subscriptionToCancel && <input style={{ display: 'none' }} type="text" id="subscriptionToCancel" name="subscriptionToCancel" defaultValue={subscriptionToCancel} />
    }
    <Button {...buttonProps} type="submit">{buttonText}</Button>
  </form>
);

StripePortalButtonComponent.propTypes = {
  tenant: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  subscriptionToCancel: PropTypes.string,
};

StripePortalButtonComponent.defaultProps = { subscriptionToCancel: null };

export default StripePortalButtonComponent;
