// import { BarnService } from '@pingum/api-services';
import moment from 'moment';
import EventTemplateService from '../../services/EventTemplateService';
import ScheduleService from '../../services/ScheduleService';
import ScheduleExceptionService from '../../services/ScheduleExceptionService';
import * as types from './types';
import {
  dispatchNetworkRequest,
  dispatchNetworkRequestDynamicSuccessDispatch,
} from '../networkDispatcher';

export const getAllPagedSearchSchedulesData = async (request, page, pageSize, sort, allContent = []) => {
  const response = await ScheduleService.searchSchedules(request, page, pageSize, sort);
  const newAllContent = [...allContent, ...response.content];

  if (response.last) {
    // all data loaded, don
    return newAllContent;
  }
  // get the next page and repeat
  return getAllPagedSearchSchedulesData(request, page + 1, pageSize, sort, newAllContent);
}

export const getAllPagedScheduleData = async (startDate, endDate, tags, page, pageSize, sort, allContent = []) => {
  const response = await ScheduleService.getSchedules(startDate, endDate, tags, page, pageSize, sort);
  const newAllContent = [...allContent, ...response.content];

  if (response.last) {
    // all data loaded, don
    return newAllContent;
  }
  // get the next page and repeat
  return getAllPagedScheduleData(startDate, endDate, tags, page + 1, pageSize, sort, newAllContent);
};

export const getAllPagedScheduleExceptionData = async (startDate, endDate, tags, page, pageSize, sort, allContent = []) => {
  const response = await ScheduleExceptionService.getScheduleExceptions(startDate, endDate, tags, page, pageSize, sort);
  const newAllContent = [...allContent, ...response.content];

  if (response.last) {
    // all data loaded, don
    return newAllContent;
  }
  // get the next page and repeat
  return getAllPagedScheduleExceptionData(startDate, endDate, tags, page + 1, pageSize, sort, newAllContent);
};

export const getAllPagedEventTemplateScheduleData = async (
  id,
  startDate,
  endDate,
  tags,
  includeWhereChild,
  page,
  pageSize,
  sort,
  allContent = [],
) => {
  const response = await EventTemplateService.getSchedulesForEventTemplate(id, startDate, endDate, tags, includeWhereChild, page, pageSize, sort);
  const newAllContent = [...allContent, ...response.content];

  if (response.last) {
    // all data loaded, don
    return newAllContent;
  }
  // get the next page and repeat
  return getAllPagedEventTemplateScheduleData(id, startDate, endDate, tags, includeWhereChild, page + 1, pageSize, sort, newAllContent);
};

export const getAllPagedChildrenScheduleData = async (parentScheduleId, startDate, endDate, page, pageSize, sort, allContent = []) => {
  const response = await ScheduleService.getChildrenSchedules(parentScheduleId, startDate, endDate, page, pageSize, sort);
  const newAllContent = [...allContent, ...response.content];

  if (response.last) {
    // all data loaded, don
    return newAllContent;
  }
  // get the next page and repeat
  return getAllPagedChildrenScheduleData(parentScheduleId, startDate, endDate, page + 1, pageSize, sort, newAllContent);
};

/*
  SCHEDULES
*/

export const getSchedules = (startDate, endDate, tags = [], page = 0, pageSize = 100, sort = '&sort=startDate,desc') => (dispatch, getState) => {
  dispatch({ type: types.GET_SCHEDULES_REQUESTED });
  dispatch({ type: types.GET_SCHEDULE_EXCEPTIONS_REQUESTED });
  /*
    We need to get all schedules and schedule exceptions for the date range and tags,
    then combine them to get the final schedule of events.
  */
  const schedules = getAllPagedScheduleData(startDate, endDate, tags, page, pageSize, sort);
  const scheduleExceptions = getAllPagedScheduleExceptionData(startDate, endDate, tags, page, pageSize, '&sort=exceptionDate,desc');

  Promise.all([schedules, scheduleExceptions]).then((values) => {
    dispatch({
      type: types.GET_SCHEDULES_SUCCESS,
      schedules: values[0],
    });
    dispatch({
      type: types.GET_SCHEDULE_EXCEPTIONS_SUCCESS,
      scheduleExceptions: values[1],
    });
    // once we have both schedules and schedule exceptions, we can generate all events for calendars
    const {
      barn: {
        selectedBarn,
        barnUsersMap,
      },
      user: { barnUser },
    } = getState();
    dispatch({
      type: types.SET_SCHEDULE_EVENTS,
      schedules: values[0],
      scheduleExceptions: values[1],
      startDate,
      endDate,
      barnUser,
      horseTagTypeId: (selectedBarn.barnMetadata && selectedBarn.barnMetadata.horseTagTypeId) || 0,
      barnUsersMap,
      barnMetadata: selectedBarn.barnMetadata,
    });
  });
};

export const getSchedulesForEventTemplate = (id, startDate, endDate, tags = [], includeWhereEventTemplateIsChild = false, page = 0, pageSize = 100, sort = '&sort=startDate,desc') => (dispatch) => {
  dispatch({ type: types.GET_EVENT_TEMPLATE_SCHEDULES_REQUESTED });
  /*
    We need to get all schedules and schedule exceptions for the date range and tags,
    then combine them to get the final schedule of events.
  */
  const schedules = getAllPagedEventTemplateScheduleData(id, startDate, endDate, tags, includeWhereEventTemplateIsChild, page, pageSize, sort);

  Promise.all([schedules]).then((values) => {
    dispatch({
      type: types.GET_EVENT_TEMPLATE_SCHEDULES_SUCCESS,
      schedules: values[0],
    });
  });
};

export const addSchedule = (id, request) => (dispatch, getState) => {
  const promise = EventTemplateService.addSchedule(id, request);
  const {
    barn: {
      selectedBarn,
      barnUsersMap,
    },
    user: { barnUser },
  } = getState();
  dispatchNetworkRequestDynamicSuccessDispatch({
    dispatch,
    requestType: types.ADD_SCHEDULE_REQUESTED,
    successDispatch: {
      type: types.ADD_SCHEDULE_SUCCESS,
      barnUser,
      horseTagTypeId: (selectedBarn.barnMetadata && selectedBarn.barnMetadata.horseTagTypeId) || 0,
      barnUsersMap,
      barnMetadata: selectedBarn.barnMetadata,
    },
    errorType: types.ADD_SCHEDULE_ERROR,
    promise,
    successMessage: 'Successfully created the schedule',
    defaultErrorMessage: 'There was a problem creating the schedule',
    responseName: 'newSchedule',
  });
  return promise;
};

export const updateSchedule = (id, request) => (dispatch, getState) => {
  const {
    barn: {
      barnUsersMap,
      selectedBarn,
    },
    user: { barnUser },
  } = getState();
  const promise = ScheduleService.updateSchedule(id, request);
  dispatchNetworkRequestDynamicSuccessDispatch({
    dispatch,
    requestType: types.UPDATE_SCHEDULE_REQUESTED,
    successDispatch: {
      type: types.UPDATE_SCHEDULE_SUCCESS,
      barnUser,
      barnUsersMap,
      barnMetadata: selectedBarn.barnMetadata,
    },
    errorType: types.UPDATE_SCHEDULE_ERROR,
    promise,
    successMessage: 'Successfully updated the schedule',
    defaultErrorMessage: 'There was a problem updating the schedule',
    responseName: 'updatedSchedule',
  });
  return promise;
};

export const getSchedule = (id, startDate, endDate) => (dispatch, getState) => {
  dispatch({ type: types.GET_SCHEDULE_REQUESTED });
  const {
    barn: {
      selectedBarn,
      barnUsersMap,
    },
  } = getState();

  /*
    We need to get all schedules and schedule exceptions for the date range and parent schedule,
    then combine them to get the final schedule of events.
  */

  const promise = ScheduleService.getSchedule(id);

  promise.then((schedule) => {
    // if this is a parent schedule we need to get children for the parent, not the child
    getAllPagedChildrenScheduleData((schedule.parentSchedule && schedule.parentSchedule.publicId) || id, startDate, endDate, 0, 100, '&sort=startDate,desc').then((childrenSchedules) => {
      dispatch({
        type: types.GET_SCHEDULE_SUCCESS,
        schedule,
        childrenSchedules,
        startDate,
        endDate,
        barnMetadata: (selectedBarn && selectedBarn.barnMetadata) || {},
        barnUsersMap,
      });
    }).catch((error) => {
      console.error(error);
      dispatch({ type: types.GET_SCHEDULE_ERROR });
    });
  }).catch((error) => {
    console.error(error);
    dispatch({ type: types.GET_SCHEDULE_ERROR });
  });
  return promise;
};

export const getScheduleExceptionsForSchedule = (id, startDate, endDate, page = 0, pageSize = 100, sort = '&sort=exceptionDate,desc') => (dispatch) => {
  const promise = ScheduleService.getSchedulelExceptionsForSchedule(id, startDate, endDate, page, pageSize, sort);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.GET_SCHEDULE_SCHEDULE_EXCEPTIONS_REQUESTED,
    successType: types.GET_SCHEDULE_SCHEDULE_EXCEPTIONS_SUCCESS,
    errorType: types.GET_SCHEDULE_SCHEDULE_EXCEPTIONS_ERROR,
    promise,
    successMessage: 'Successfully retrieved the schedule exceptions',
    defaultErrorMessage: 'There was a problem getting the schedule exceptions',
    responseName: 'scheduleExceptions',
  });
  return promise;
};

export const getScheduleResponsesForSchedule = (schedule, occurrenceDate) => (dispatch) => {
  // Always get responses from parent
  const promise = schedule.parentSchedule
    ? ScheduleService.getScheduleResponses(schedule.parentSchedule.publicId, occurrenceDate)
    : ScheduleService.getScheduleResponses(schedule.publicId, occurrenceDate);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.GET_SCHEDULE_RESPONSES_REQUESTED,
    successType: types.GET_SCHEDULE_RESPONSES_SUCCESS,
    errorType: types.GET_SCHEDULE_RESPONSES_ERROR,
    promise,
    successMessage: 'Successfully got the invitation response',
    defaultErrorMessage: 'There was a problem getting the invitation response',
    responseName: 'scheduleResponses',
  });
  return promise;
};

export const addScheduleResponsesForSchedule = (id, request) => (dispatch) => {
  const promise = ScheduleService.sendScheduleResponseRequest(id, request);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.ADD_SCHEDULE_RESPONSES_REQUESTED,
    successType: types.ADD_SCHEDULE_RESPONSES_SUCCESS,
    errorType: types.ADD_SCHEDULE_RESPONSES_ERROR,
    promise,
    successMessage: 'Successfully saved the invitation response',
    defaultErrorMessage: 'There was a problem saving the invitation response',
    responseName: 'scheduleResponse',
  });
  return promise;
};

export const deleteSchedule = (id) => (dispatch) => {
  const promise = ScheduleService.deleteSchedule(id);
  dispatchNetworkRequestDynamicSuccessDispatch({
    dispatch,
    requestType: types.DELETE_SCHEDULE_REQUESTED,
    successDispatch: {
      type: types.DELETE_SCHEDULE_SUCCESS,
      deletedScheduleId: id,
    },
    errorType: types.DELETE_SCHEDULE_ERROR,
    promise,
    successMessage: 'Successfully deleted the schedule',
    defaultErrorMessage: 'There was a problem deleting the schedule',
    responseName: 'response',
  });
  return promise;
};

/*
  SCHEDULE EXCEPTIONS
*/

export const addScheduleException = (id, request) => (dispatch, getState) => {
  const {
    barn: {
      selectedBarn,
      barnUsersMap,
    },
    user: { barnUser },
  } = getState();

  dispatch({ type: types.ADD_SCHEDULE_EXCEPTION_REQUESTED });

  return new Promise((resolve, reject) => {
    let newChildSchedule = null;
    ScheduleService.addScheduleException(id, request).then((newScheduleException) => {
      if (request.exceptionSchedule && newScheduleException && newScheduleException.schedule) {
        // a new child schedule was created after the exception was inserted into the db, we need to get it and update redux

        // get child schedules for this schedule
        const exceptionDate = moment(request.exceptionSchedule.startDate);
        ScheduleService.getChildrenSchedules(newScheduleException.schedule.id, exceptionDate.add(-5, 'seconds').toDate(), exceptionDate.add(10, 'seconds').toDate(), 0, 5, '').then((newChildSchedules) => {
          if (newChildSchedules && newChildSchedules.content && newChildSchedules.content.length > 0) {
            for (let i = 0; i < newChildSchedules.content.length; i += 1) {
              if (newChildSchedules.content[i].scheduleExceptionId === newScheduleException.id) {
                newChildSchedule = newChildSchedules.content[i];
              }
            }
          }
          dispatch({
            type: types.ADD_SCHEDULE_EXCEPTION_SUCCESS,
            barnUser,
            horseTagTypeId: (selectedBarn.barnMetadata && selectedBarn.barnMetadata.horseTagTypeId) || 0,
            barnUsersMap,
            newChildSchedule,
            newScheduleException,
            barnMetadata: selectedBarn.barnMetadata,
          });
          resolve(newScheduleException);
        }).catch((error) => {
          console.error(error);
          dispatch({ type: types.ADD_SCHEDULE_EXCEPTION_ERROR });
          reject(new Error((error && error.data && error.dataa.message) || error));
        });
      } else {
        dispatch({
          type: types.ADD_SCHEDULE_EXCEPTION_SUCCESS,
          barnUser,
          horseTagTypeId: (selectedBarn.barnMetadata && selectedBarn.barnMetadata.horseTagTypeId) || 0,
          barnUsersMap,
          newChildSchedule,
          newScheduleException,
          barnMetadata: selectedBarn.barnMetadata,
        });
        resolve(newScheduleException);
      }
    }).catch((error) => {
      dispatch({ type: types.ADD_SCHEDULE_EXCEPTION_ERROR });
      reject(new Error((error && error.data && error.dataa.message) || error));
    });
  });
};

export const deleteScheduleException = (id) => (dispatch, getState) => {
  const promise = ScheduleService.deleteScheduleException(id);
  const {
    barn: {
      selectedBarn,
      barnUsersMap,
    },
    user: { barnUser },
  } = getState();
  dispatchNetworkRequestDynamicSuccessDispatch({
    dispatch,
    requestType: types.DELETE_SCHEDULE_EXCEPTION_REQUESTED,
    successDispatch: {
      type: types.DELETE_SCHEDULE_EXCEPTION_SUCCESS,
      deletedScheduleExeptionId: id,
      barnUser,
      horseTagTypeId: (selectedBarn.barnMetadata && selectedBarn.barnMetadata.horseTagTypeId) || 0,
      barnUsersMap,
      barnMetadata: selectedBarn.barnMetadata,
    },
    errorType: types.DELETE_SCHEDULE_EXCEPTION_ERROR,
    promise,
    successMessage: 'Successfully deleted the schedule exception',
    defaultErrorMessage: 'There was a problem deleting the schedule exception',
    responseName: 'response',
  });
  return promise;
};
