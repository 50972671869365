import * as types from './types';

export default (state = {
  search: { content: [] },
  isSearching: false,
}, action = {}) => {
  switch (action.type) {
    case types.SEARCH_REQUESTED:
      return {
        ...state,
        search: { content: [] },
        isSearching: true,
      };
    case types.SEARCH_SUCCESS:
      return {
        ...state,
        isSearching: false,
        search: action.search,
      };
    case types.SEARCH_ERROR:
      return {
        ...state,
        isSearching: false,
        search: { content: [] },
      };
    default:
      return state;
  }
};
