import { grey } from '../../utils/brandColors';

const notificationDescription = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': 2,
  lineHeight: '21px',
  maxHeight: '42px',
};

const notificationTitle = {
  flex: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '12px',
  lineHeight: '19px',
};

const listItem = {
  alignItems: 'flex-start',
  borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
  '&:hover': { borderBottom: '1px solid transparent' },
  padding: '10px',
};

export default () => ({
  listItemUnread: {
    ...listItem,
    background: grey[100],
  },
  listItemRead: listItem,
  listItemPrimary: {
    lineHeight: 1,
    marginBottom: '4px',
  },
  notificationTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    color: 'black',
  },
  notificationTitleUnread: {
    ...notificationTitle,
    fontWeight: 500,
  },
  notificationTitleRead: notificationTitle,
  notificationDate: { marginLeft: '1em' },
  listItemRoot: {
    padding: '0 0 0 10px',
    margin: 0,
  },
  notificationDescriptionRead: {
    ...notificationDescription,
    fontWeight: 300,
  },
  notificationDescriptionUnread: {
    ...notificationDescription,
    fontWeight: 400,
    color: grey[800],
  },
});
