// Horse
export const GET_HORSE_REQUESTED = 'GET_HORSE_REQUESTED';
export const GET_HORSE_SUCCESS = 'GET_HORSE_SUCCESS';
export const GET_HORSE_ERROR = 'GET_HORSE_ERROR';
export const GET_HORSES_REQUESTED = 'GET_HORSES_REQUESTED';
export const GET_HORSES_SUCCESS = 'GET_HORSES_SUCCESS';
export const GET_HORSES_ERROR = 'GET_HORSES_ERROR';
export const UPDATE_HORSE_REQUESTED = 'UPDATE_HORSE_REQUESTED';
export const UPDATE_HORSE_SUCCESS = 'UPDATE_HORSE_SUCCESS';
export const UPDATE_HORSE_ERROR = 'UPDATE_HORSE_ERROR';
export const GET_HORSE_LODGINGS_REQUESTED = 'GET_HORSE_LODGINGS_REQUESTED';
export const GET_HORSE_LODGINGS_SUCCESS = 'GET_HORSE_LODGINGS_SUCCESS';
export const GET_HORSE_LODGINGS_ERROR = 'GET_HORSE_LODGINGS_ERROR';
export const GET_ALL_HORSE_LODGINGS_REQUESTED = 'GET_ALL_HORSE_LODGINGS_REQUESTED';
export const GET_ALL_HORSE_LODGINGS_SUCCESS = 'GET_ALL_HORSE_LODGINGS_SUCCESS';
export const GET_ALL_HORSE_LODGINGS_ERROR = 'GET_ALL_HORSE_LODGINGS_ERROR';
// Horse Notes
export const GET_HORSE_NOTES_REQUESTED = 'GET_HORSE_NOTES_REQUESTED';
export const GET_HORSE_NOTES_SUCCESS = 'GET_HORSE_NOTES_SUCCESS';
export const GET_HORSE_NOTES_ERROR = 'GET_HORSE_NOTES_ERROR';
export const GET_HORSE_NOTE_HIGHLIGHTS_REQUESTED = 'GET_HORSE_NOTE_HIGHLIGHTS_REQUESTED';
export const GET_HORSE_NOTE_HIGHLIGHTS_SUCCESS = 'GET_HORSE_NOTE_HIGHLIGHTS_SUCCESS';
export const GET_HORSE_NOTE_HIGHLIGHTS_ERROR = 'GET_HORSE_NOTE_HIGHLIGHTS_ERROR';
export const UPDATE_HIGHLIGHTS_LIST = 'UPDATE_HIGHLIGHTS_LIST';
export const UPDATE_HORSE_NOTE_REQUESTED = 'UPDATE_HORSE_NOTE_REQUESTED';
export const UPDATE_HORSE_NOTE_SUCCESS = 'UPDATE_HORSE_NOTE_SUCCESS';
export const UPDATE_HORSE_NOTE_ERROR = 'UPDATE_HORSE_NOTE_ERROR';
export const ADD_HORSE_NOTE_REQUESTED = 'ADD_HORSE_NOTE_REQUESTED';
export const ADD_HORSE_NOTE_SUCCESS = 'ADD_HORSE_NOTE_SUCCESS';
export const ADD_HORSE_NOTE_ERROR = 'ADD_HORSE_NOTE_ERROR';
export const DELETE_HORSE_NOTE_REQUESTED = 'DELETE_HORSE_NOTE_REQUESTED';
export const DELETE_HORSE_NOTE_SUCCESS = 'DELETE_HORSE_NOTE_SUCCESS';
export const DELETE_HORSE_NOTE_ERROR = 'DELETE_HORSE_NOTE_ERROR';
// Horse Feeds
export const GET_HORSE_FEEDS_REQUESTED = 'GET_HORSE_FEEDS_REQUESTED';
export const GET_HORSE_FEEDS_SUCCESS = 'GET_HORSE_FEEDS_SUCCESS';
export const GET_HORSE_FEEDS_ERROR = 'GET_HORSE_FEEDS_ERROR';
export const UPDATE_HORSE_FEED_REQUESTED = 'UPDATE_HORSE_FEED_REQUESTED';
export const UPDATE_HORSE_FEED_SUCCESS = 'UPDATE_HORSE_FEED_SUCCESS';
export const UPDATE_HORSE_FEED_ERROR = 'UPDATE_HORSE_FEED_ERROR';
export const ADD_HORSE_FEED_REQUESTED = 'ADD_HORSE_FEED_REQUESTED';
export const ADD_HORSE_FEED_SUCCESS = 'ADD_HORSE_FEED_SUCCESS';
export const ADD_HORSE_FEED_ERROR = 'ADD_HORSE_FEED_ERROR';
export const DELETE_HORSE_FEED_REQUESTED = 'DELETE_HORSE_FEED_REQUESTED';
export const DELETE_HORSE_FEED_SUCCESS = 'DELETE_HORSE_FEED_SUCCESS';
export const DELETE_HORSE_FEED_ERROR = 'DELETE_HORSE_FEED_ERROR';
// Horse Locations
export const GET_HORSE_LOCATIONS_REQUESTED = 'GET_HORSE_LOCATIONS_REQUESTED';
export const GET_HORSE_LOCATIONS_SUCCESS = 'GET_HORSE_LOCATIONS_SUCCESS';
export const GET_HORSE_LOCATIONS_ERROR = 'GET_HORSE_LOCATIONS_ERROR';
export const UPDATE_HORSE_LOCATION_REQUESTED = 'UPDATE_HORSE_LOCATION_REQUESTED';
export const UPDATE_HORSE_LOCATION_SUCCESS = 'UPDATE_HORSE_LOCATION_SUCCESS';
export const UPDATE_HORSE_LOCATION_ERROR = 'UPDATE_HORSE_LOCATION_ERROR';
export const ADD_HORSE_LOCATION_REQUESTED = 'ADD_HORSE_LOCATION_REQUESTED';
export const ADD_HORSE_LOCATION_SUCCESS = 'ADD_HORSE_LOCATION_SUCCESS';
export const ADD_HORSE_LOCATION_ERROR = 'ADD_HORSE_LOCATION_ERROR';
export const DELETE_HORSE_LOCATION_REQUESTED = 'DELETE_HORSE_LOCATION_REQUESTED';
export const DELETE_HORSE_LOCATION_SUCCESS = 'DELETE_HORSE_LOCATION_SUCCESS';
export const DELETE_HORSE_LOCATION_ERROR = 'DELETE_HORSE_LOCATION_ERROR';
// Horse Files
export const ADD_HORSE_FILE_REQUESTED = 'ADD_HORSE_FILE_REQUESTED';
export const ADD_HORSE_FILE_SUCCESS = 'ADD_HORSE_FILE_SUCCESS';
export const ADD_HORSE_FILE_ERROR = 'ADD_HORSE_FILE_ERROR';
export const GET_HORSE_FILE_REQUESTED = 'GET_HORSE_FILE_REQUESTED';
export const GET_HORSE_FILE_SUCCESS = 'GET_HORSE_FILE_SUCCESS';
export const GET_HORSE_FILE_ERROR = 'GET_HORSE_FILE_ERROR';
export const SET_HORSE_FILE_PREVIEWS = 'SET_HORSE_FILE_PREVIEWS';
// Horse Suggestion
export const GET_HORSE_SUGGESTION_REQUESTED = 'GET_HORSE_SUGGESTION_REQUESTED';
export const GET_HORSE_SUGGESTION_SUCCESS = 'GET_HORSE_SUGGESTION_SUCCESS';
export const GET_HORSE_SUGGESTION_ERROR = 'GET_HORSE_SUGGESTION_ERROR';
export const UPDATE_HORSE_SUGGESTION_REQUESTED = 'UPDATE_HORSE_SUGGESTION_REQUESTED';
export const UPDATE_HORSE_SUGGESTION_SUCCESS = 'UPDATE_HORSE_SUGGESTION_SUCCESS';
export const UPDATE_HORSE_SUGGESTION_ERROR = 'UPDATE_HORSE_SUGGESTION_ERROR';
