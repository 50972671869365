import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Auth0Provider } from '@auth0/auth0-react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import LoadingPlaceholder from './components/LoadingPlaceholder';
import App, { history } from './App';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';
import theme from './utils/theme';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './index.scss';
import './stream/styles/index.scss';

const onRedirectCallback = (appState) => {
  history.push(appState?.returnTo || window.location.pathname);
  window.location.href = `${window.location.origin + (appState?.returnTo || window.location.pathname)}`
};
const root = createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <React.Suspense fallback={<LoadingPlaceholder />}>
      <Provider store={store}>
        <Router history={history}>
          <CssBaseline />
          <Auth0Provider
            domain="auth.questri.app"
            clientId={env.REACT_APP_AUTH0_CLIENT_ID}
            authorizationParams={{ redirect_uri: `${window.location.origin}` }}
            onRedirectCallback={onRedirectCallback}
            cacheLocation="localstorage"
            useRefreshTokens
            useRefreshTokensFallback
            cookieDomain=".questri.app"
          >
            <App />
          </Auth0Provider>
        </Router>
      </Provider>
    </React.Suspense>
  </ThemeProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
