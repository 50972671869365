import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mui/material/Icon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import { useSelector } from 'react-redux';
import NoAccounts from '@mui/icons-material/NoAccounts';
import AccountCircle from '@mui/icons-material/AccountCircle';

import { Box } from '@mui/material';
import { getUser } from '../../utils/userHelper';
import Chip from '../Chip';
import styles from './styles';

const UserComponent = ({
  user,
  secondary,
  mini,
  classes,
  chip,
  bordered,
  disableGutters,
  showDelete,
  onDelete,
  onClick,
  variant,
  userAction,
  cacheBust,
  size,
  chipSize,
  showAccountIndicator,
  ...rest
}) => {
  const barnUsersMap = useSelector((state) => state.barn.barnUsersMap);

  const tenantUser = ((user && user.id) || !barnUsersMap)
    ? user
    : getUser(user, barnUsersMap);

  const {
    userFullName = (user && user.userFullName) || 'Questri System',
    avatar,
  } = tenantUser;

  const miniAvatar = (
    <Avatar
      src={avatar && `${avatar}${cacheBust
        ? `?cacheBust=${new Date().getTime()}`
        : ''}`}
      className={[
        (!(size && mini)
          ? classes.miniAvatar
          : ''),
        bordered
          ? classes.bordered
          : '',
      ].join(' ')}
      classes={(!(size && mini) && { img: classes.miniImg }) || {}}
      sx={(size && mini) && {
        height: size,
        width: size,
      }}
    />
  );

  if (mini) {
    return miniAvatar;
  }

  if (chip) {
    return (
      <Chip
        avatar={miniAvatar}
        label={userFullName}
        disableGutters={disableGutters}
        showDelete={showDelete}
        onDelete={onDelete}
        onClick={onClick}
        variant={variant}
        size={((chipSize === 'l' || chipSize === 'xl') && 'large') || 'small'}
        {...rest}
      />
    );
  }

  return (
    <ListItem
      disableGutters
      classes={{ root: classes.listItem }}
      component="div"
      secondaryAction={
          (showAccountIndicator && !userAction && (
            tenantUser.auth0UserId
              ? (
                <Tooltip title="User has account linked to email and can login">
                  <AccountCircle color="primary" />
                </Tooltip>
              )
              : (
                <Tooltip
                  title={tenantUser.email
                    ? 'User has not logged in or created an account with provided email'
                    : 'No email provided for user, user cannot create an account or login'}
                ><NoAccounts color="secondary" />
                </Tooltip>
              )
          ))
        }
    >
      {
          !!avatar
            && <Avatar src={avatar} className={classes.avatar} />
        }
      {
          !avatar
            && (
              <Avatar className={classes.avatar}>
                <Icon>account_circle</Icon>
              </Avatar>
            )
        }
      <ListItemText
        style={{
          maxWidth: 'fit-content',
          wordBreak: 'break-word',
        }}
        primary={(
          <span style={{ width: '85%' }}>
            <Box>{tenantUser.userFullName}</Box>
            {userAction}
          </span>
        )}
        primaryTypographyProps={{ component: 'div' }}
        secondaryTypographyProps={{
          component: 'div',
          style: { width: '85%' },
        }}
        secondary={secondary}
        classes={{ root: classes.root }}
      />
    </ListItem>
  );
};

UserComponent.propTypes = {
  user: PropTypes.object,
  secondary: PropTypes.string,
  classes: PropTypes.object,
  mini: PropTypes.bool,
  chip: PropTypes.bool,
  bordered: PropTypes.bool,
  disableGutters: PropTypes.bool,
  showDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  userAction: PropTypes.any,
  cacheBust: PropTypes.bool,
  size: PropTypes.number,
  chipSize: PropTypes.any,
  showAccountIndicator: PropTypes.bool,
};

UserComponent.defaultProps = {
  user: {},
  secondary: '',
  classes: {},
  mini: false,
  chip: false,
  bordered: false,
  disableGutters: false,
  showDelete: false,
  onDelete: () => {},
  onClick: () => {},
  variant: 'default',
  userAction: '',
  cacheBust: false,
  size: undefined,
  chipSize: null,
  showAccountIndicator: false,
};

export default withStyles(styles)(UserComponent);
