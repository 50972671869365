import * as React from 'react';
import PropTypes from 'prop-types';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  requestTenants,
  addTenantUserRequest,
} from '@pingum/app-state-management/tenant/actions';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import Launch from '@mui/icons-material/Launch';
import Search from '@mui/icons-material/Search';
import Add from '@mui/icons-material/Add';

import AppStoreButton from '../../AppStoreButton';
import accessDenied from '../../../images/unused-undraw-illustrations/undraw_hello_re_3evm.svg';
import useDebounce from '../../../utils/useDebounce';
import { primary } from '../../../utils/brandColors';
import QuestriCard from '../../QuestriCard';
import TenantAvatar from '../../Tenant/TenantAvatar';
import CreateOrganizationCard from '../CreateOrganization';
import { getQueryParam } from '../../../utils/urlHelper';

const defaultContainerStyle = {
  margin: 'auto',
  padding: '2rem 0 0',
  width: 500,
  maxWidth: '90vw',

};
const buttonContainer = {
  margin: '2rem auto 1rem',
  display: 'block',
  width: 'fit-content',
};
const imageContainer = {
  width: 250,
  display: 'block',
  margin: 'auto',
  marginBottom: '2rem',
};

const LimitedPaidClientAccessCardComponent = ({
  barns,
  authUser,
}) => {
  const dispatch = useDispatch();
  const [createOrganization, setCreateOrganization] = React.useState(false);

  // redux state
  const { isAddingTenant: isLoading } = useSelector((state) => state.tenant);
  const {
    tenants: results,
    isFetching: isSearchingTenants,
  } = useSelector((state) => state.tenant);

  // local state

  const [showAccessAlert, setShowAccessAlert] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const [hasSearched, setHasSearched] = React.useState('');

  const debouncedSearch = useDebounce(search, 500);

  React.useEffect(() => {
    const queryParamTab = getQueryParam('signup');
    if (queryParamTab === 'true') {
      setCreateOrganization(true);
    }
  }, [])

  React.useEffect(() => {
    setShowAccessAlert(null);
    if (debouncedSearch.length >= 2) {
      dispatch(requestTenants(debouncedSearch));
      setHasSearched(true);
    }
  }, [debouncedSearch]);

  const isFetching = isLoading;

  // don't include existing barns, also only questri appss
  const foundTenants = (results || []).filter((t) => t.application === 'questri' && barns.map((existingTenant) => existingTenant.tenant).indexOf(t.name) < 0);

  return (
    !createOrganization
      ? (
        <Box
          sx={{
            mt: 2,
            p: 2,
          }}
        >
          <Avatar
            src={authUser && (authUser['https://pingum.io/avatar'] || authUser.picture)}
            sx={{
              width: '10rem !important',
              height: '10rem !important',
              boxShadow: '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
              position: 'absolute !important',
              left: 'calc(50% - 5rem)',
              top: '5rem',

            }}
          />
          <Box style={defaultContainerStyle}>
            <Paper
              sx={{
                paddingTop: 16,
                paddingBottom: 2,
                marginTop: 3,
                paddingLeft: 0,
                paddingRight: 0,

              }}
              elevation={10}
            >
              <Box>
                <Box
                  sx={{
                    mb: '1rem',
                    p: '0 1rem',
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: 32,
                      fontWeight: 500,
                      mb: 2,
                    }}
                    color="primary"
                    align="center"
                  >Welcome to Questri!
                  </Typography>

                  <img src={accessDenied} alt="man access denied app" style={imageContainer} />
                  <Typography
                    sx={{
                      mb: 1,
                      fontSize: 18,
                    }}
                    align="center"
                  >
                    Select the organization you want to visit.
                  </Typography>
                  <Typography
                    sx={{
                      mb: 1,
                      fontSize: 18,
                    }}
                    align="center"
                  >
                    You can create a new organzation or ask to join an existing one.
                  </Typography>
                  <Typography variant="caption" component="div" sx={{ fontWeight: 300 }} align="center">
                    Don&quot;t see your organization?
                    An organization administrator may need to add you.
                  </Typography>
                </Box>
              </Box>
            </Paper>
            <List component="nav">
              <Paper elevation={10}>
                <ListItem
                  button
                  to="/create-organization"
                  href="/create-organization"
                  onClick={() => setCreateOrganization(true)}
                >

                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: primary.main }}><Add sx={{ color: '#ffffff' }} /></Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Create Your Organization"
                  />
                </ListItem>
              </Paper>

              {
              !!isFetching
              && (
                <Box
                  sx={{
                    textAlign: 'center',
                    mt: 2,
                  }}
                >
                  <CircularProgress />
                </Box>
              )
            }

              {barns.map((barn, index) => (
                <Paper elevation={10} key={`tenant-list-item-${barn.id}`} sx={{ mt: 1 }}>
                  <ListItem
                    divider={index < barns.length - 1}
                    button
                    onClick={() => { window.location.href = `http://${barn.tenant}.${window.location.host}`; }}
                    secondaryAction={(
                      <IconButton edge="end" aria-label="delete">
                        <Launch />
                      </IconButton>
                    )}
                  >
                    <ListItemAvatar>
                      <TenantAvatar
                        tenant={{
                          displayName: barn.name,
                          iconColor: primary.main,
                          avatar: `https://d8adkpqcrx2wo.cloudfront.net/${barn.tenant}.png`,
                        }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={barn.name}
                      secondary={`https://${barn.tenant}.questri.app`}
                    />
                  </ListItem>
                </Paper>
              ))}
            </List>
            <Box style={defaultContainerStyle} sx={{ mt: '100px !important' }}>
              <QuestriCard
                title="Search For Questri Organization"
                actionReplacement={<Search />}
              >
                <Grid container sx={{ p: 2 }}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      component="div"
                    >
                      Search For Questri Organization
                    </Typography>
                    <TextField
                      sx={{
                        mt: 1,
                        mb: 1,
                      }}
                      InputProps={{
                        endAdornment: (isSearchingTenants && <CircularProgress size={16} />),
                        startAdornment: <Search />,
                      }}
                      fullWidth
                      aria-describedby="search-organization-text"
                      variant="outlined"
                      id="search"
                      size="small"
                      label="Search"
                      value={search}
                      disabled={isFetching || isSearchingTenants}
                      onChange={(e) => { setSearch(e.target.value); }}
                    />
                    {
                  showAccessAlert && (
                    <Alert severity="info">
                      <AlertTitle>Request Sent</AlertTitle>
                      Your request to access <strong>{showAccessAlert.displayName}</strong> has been sent. You can only send this once.
                    </Alert>
                  )
                }
                    <List>
                      {foundTenants.map((barn, index) => (
                        <ListItem
                          divider={index < barns.length - 1}
                          key={`tenant-list-item-${barn.id}`}
                          secondaryAction={(
                            <Button
                              edge="end"
                              size="small"
                              variant="contained"
                              aria-label="access"
                              onClick={() => {
                                dispatch(addTenantUserRequest(barn.name, {
                                  auth0UserId: authUser.sub,
                                  email: authUser.email,
                                  firstName: (authUser && authUser['https://pingum.io/firstName']) || '',
                                  lastName: (authUser && authUser['https://pingum.io/lastName']) || '',
                                  passive: false,
                                }));
                                setShowAccessAlert(barn)
                              }}
                            >
                              Request Access
                            </Button>
                          )}
                        >
                          <ListItemAvatar>
                            <TenantAvatar
                              tenant={{
                                displayName: barn.displayName,
                                iconColor: primary.main,
                                icon: `https://${barn.avatar}`,
                              }}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={barn.displayName}
                            secondary={`https://${barn.name}.questri.app`}
                          />
                        </ListItem>
                      ))}
                    </List>
                    {
                  (hasSearched && !isFetching && !isSearchingTenants && foundTenants.length === 0) && (
                    <Typography
                      align="center"
                      sx={{ mt: 1 }}
                    >
                      No results found.
                    </Typography>
                  )
                }

                    <Typography
                      component="div"
                      align="center"
                      gutterBottom
                      sx={{
                        pl: 2,
                        pr: 2,
                        pt: 1,
                      }}
                    >
                      Having trouble or unable find your Questri organization?
                      <div>Contact us at
                        <Button
                          sx={{ marginLeft: '2px' }}
                          color="primary"
                          size="small"
                          href="mailto:support@questri.app?Subject=Help%20Find%20Questri%20Organization"
                          target="_top"
                        >
                          support@questri.app
                        </Button>
                      </div>
                    </Typography>
                  </Grid>
                </Grid>
              </QuestriCard>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Box
                sx={{
                  mt: 2,
                  mb: 2,
                }}
              >
                <Card style={defaultContainerStyle}>
                  <CardHeader
                    subheader="Don't forget to download our mobile app so you can take Questri wherever you go!"
                  />
                  <CardContent>
                    <Grid container justifyContent="center">
                      <Grid
                        item
                      >
                        <AppStoreButton
                          store="ios"
                          url="https://apps.apple.com/us/app/questri/id1634821513"
                          width={150}
                          height={60}
                        />
                        <AppStoreButton
                          store="android"
                          url="https://play.google.com/store/apps/details?id=app.questri"
                          width={150}
                          height={60}
                        />
                      </Grid>

                    </Grid>
                    <div style={buttonContainer}>
                      <Button
                        color="primary"
                        size="small"
                        href="/settings/profile"
                        target="_top"
                        variant="contained"
                      >
                        Go To Your Profile
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </Box>
        </Box>
      )
      : (
        <CreateOrganizationCard onCancel={() => setCreateOrganization(false)} />
      )
  );
}

LimitedPaidClientAccessCardComponent.propTypes = {
  authUser: PropTypes.object,
  barns: PropTypes.array,
};

LimitedPaidClientAccessCardComponent.defaultProps = {
  authUser: {},
  barns: [],
};

export default LimitedPaidClientAccessCardComponent;
