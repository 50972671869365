export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_ERROR = 'GET_INVOICES_ERROR';
export const GET_INVOICES_REQUESTED = 'GET_INVOICES_REQUESTED';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const GET_INVOICE_ERROR = 'GET_INVOICE_ERROR';
export const GET_INVOICE_REQUESTED = 'GET_INVOICE_REQUESTED';
export const GET_INVOICE_PDF_SUCCESS = 'GET_INVOICE_PDF_SUCCESS';
export const GET_INVOICE_PDF_ERROR = 'GET_INVOICE_PDF_ERROR';
export const GET_INVOICE_PDF_REQUESTED = 'GET_INVOICE_PDF_REQUESTED';
export const UPDATE_INVOICES_REQUESTED = 'UPDATE_INVOICES_REQUESTED';
export const UPDATE_INVOICES_SUCCESS = 'UPDATE_INVOICES_SUCCESS';
export const UPDATE_INVOICES_ERROR = 'UPDATE_INVOICES_ERROR';
export const ADD_INVOICES_REQUESTED = 'ADD_INVOICES_REQUESTED';
export const ADD_INVOICES_SUCCESS = 'ADD_INVOICES_SUCCESS';
export const ADD_INVOICES_ERROR = 'ADD_INVOICES_ERROR';
export const DELETE_INVOICES_REQUESTED = 'DELETE_INVOICES_REQUESTED';
export const DELETE_INVOICES_SUCCESS = 'DELETE_INVOICES_SUCCESS';
export const DELETE_INVOICES_ERROR = 'DELETE_INVOICES_ERROR';
