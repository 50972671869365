import PropTypes from 'prop-types';
import React from 'react';

export const BarnIconComponent = ({
  fontSize,
  color,
  margin,
}) => (
  <svg
    style={{
      width: fontSize,
      height: fontSize,
      color,
      margin,
    }}
    viewBox="0 0 24 24"
  ><path d="M12 3L3 8.2V21h6l2.9-3l3.1 3h6V8.2L12 3M7.9 20v-6l3 3l-3 3m1-7h6l-3 3l-3-3m7 7l-3-3l3-3v6m-.9-9H8.8V9H15v2z" fill="currentColor" />
  </svg>
);

BarnIconComponent.propTypes = {
  fontSize: PropTypes.number,
  color: PropTypes.string,
  margin: PropTypes.string,
};

BarnIconComponent.defaultProps = {
  fontSize: 24,
  color: 'rgba(0,0,0,0.54)',
  margin: '0',
};

export default BarnIconComponent;
