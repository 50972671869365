import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Popover,
  List,
  ListItem,
  IconButton,
  Icon,
  Divider,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  Skeleton,
  Avatar,
  Typography,
  Box,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SearchIcon from '@mui/icons-material/Search';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import withStyles from '@mui/styles/withStyles';
import NoteAlt from '@mui/icons-material/NoteAlt';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Restaurant from '@mui/icons-material/Restaurant';
import LocationOn from '@mui/icons-material/LocationOn';
import { Launch } from '@mui/icons-material';
import HorseIcon from '../CustomIcons/Horse';
import { searchQuestri } from '../../redux/search/actions';
import { SEARCH_TYPE } from '../../utils/enumHelper';
import styles from './styles';
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from '../../navigation/AppContainer/appBar';
import Horse from '../Horse';
import User from '../User';
import { getLocationIcon } from '../LocationChip';

const HORSE_PREFIX = '/horses/';
const USER_PREFIX = '/users/';
const LOCATION_PREFIX = '/location/';

const getIconForAvatar = (result) => {
  if (result.type === SEARCH_TYPE.Note) {
    return <NoteAlt />;
  } if (result.type === SEARCH_TYPE.User) {
    return <AccountCircle />;
  } if (result.type === SEARCH_TYPE.Feed) {
    return <Restaurant />;
  } if (result.type === SEARCH_TYPE.Horse) {
    return <HorseIcon color="#fff" />;
  } if (result.type === SEARCH_TYPE.Location) {
    return <LocationOn />;
  }
  return <NoteAlt />;
};

const getIconForExtract = (result) => {
  if (result.type === SEARCH_TYPE.Note) {
    return <NoteAlt color="secondary" />;
  } if (result.type === SEARCH_TYPE.User) {
    return <AccountCircle color="secondary" />;
  } if (result.type === SEARCH_TYPE.Feed) {
    return <Restaurant color="secondary" />;
  } if (result.type === SEARCH_TYPE.Horse) {
    return <HorseIcon color="#9e9e9e" />;
  } if (result.type === SEARCH_TYPE.Location) {
    return <LocationOn color="secondary" />;
  }
  return <NoteAlt color="secondary" />;
};

export class SearchListComponent extends React.Component {
  render() {
    const {
      search,
      navigate,
      handleSearchChange,
      searchText,
      debouncedSearchText,
      searchResults,
      anchorElement,
      hideSearchResults,
      classes,
      isFetching,
      fullScreen,
      barnHorsesMap,
      barnUsersMap,
      locationsMap,
    } = this.props;

    return (
      <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!(anchorElement && debouncedSearchText.length > 0)}
        aria-labelledby="search-results-list"
        anchorEl={anchorElement}
        onClose={hideSearchResults}
        classes={{
          paper:
          fullScreen
            ? classes.popoverPaperFullScreen
            : null,
        }}
      >
        <div
          className={
          fullScreen
            ? classes.notificationContainerFullScreen
            : classes.notificationContainer
        }
        >
          <div className={classes.notificationListHeader}>
            <h5
              className={classes.notificationListHeaderTitle}
              size="small"
            >
              Search Results
            </h5>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                value={searchText}
                onChange={handleSearchChange}
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
            <div>
              <IconButton onClick={hideSearchResults}>
                <Icon className={classes.close}>close</Icon>
              </IconButton>
            </div>
          </div>
          <Divider />
          <List className={classes.list} dense>
            {
              isFetching && [1, 2, 3, 4, 5].map((item) => (
                <ListItem
                  key={`search-result-loading-${item}`}
                >
                  <ListItemButton>
                    <ListItemAvatar>
                      <Skeleton variant="circular" width={40} height={40} />
                    </ListItemAvatar>
                    <ListItemText
                      primaryTypographyProps={{ component: 'div' }}
                      secondaryTypographyProps={{ component: 'div' }}
                      primary={<Skeleton height={20} width="65%" />}
                      secondary={(
                        <>
                          <Skeleton height={15} width="95%" style={{ marginBottom: 6 }} />
                          <Skeleton height={15} width="50%" />
                        </>
                      )}
                    />
                  </ListItemButton>
                </ListItem>
              ))
            }
            {
              searchResults.content.map((result) => {
                let horse = null;
                let user = null;
                let location = null;
                let title = null;
                if (result.url && result.url.indexOf(HORSE_PREFIX.length) > 0) {
                  horse = barnHorsesMap[result.url.substr(HORSE_PREFIX.length)] && barnHorsesMap[result.url.substr(HORSE_PREFIX.length)][0];
                  title = horse && horse.horse.name;
                }
                if (result.url && result.url.indexOf(USER_PREFIX.length) > 0) {
                  user = barnUsersMap[result.url.substr(USER_PREFIX.length)] && barnUsersMap[result.url.substr(USER_PREFIX.length)][0];
                  title = user && user.userFullName;
                }
                if (result.url && result.url.indexOf(LOCATION_PREFIX.length) > 0) {
                  location = locationsMap[result.url.substr(LOCATION_PREFIX.length)] && locationsMap[result.url.substr(LOCATION_PREFIX.length)][0];
                  title = location && `${location.name} (${location.tyle})`;
                }

                return (
                  <ListItem
                    button
                    divider
                    key={`search-result-${result.id}`}
                    onClick={() => { hideSearchResults(); navigate(result.url); }}

                  >
                    <ListItemButton sx={{ alignItems: 'flex-start' }}>
                      <ListItemAvatar>
                        {
                          horse && (
                            <Horse hideName horse={horse.horse} />
                          )
                        }
                        {
                          user && (
                            <User mini user={user} size={40} />
                          )
                        }
                        {
                          location && (
                            <Avatar>
                              {getLocationIcon(location.type)}
                            </Avatar>
                          )
                        }
                        {
                          !horse && !user && !location && (
                            <Avatar>
                              {getIconForAvatar(result)}
                            </Avatar>
                          )
                        }
                      </ListItemAvatar>
                      <ListItemText primary={title || result.title} primaryTypographyProps={{ variant: 'h5' }} secondary={result.description} />
                    </ListItemButton>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      { getIconForExtract(result) }
                      <Launch color="primary" sx={{ ml: 0.5 }} />
                    </Box>
                  </ListItem>
                )
              })
            }
            {!isFetching && searchResults.content.length === 0 && <Typography variant="caption" align="center">No Results Found.</Typography>}
            {!searchResults.last
              && (
                <ListItem>
                  <div className={classes.center}>
                    <LoadingButton
                      variant="outlined"
                      color="primary"
                      disabled={isFetching}
                      loading={isFetching}
                      loadingPosition="start"
                      startIcon={<NavigateNextIcon />}
                      onClick={() => search(
                        searchText,
                        (searchResults.number + 1),
                        searchResults.size,
                      )}
                    >
                      Load Next Results
                    </LoadingButton>
                  </div>
                </ListItem>
              )}
          </List>
        </div>
      </Popover>
    );
  }
}

SearchListComponent.propTypes = {
  searchText: PropTypes.string,
  debouncedSearchText: PropTypes.string,
  handleSearchChange: PropTypes.func,
  searchResults: PropTypes.object,
  anchorElement: PropTypes.object,
  search: PropTypes.func,
  navigate: PropTypes.func,
  hideSearchResults: PropTypes.func,
  classes: PropTypes.object,
  isFetching: PropTypes.bool,
  fullScreen: PropTypes.bool,
  barnHorsesMap: PropTypes.object,
  barnUsersMap: PropTypes.object,
  locationsMap: PropTypes.object,
};

SearchListComponent.defaultProps = {
  searchText: '',
  debouncedSearchText: '',
  handleSearchChange: () => {},
  searchResults: {
    content: [],
    page: 0,
    size: 0,
    last: true,
  },
  anchorElement: null,
  search: () => {},
  navigate: () => {},
  hideSearchResults: () => {},
  classes: {},
  isFetching: false,
  fullScreen: false,
  barnHorsesMap: {},
  barnUsersMap: {},
  locationsMap: {},
};

export const mapStateToProps = (state) => ({
  searchResults: state.search.search,
  isFetching: state.search.isSearching,
  barnHorsesMap: state.barn.barnHorsesMap,
  barnUsersMap: state.barn.barnUsersMap,
  locationsMap: state.location.locationsMap,
});

export const mapDispatchToProps = (dispatch) => ({ search: (searchText, page, size) => dispatch(searchQuestri(searchText, page, size)) });

export default (withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchListComponent)));
