import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import AppStoreButton from '../../AppStoreButton';
import accessDenied from '../../../images/unused-undraw-illustrations/undraw_access_denied_re_awnf.svg';

const defaultCardStyle = {
  width: '500px',
  margin: 'auto',
  maxWidth: '90vw',
};
const buttonContainer = {
  margin: '2rem auto 1rem',
  display: 'block',
  width: 'fit-content',
};
const imageContainer = {
  width: 250,
  display: 'block',
  margin: 'auto',
  marginBottom: '1rem',
};

const LimitedPaidClientAccessCardComponent = ({ selectedBarn = { name: 'Your barn' } }) => (
  <Box sx={{ mt: 2 }}>
    <Card style={defaultCardStyle}>
      <CardHeader
        title={`${selectedBarn.name || ''} Welcomes You To Questri!`}
        subheader="Download the Questri mobile app to access additional features"
      />
      <CardContent>
        <img src={accessDenied} alt="man access denied app" style={imageContainer} />

        <Typography variant="body2" gutterBottom>
          The Questri website has limited features for clients and riders.
          Additional features are available through the Questri mobile app.
        </Typography>
        <Divider
          sx={{
            mt: 2,
            mb: 2,
          }}
        />
        <Typography variant="h5" align="center" gutterBottom color="primary">
          Ask {selectedBarn.name} about how you can get access to the Questri mobile app.
        </Typography>
        <Grid container justifyContent="center">
          <Grid
            item
          >
            <AppStoreButton
              store="ios"
              url="https://apps.apple.com/us/app/questri/id1634821513"
              width={150}
              height={60}
            />
            <AppStoreButton
              store="android"
              url="https://play.google.com/store/apps/details?id=app.questri"
              width={150}
              height={60}
            />
          </Grid>

        </Grid>
        <div style={buttonContainer}>
          <Button
            color="primary"
            size="small"
            href="/settings/profile"
            target="_top"
            variant="contained"
          >
            Go To Profile
          </Button>
        </div>
        <Typography variant="caption" component="div" align="center" gutterBottom>
          If you feel this is a mistake and you need a different role, please
          contact an admin for this Questri organization.
        </Typography>
      </CardContent>
    </Card>
  </Box>
);

LimitedPaidClientAccessCardComponent.propTypes = { selectedBarn: PropTypes.object };

LimitedPaidClientAccessCardComponent.defaultProps = { selectedBarn: { name: 'Your barn' } };

export default LimitedPaidClientAccessCardComponent;
