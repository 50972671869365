// import Joi from 'joi-browser';
import HttpService from '@pingum/api-services/HttpService';

export default class EventTemplateReminderService {
  static deleteEventTemplateReminder(id) {
    if (!id) {
      return Promise.reject(new Error('Missing Rask Reminders ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.delete(`/${id}`).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static updateEventTemplateReminder(id, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Task Reminder ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.patch(`/${id}`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static getEventTemplateReminders() {
    return this.httpService.get('');
  }

  static getEventTemplateReminderTagReminders(id) {
    return this.httpService.get(`/${id}/tagReminders`);
  }
}

EventTemplateReminderService.httpService = new HttpService({
  baseURL: `${typeof window !== 'undefined'
    ? env.REACT_APP_EVENT_API
    : ''}/eventTemplateReminders`,
});
