import { blueGrey } from '@mui/material/colors';

export default () => ({
  label: { fontSize: '12px' },
  labelLarge: { fontSize: '16px' },
  icon: {
    fontSize: '16px',
    marginLeft: '6px',
  },
  iconLarge: {
    fontSize: '22px',
    marginLeft: '6px',
  },
  chip: {
    margin: '0 .5em .5em 0',
    backgroundColor: blueGrey[50],
    color: 'rgba(0,0,0,0.87)',
  },
  chipNoGutters: {
    margin: '0',
    backgroundColor: blueGrey[50],
    color: 'rgba(0,0,0,0.87)',
  },
  deletableChip: { padding: '0 8px' },
  deleteIcon: {
    margin: '0 0px 0 -6px',
    height: '16px',
  },
});
