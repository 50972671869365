import PropTypes from 'prop-types';
import React from 'react';
import {
  Box,
  Button,
  Dialog,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PricingPlans from '../PricingPlans';

export const StripeCheckoutButtonComponent = ({
  tenant,
  buttonText,
  ...buttonProps
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Button {...buttonProps} onClick={() => setOpen(true)}>{buttonText || 'Subscribe'}</Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="xl"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="info-modal-title"
        aria-describedby="info-modal-description"
      >
        <Box
          sx={{
            bgcolor: 'rgb(244, 244, 244)',
            p: 2,
          }}
        >
          <PricingPlans stripeCheckout tenant={tenant} />
        </Box>
      </Dialog>
    </>

  );
}

StripeCheckoutButtonComponent.propTypes = {
  tenant: PropTypes.object.isRequired,
  buttonText: PropTypes.string,
};

StripeCheckoutButtonComponent.defaultProps = { buttonText: null };

export default StripeCheckoutButtonComponent;
