import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  IconButton,
  Snackbar,
  SnackbarContent,
  Icon,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Close as CloseIcon } from '@mui/icons-material';
import { removeSuccess } from '@pingum/app-state-management/success/actions';
import * as brandColors from '../../../utils/brandColors';

export const styles = (theme) => ({
  close: { padding: theme.spacing(0.5) },
  snackbar: { backgroundColor: brandColors.primary.main },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const SuccessContainerComponent = (props) => (
  <div>
    {
      props.successes.map((success) => {
        const close = () => {
          props.removeSuccess(success.id);
        };
        setTimeout(close, props.successTimeout);
        return (
          <Snackbar
            key={success.id}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open
            onClose={close}
          >
            <SnackbarContent
              className={props.classes.snackbar}
              aria-describedby="message-id"
              message={(
                <span id="message-id" className={props.classes.message}>
                  <Icon className={props.classes.icon}> check_circle </Icon>
                  {success.message}
                </span>
              )}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={props.classes.close}
                  onClick={close}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            />
          </Snackbar>
        );
      })
    }
  </div>
);

SuccessContainerComponent.propTypes = {
  classes: PropTypes.object,
  successes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    message: PropTypes.string,
    dismissable: PropTypes.bool,
  })),
  successTimeout: PropTypes.number,
  removeSuccess: PropTypes.func,
};

SuccessContainerComponent.defaultProps = {
  classes: {},
  successes: [],
  successTimeout: 7000,
  removeSuccess: () => {},
};

export const mapStateToProps = (state) => ({ successes: state.success.successMessages });

export const mapDispatchToProps = (dispatch) => ({ removeSuccess: (id) => dispatch(removeSuccess(id)) });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(SuccessContainerComponent));
