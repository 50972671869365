import * as types from './types';

export default (state = {
  // login
  isAuthenticated: null,
  isLoggingIn: false,
  loginError: '',
  // check email
  isCheckingEmail: false,
  checkEmailUser: null,
  organizationAuth0UserLimitReached: false,
  // password reset
  isSendingPasswordResetEmail: false,
  passwordResetSuccess: false,
  // Questri/Pingum
  tenants: [],
  updateUserError: false,
  isFetchingUserTenants: false,
  isFetchingUser: false,
  isSearchingUsers: false,
  me: null,
  user: null,
  barnUser: null,
  horseAccess: [],
  paidClientAccess: false,
  isFetchingMeHorseAccess: false,
  permissions: {
    owner: false,
    admin: false,
    staff: false,
    restricted: false,
  },
  isClient: true,
  // horse note
  meHorseNotes: { content: [] },
  isFetchingMeHorseNotes: false,
}, action = {}) => {
  switch (action.type) {
    case types.SET_AUTHENTICATION:
      return {
        ...state,
        isLoggingIn: false,
        loginError: '',
        isAuthenticated: action.isAuthenticated,
        passwordResetSuccess: false,
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        loginError: '',
        isAuthenticated: false,
        passwordResetSuccess: false,
        tenants: [],
        user: null,
        barnUser: null,
        permissions: {
          owner: false,
          admin: false,
          staff: false,
          restricted: false,
        },
        isClient: true,
      };
    case types.LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        loginError: '',
        isAuthenticated: false,
        passwordResetSuccess: false,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        loginError: '',
        isAuthenticated: !!action.profile,
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        user: null,
        isLoggingIn: false,
        loginError: action.error || 'There was an error logging in. If the issue persists, please contact support@questri.app.',
        isAuthenticated: false,
      };
    case types.PASSWORD_RESET_REQUEST:
      return {
        ...state,
        passwordResetSuccess: false,
        isSendingPasswordResetEmail: true,
        loginError: '',
      };
    case types.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        passwordResetSuccess: true,
        isSendingPasswordResetEmail: false,
      };
    case types.PASSWORD_RESET_ERROR:
      return {
        ...state,
        passwordResetSuccess: false,
        isSendingPasswordResetEmail: false,
        loginError: action.customError || 'There was an error sending the password reset email. If the issue persists, please contact support@questri.app.',
      };
    case types.USER_TENANTS_REQUESTED:
      return {
        ...state,
        isFetchingUserTenants: true,
      };
    case types.USER_TENANTS_SUCCESS:
      return {
        ...state,
        isFetchingUserTenants: false,
        tenants: action.tenants,
      };
    case types.USER_TENANTS_ERROR:
    case types.CHECK_EMAIL_REQUESTED:
      return {
        ...state,
        isCheckingEmail: true,
        checkEmailUser: null,
        organizationAuth0UserLimitReached: false,
      };
    case types.CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        isCheckingEmail: false,
        checkEmailUser: action.checkEmailUser,
        organizationAuth0UserLimitReached: action.organizationAuth0UserLimitReached || false,
      };
    case types.CHECK_EMAIL_ERROR:
      return {
        ...state,
        isCheckingEmail: false,
        checkEmailUser: null,
        organizationAuth0UserLimitReached: false,
      }
    case types.GET_ME_BARN_USER_REQUESTED:
      return {
        ...state,
        isFetchingUser: true,
      };
    case types.GET_ME_BARN_USER_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const permissions = {
        owner: action.barnUser.roles.indexOf('owner') > -1,
        admin: action.barnUser.roles.indexOf('admin') > -1,
        staff: action.barnUser.roles.indexOf('staff') > -1,
        restricted: action.barnUser.roles.indexOf('restricted') > -1,
      };
      return {
        ...state,
        isFetchingUser: false,
        barnUser: action.barnUser,
        user: action.barnUser,
        permissions,
        isClient: !(permissions.admin || permissions.owner || permissions.staff),
      };
    case types.GET_ME_BARN_USER_ERROR:
      return {
        ...state,
        isFetchingUser: false,
        permissions: {
          owner: false,
          admin: false,
          staff: false,
          restricted: false,
        },
        isClient: true,
      };
    case types.GET_ME_USER_REQUESTED:
      return {
        ...state,
        me: null,
      };
    case types.GET_ME_USER_SUCCESS:
      return {
        ...state,
        me: action.me,
      };
    case types.GET_ME_USER_ERROR:
      return {
        ...state,
        me: null,
      };
    case types.UPDATE_ME_REQUESTED:
      return {
        ...state,
        updateUserError: false,
        isFetchingUser: true,
      };
    case types.UPDATE_ME_SUCCESS:
      return {
        ...state,
        isFetchingUser: false,
        updateUserError: false,
        user: {
          ...state.barnUser,
          ...action.user,
        },
        barnUser: {
          ...state.barnUser,
          ...action.user,
        },
      };
    case types.UPDATE_ME_ERROR:
      return {
        ...state,
        updateUserError: true,
        isFetchingUser: false,
      };
    case types.GET_ME_HORSE_ACCESS_REQUESTED:
      return {
        ...state,
        isFetchingMeHorseAccess: true,
        paidClientAccess: false,
      };
    case types.GET_ME_HORSE_ACCESS_SUCCESS:
      return {
        ...state,
        isFetchingMeHorseAccess: false,
        horseAccess: action.horseAccess,
        // TODO should we check the client access type? Client vs Rider vs Other?
        paidClientAccess: action.horseAccess.some((ha) => (ha.horseLodging
          ? ha.horseLodging.paidClientAccessSeat
          : false)),
      };
    case types.GET_ME_HORSE_ACCESS_ERROR:
      return {
        ...state,
        horseAccess: [],
        isFetchingMeHorseAccess: false,
        paidClientAccess: false,
      };
    case types.GET_BARN_USER_NOTES_REQUESTED:
      return {
        ...state,
        isFetchingMeHorseNotes: true,
        meHorseNotes: { content: [] },
      };
    case types.GET_BARN_USER_NOTES_SUCCESS:
      return {
        ...state,
        isFetchingMeHorseNotes: false,
        meHorseNotes: action.horseNotes,
      };
    case types.GET_BARN_USER_NOTES_ERROR:
      return {
        ...state,
        isFetchingMeHorseNotes: false,
        meHorseNotes: { content: [] },
      };
    default:
      return state;
  }
};
