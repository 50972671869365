import {
  TenantService,
  AuthService,
} from '@pingum/api-services';
import * as urlHelper from './urlHelper';

export const isPublicPage = () => window.location.href.indexOf('/forms/') >= 0;

export const redirectOnUnauthenticatedTenant = () => {
  const tenantName = urlHelper.getTenantFromUrl();

  if (tenantName && !AuthService.isLoggedIn() && !isPublicPage()) {
    const redirectPath = '/login';
    const parameters = `tenant=${tenantName}&redirect=${window.location.pathname}`;
    const loginRedirect = `${window.location.protocol}//${env.REACT_APP_DOMAIN}${redirectPath}?${parameters}`;
    window.location.assign(loginRedirect);
    return loginRedirect;
  }
  return null;
};

export const redirectToSubdomain = ({
  tenant,
  redirect,
}) => {
  const accessToken = AuthService.getAccessToken();
  const idToken = AuthService.getToken();

  const loginRedirect = `${TenantService.getTenantUrl(tenant)}/login?access_token=${accessToken}&id_token=${idToken}&redirect=${redirect}`;
  window.location.assign(loginRedirect);
  return loginRedirect;
};

export const isUserAdmin = (roles) => roles.indexOf('admin') > -1 || roles.indexOf('owner') > -1;
export const isUserOwner = (roles) => roles.indexOf('owner') > -1;
export const isUserDeveloper = (roles) => roles.indexOf('developer') > -1 || roles.indexOf('admin') > -1 || roles.indexOf('owner') > -1;
export const isUserRestricted = (roles) => roles.indexOf('restricted') > -1;
