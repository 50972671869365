import * as brandColors from '../../utils/brandColors';

export default () => ({
  chip: {
    margin: '0.5em',
    color: 'rgba(0,0,0,0.87) !important',
  },
  chipWithSubheader: {
    margin: '1rem',
    color: 'rgba(0,0,0,0.87) !important',
  },
  cardHeaderTitle: {
    '& span': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  cardSubheaderTitle: {
    '& span': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: 'white',
  },
  cardHeaderAvatar: { marginRight: '10px' },
  cardHeaderRoot: {
    padding: '8px 12px',
    boxShadow: `0 1px 3px 0 ${brandColors.grey[400]}`,
    borderBottom: brandColors.grey[200],
    background: brandColors.blueGrey[400],
    color: 'white',
  },
});
