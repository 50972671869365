// App
export const SET_SELECTED_BARN_HORSE = 'SET_SELECTED_BARN_HORSE';
export const SET_GLOABL_MODALS = 'SET_GLOABL_MODALS';
export const SET_MY_TIMEZONE = 'SET_MY_TIMEZONE';

export const STATUS_CHANGE_MODAL_TYPE = 'STATUS_CHANGE_MODAL_TYPE';
export const TASK_MODAL_TYPE = 'TASK_MODAL_TYPE';
export const SCHEDULE_MODAL_TYPE = 'SCHEDULE_MODAL_TYPE';

export const GET_BILLABLE_FOR_TASK_SUCCESS = 'GET_BILLABLE_FOR_TASK_SUCCESS';
export const GET_BILLABLE_FOR_TASK_ERROR = 'GET_BILLABLE_FOR_TASK_ERROR';
export const GET_BILLABLE_FOR_TASK_REQUESTED = 'GET_BILLABLE_FOR_TASK_REQUESTED';
export const GET_NOTE_FOR_TASK_SUCCESS = 'GET_NOTE_FOR_TASK_SUCCESS';
export const GET_NOTE_FOR_TASK_ERROR = 'GET_NOTE_FOR_TASK_ERROR';
export const GET_NOTE_FOR_TASK_REQUESTED = 'GET_NOTE_FOR_TASK_REQUESTED';
