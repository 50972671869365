import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import withStyles from '@mui/styles/withStyles';
import Chip from '../Chip';

import styles from './styles';

const QuestriCard = ({
  title,
  titleVariant,
  titleComponent,
  subheader,
  avatar,
  children,
  classes,
  actionCount,
  actionReplacement,
  height,
  basic,
  className,
  chipSize,
  hideOverflow,
}) => (
  <Card
    sx={{
      height: height || null,
      backgroundColor: basic
        ? 'transparent'
        : null,
      boxShadow: basic
        ? 'none'
        : null,
    }}
    className={className}
  >
    {!basic && (
      <CardHeader
        title={<Typography component={titleComponent} variant={titleVariant}>{title}</Typography>}
        avatar={avatar}
        subheader={subheader}
        action={actionReplacement || (actionCount && (
          <Chip
            color="default"
            label={actionCount}
            size={chipSize}
            classes={{
              chip: !subheader
                ? classes.chip
                : classes.chipWithSubheader,
            }}
          />
        ))}
        classes={{
          avatar: avatar
            ? classes.cardHeaderAvatar
            : '',
          content: classes.cardHeaderTitle,
          subheader: classes.cardSubheaderTitle,
          root: classes.cardHeaderRoot,
        }}
      />
    )}
    <CardContent
      sx={{
        padding: 0,
        '&:last-child': { paddingBottom: 0 },
        height: (height && height - 40) || 'default',
        overflowY: hideOverflow
          ? 'hidden'
          : 'auto',
      }}
    >
      {children}
    </CardContent>
  </Card>
);

QuestriCard.propTypes = {
  title: PropTypes.any,
  titleVariant: PropTypes.string,
  titleComponent: PropTypes.string,
  subheader: PropTypes.string,
  avatar: PropTypes.any,
  children: PropTypes.any,
  classes: PropTypes.object,
  actionCount: PropTypes.number,
  actionReplacement: PropTypes.any,
  height: PropTypes.any,
  basic: PropTypes.bool, // Hide header and card styles
  className: PropTypes.string,
  chipSize: PropTypes.string,
  hideOverflow: PropTypes.bool,
};

QuestriCard.defaultProps = {
  title: '',
  titleVariant: 'body1',
  titleComponent: null,
  subheader: null,
  avatar: null,
  children: null,
  classes: {},
  actionCount: 0,
  actionReplacement: null,
  height: null,
  basic: false,
  className: '',
  chipSize: 'small',
  hideOverflow: false,
};

export default withStyles(styles)(QuestriCard);
