// Feed
export const GET_INVOICE_HEADER_REQUESTED = 'GET_INVOICE_HEADER_REQUESTED';
export const GET_INVOICE_HEADER_SUCCESS = 'GET_INVOICE_HEADER_SUCCESS';
export const GET_INVOICE_HEADER_ERROR = 'GET_INVOICE_HEADER_ERROR';
export const UPDATE_INVOICE_HEADER_REQUESTED = 'UPDATE_INVOICE_HEADER_REQUESTED';
export const UPDATE_INVOICE_HEADER_SUCCESS = 'UPDATE_INVOICE_HEADER_SUCCESS';
export const UPDATE_INVOICE_HEADER_ERROR = 'UPDATE_INVOICE_HEADER_ERROR';
export const ADD_INVOICE_HEADER_REQUESTED = 'ADD_INVOICE_HEADER_REQUESTED';
export const ADD_INVOICE_HEADER_SUCCESS = 'ADD_INVOICE_HEADER_SUCCESS';
export const ADD_INVOICE_HEADER_ERROR = 'ADD_INVOICE_HEADER_ERROR';
