
export const ADD_HORSE_ACCESS_REQUEST_REQUESTED = 'ADD_HORSE_ACCESS_REQUEST_REQUESTED';
export const ADD_HORSE_ACCESS_REQUEST_SUCCESS = 'ADD_HORSE_ACCESS_REQUEST_SUCCESS';
export const ADD_HORSE_ACCESS_REQUEST_ERROR = 'ADD_HORSE_ACCESS_REQUEST_ERROR';
export const GET_HORSE_ACCESS_REQUESTS_REQUESTED = 'GET_HORSE_ACCESS_REQUESTS_REQUESTED';
export const GET_HORSE_ACCESS_REQUESTS_SUCCESS = 'GET_HORSE_ACCESS_REQUESTS_SUCCESS';
export const GET_HORSE_ACCESS_REQUESTS_ERROR = 'GET_HORSE_ACCESS_REQUESTS_ERROR';
export const UPDATE_HORSE_ACCESS_REQUEST_REQUESTED = 'UPDATE_HORSE_ACCESS_REQUEST_REQUESTED';
export const UPDATE_HORSE_ACCESS_REQUEST_SUCCESS = 'UPDATE_HORSE_ACCESS_REQUEST_SUCCESS';
export const UPDATE_HORSE_ACCESS_REQUEST_ERROR = 'UPDATE_HORSE_ACCESS_REQUEST_ERROR';
