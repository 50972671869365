import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './styles';

class EmptyList extends React.Component {
  render() {
    const {
      classes,
      title,
      description,
      icon,
    } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.flex} />
        <Icon
          name="empty-list-icon"
          className={classes.icon}
        >
          {icon}
        </Icon>
        <div
          name="empty-list-title"
          className={classes.title}
        >
          {title}
        </div>
        <div
          name="empty-list-description"
          className={classes.description}
        >
          {description}
        </div>
        <div className={classes.flex} />
      </div>
    );
  }
}

EmptyList.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.any,
  icon: PropTypes.string,
};

EmptyList.defaultProps = {
  classes: {},
  title: '',
  description: '',
  icon: '',
};

export default withStyles(styles)(EmptyList);
