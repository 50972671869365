import React from 'react';
import PropTypes from 'prop-types';
import * as brandColors from '../../utils/brandColors';
import pingumLogo from '../../images/icon.png';

const QuestriLogo = ({
  fontSize,
  removePadding,
  color,
  iconOnly,
  replacementLogo,
}) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'flex-end',
      minHeight: fontSize,
      minWidth: fontSize,
      padding: removePadding || iconOnly
        ? '0px'
        : '14px 10px',
    }}
  >
    <img style={{ height: fontSize }} src={replacementLogo || pingumLogo} alt="Questri Logo" />
    {
      !iconOnly
        && (
          <h5
            id="title"
            color="inherit"
            style={{
              flex: 1,
              margin: '0 12px',
              fontSize: `${fontSize - 11}px`,
              lineHeight: `${fontSize}px`,
              fontWeight: 500,
              color,
            }}
          >
            Questri
          </h5>
        )
    }
  </div>
);

QuestriLogo.propTypes = {
  fontSize: PropTypes.number,
  removePadding: PropTypes.bool,
  color: PropTypes.string,
  iconOnly: PropTypes.bool,
  replacementLogo: PropTypes.any,
};

QuestriLogo.defaultProps = {
  fontSize: 38,
  removePadding: false,
  color: brandColors.primary.main,
  iconOnly: false,
  replacementLogo: null,
};

export default QuestriLogo;
