export default () => ({
  horseName: {
    fontSize: 16,
    lineHeight: '18px',
  },
  horseShowName: {
    color: '#858585',
    fontSize: 14,
    lineHeight: '16px',
  },
  miniAvatar: {
    display: 'inline-block',
    height: 32,
    width: 32,
  },
  miniImg: { verticalAlign: 'top' },
  bordered: { border: '2px solid white' },
});
