import * as types from './types';

export default (state = {
  invoiceHeaders: [],
  isFetchingInvoiceHeaders: false,
}, action = {}) => {
  switch (action.type) {
    case types.GET_INVOICE_HEADER_REQUESTED:
      return {
        ...state,
        invoiceHeaders: [],
        isFetchingInvoiceHeaders: true,
      };
    case types.GET_INVOICE_HEADER_SUCCESS:
      return {
        ...state,
        isFetchingInvoiceHeaders: false,
        invoiceHeaders: action.invoiceHeaders,
      };
    case types.GET_INVOICE_HEADER_ERROR:
      return {
        ...state,
        isFetchingInvoiceHeaders: false,
      };
    case types.ADD_INVOICE_HEADER_REQUESTED:
      return {
        ...state,
        isFetchingInvoiceHeaders: true,
      };
    case types.ADD_INVOICE_HEADER_SUCCESS:
      return {
        ...state,
        isFetchingInvoiceHeaders: false,
        invoiceHeaders: [{
          ...action.newInvoiceHeader,
          new: true, // signal in UI to show newly added header
        }].concat(state.invoiceHeaders),
      };
    case types.ADD_INVOICE_HEADER_ERROR:
      return {
        ...state,
        isFetchingInvoiceHeaders: false,
      };
    case types.UPDATE_INVOICE_HEADER_REQUESTED:
      return {
        ...state,
        isFetchingInvoiceHeaders: true,
      };
    case types.UPDATE_INVOICE_HEADER_SUCCESS:
      return {
        ...state,
        isFetchingInvoiceHeaders: false,
        invoiceHeaders: state.invoiceHeaders.map((f) => (f.id === action.updatedInvoiceHeader.id
          ? action.updatedInvoiceHeader
          : f))
        ,
      };
    case types.UPDATE_INVOICE_HEADER_ERROR:
      return {
        ...state,
        isFetchingInvoiceHeaders: false,
      };

    default:
      return state;
  }
};

