import StreamService from '../../services/StreamService';
import * as types from './types';
import { dispatchNetworkRequest } from '../networkDispatcher';

export const getStreamUser = () => (dispatch) => {
  const promise = StreamService.getMe();
  dispatchNetworkRequest({
    dispatch,
    requestType: types.GET_ME_STREAM_REQUESTED,
    successType: types.GET_ME_STREAM_SUCCESS,
    errorType: types.GET_ME_STREAM_ERROR,
    promise,
    defaultErrorMessage: 'There was a problem loading your user',
    responseName: 'streamUser',
  });
  return promise;
};
