export default () => ({
  loadingContainer: {
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 100,
  },
  imageContainer: {
    width: '160px',
    '-webkit-animation': 'fade 1s linear alternate infinite',
    animation: 'fade 1s linear alternate infinite',
    position: 'absolute',
    top: 'calc(50vh - 49px)',
    left: 'calc(50vw - 80px)',
  },
  logo: {
    width: '60px',
    margin: '0 auto 2em',
    display: 'block',
  },
});
