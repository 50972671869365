import {
  compose,
  createStore,
  applyMiddleware,
} from 'redux';
import ReduxThunk from 'redux-thunk';
import rootReducer from './reducer';

export default (preloadedState) => createStore(
  rootReducer,
  preloadedState,
  compose(
    applyMiddleware(ReduxThunk),
    window.__REDUX_DEVTOOLS_EXTENSION__ //eslint-disable-line
      ? window.__REDUX_DEVTOOLS_EXTENSION__() //eslint-disable-line
      : (f) => f,
  ),
);
