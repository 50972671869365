import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';

const getSrc = (avatar) => {
  if (avatar && avatar.indexOf('http') >= 0) {
    // to avoid browser cache append random param
    return `${avatar}?date=${new Date().toISOString()}`;
  }
  return avatar;
};

const TenantAvatar = (props) => (
  <Avatar
    style={{
      color: props.tenant.iconColor,
      backgroundColor: '#F2F2F2',
      fontSize: '1.25rem',
      lineHeight: '1rem',
    }}
    alt={props.tenant.displayName.charAt(0)}
    src={getSrc(props.tenant.avatar)}
    sx={props.sx || undefined}
  >
    {
      props.tenant
      && props.tenant.displayName
      && props.tenant.displayName.charAt(0)
    }
  </Avatar>
)

TenantAvatar.propTypes = {
  tenant: PropTypes.object.isRequired,
  sx: PropTypes.object,
};
TenantAvatar.defaultProps = { sx: undefined }

export default TenantAvatar;
