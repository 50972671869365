// Feed
export const GET_FEED_REQUESTED = 'GET_FEED_REQUESTED';
export const GET_FEED_SUCCESS = 'GET_FEED_SUCCESS';
export const GET_FEED_ERROR = 'GET_FEED_ERROR';
export const UPDATE_FEED_REQUESTED = 'UPDATE_FEED_REQUESTED';
export const UPDATE_FEED_SUCCESS = 'UPDATE_FEED_SUCCESS';
export const UPDATE_FEED_ERROR = 'UPDATE_FEED_ERROR';
export const ADD_FEED_REQUESTED = 'ADD_FEED_REQUESTED';
export const ADD_FEED_SUCCESS = 'ADD_FEED_SUCCESS';
export const ADD_FEED_ERROR = 'ADD_FEED_ERROR';
export const DELETE_FEED_REQUESTED = 'DELETE_FEED_REQUESTED';
export const DELETE_FEED_SUCCESS = 'DELETE_FEED_SUCCESS';
export const DELETE_FEED_ERROR = 'DELETE_FEED_ERROR';
// Horse Feed
export const GET_HORSE_FEED_REQUESTED = 'GET_HORSE_FEED_REQUESTED';
export const GET_HORSE_FEED_SUCCESS = 'GET_HORSE_FEED_SUCCESS';
export const GET_HORSE_FEED_ERROR = 'GET_HORSE_FEED_ERROR';
