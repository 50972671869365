// HORSE_ACCESS
export const GET_HORSE_ACCESS_BY_TYPE_SUCCESS = 'GET_HORSE_ACCESS_BY_TYPE_SUCCESS';
export const GET_HORSE_ACCESS_BY_TYPE_ERROR = 'GET_HORSE_ACCESS_BY_TYPE_ERROR';
export const GET_HORSE_ACCESS_BY_TYPE_REQUESTED = 'GET_HORSE_ACCESS_BY_TYPE_REQUESTED';
export const GET_HORSE_ACCESS_REQUESTED = 'GET_HORSE_ACCESS_REQUESTED';
export const GET_HORSE_ACCESS_SUCCESS = 'GET_HORSE_ACCESS_SUCCESS';
export const GET_HORSE_ACCESS_ERROR = 'GET_HORSE_ACCESS_ERROR';
export const UPDATE_HORSE_ACCESS_REQUESTED = 'UPDATE_HORSE_ACCESS_REQUESTED';
export const UPDATE_HORSE_ACCESS_SUCCESS = 'UPDATE_HORSE_ACCESS_SUCCESS';
export const UPDATE_HORSE_ACCESS_ERROR = 'UPDATE_HORSE_ACCESS_ERROR';
export const ADD_HORSE_ACCESS_REQUESTED = 'ADD_HORSE_ACCESS_REQUESTED';
export const ADD_HORSE_ACCESS_SUCCESS = 'ADD_HORSE_ACCESS_SUCCESS';
export const ADD_HORSE_ACCESS_ERROR = 'ADD_HORSE_ACCESS_ERROR';
export const DELETE_HORSE_ACCESS_REQUESTED = 'DELETE_HORSE_ACCESS_REQUESTED';
export const DELETE_HORSE_ACCESS_SUCCESS = 'DELETE_HORSE_ACCESS_SUCCESS';
export const DELETE_HORSE_ACCESS_ERROR = 'DELETE_HORSE_ACCESS_ERROR';
export const DELETE_HORSE_ACCESS_FOR_HORSE_REQUESTED = 'DELETE_HORSE_ACCESS_FOR_HORSE_REQUESTED';
export const DELETE_HORSE_ACCESS_FOR_HORSE_SUCCESS = 'DELETE_HORSE_ACCESS_FOR_HORSE_SUCCESS';
export const DELETE_HORSE_ACCESS_FOR_HORSE_ERROR = 'DELETE_HORSE_ACCESS_FOR_HORSE_ERROR';
