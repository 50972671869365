// import Joi from 'joi-browser';
import HttpService from '@pingum/api-services/HttpService';

// const numberOfDaysBetweenDates = (d1, d2) => {
//   const diff = Math.abs(d1.getTime() - d2.getTime());
//   return diff / (1000 * 60 * 60 * 24);
// };

export default class EventTemplateService {
  static addSchedule(id, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Event Template ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.post(`/${id}/schedules`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static addEventTemplateReminder(id, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Event Template ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.post(`/${id}/eventTemplateReminders`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static getSchedulesForEventTemplate(id, startTime, endTime, tags, includeWhereEventTemplateIsChild, page, size, sort) {
    return this.httpService.get(`/${id}/schedules?page=${page}&size=${size}${sort}&startTime=${startTime || new Date(10000)}&endTime=${endTime || new Date()}&tags=${tags || []}&includeWhereEventTemplateIsChild=${includeWhereEventTemplateIsChild}`);
  }
}

EventTemplateService.httpService = new HttpService({
  baseURL: `${typeof window !== 'undefined'
    ? env.REACT_APP_EVENT_API
    : ''}/eventTemplates`,
});
