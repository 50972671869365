// Horse Public Share
export const GET_HORSE_PUBLIC_SHARES_REQUESTED = 'GET_HORSE_PUBLIC_SHARES_REQUESTED';
export const GET_HORSE_PUBLIC_SHARES_SUCCESS = 'GET_HORSE_PUBLIC_SHARES_SUCCESS';
export const GET_HORSE_PUBLIC_SHARES_ERROR = 'GET_HORSE_PUBLIC_SHARES_ERROR';
export const GET_HORSE_PUBLIC_SHARE_REQUESTED = 'GET_HORSE_PUBLIC_SHARE_REQUESTED';
export const GET_HORSE_PUBLIC_SHARE_SUCCESS = 'GET_HORSE_PUBLIC_SHARE_SUCCESS';
export const GET_HORSE_PUBLIC_SHARE_ERROR = 'GET_HORSE_PUBLIC_SHARE_ERROR';
export const ADD_HORSE_PUBLIC_SHARE_REQUESTED = 'ADD_HORSE_PUBLIC_SHARE_REQUESTED';
export const ADD_HORSE_PUBLIC_SHARE_SUCCESS = 'ADD_HORSE_PUBLIC_SHARE_SUCCESS';
export const ADD_HORSE_PUBLIC_SHARE_ERROR = 'ADD_HORSE_PUBLIC_SHARE_ERROR';
export const UPDATE_HORSE_PUBLIC_SHARE_REQUESTED = 'UPDATE_HORSE_PUBLIC_SHARE_REQUESTED';
export const UPDATE_HORSE_PUBLIC_SHARE_SUCCESS = 'UPDATE_HORSE_PUBLIC_SHARE_SUCCESS';
export const UPDATE_HORSE_PUBLIC_SHARE_ERROR = 'UPDATE_HORSE_PUBLIC_SHARE_ERROR';
export const DELETE_HORSE_PUBLIC_SHARE_REQUESTED = 'DELETE_HORSE_PUBLIC_SHARE_REQUESTED';
export const DELETE_HORSE_PUBLIC_SHARE_SUCCESS = 'DELETE_HORSE_PUBLIC_SHARE_SUCCESS';
export const DELETE_HORSE_PUBLIC_SHARE_ERROR = 'DELETE_HORSE_PUBLIC_SHARE_ERROR';
// Horse Shares
export const GET_HORSE_PUBLIC_SHARE_FOR_HORSE_REQUESTED = 'GET_HORSE_PUBLIC_SHARE_FOR_HORSE_REQUESTED';
export const GET_HORSE_PUBLIC_SHARE_FOR_HORSE_SUCCESS = 'GET_HORSE_PUBLIC_SHARE_FOR_HORSE_SUCCESS';
export const GET_HORSE_PUBLIC_SHARE_FOR_HORSE_ERROR = 'GET_HORSE_PUBLIC_SHARE_FOR_HORSE_ERROR';
