import * as types from './types';
import * as userTypes from '../user/types';

const defaultState = {
  streamUser: null,
  isFetchingStreamUser: false,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case userTypes.LOGOUT_SUCCESS:
      return defaultState;
    case types.GET_ME_STREAM_REQUESTED:
      return {
        ...state,
        streamUser: null,
        isFetchingStreamUser: true,
      };
    case types.GET_ME_STREAM_SUCCESS:
      return {
        ...state,
        isFetchingStreamUser: false,
        streamUser: action.streamUser,
      };
    case types.GET_ME_STREAM_ERROR:
      return {
        ...state,
        isFetchingStreamUser: false,
        streamUser: null,
      };
    default:
      return state;
  }
};
