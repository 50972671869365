// TAG_REMINDER
export const GET_TAG_REMINDERS_REQUESTED = 'GET_TAG_REMINDERS_REQUESTED';
export const GET_TAG_REMINDERS_SUCCESS = 'GET_TAG_REMINDERS_SUCCESS';
export const GET_TAG_REMINDERS_ERROR = 'GET_TAG_REMINDERS_ERROR';

export const GET_TAG_REMINDER_REQUESTED = 'GET_TAG_REMINDER_REQUESTED';
export const GET_TAG_REMINDER_SUCCESS = 'GET_TAG_REMINDER_SUCCESS';
export const GET_TAG_REMINDER_ERROR = 'GET_TAG_REMINDER_ERROR';

export const GET_TAG_TAG_REMINDERS_REQUESTED = 'GET_TAG_TAG_REMINDERS_REQUESTED';
export const GET_TAG_TAG_REMINDERS_SUCCESS = 'GET_TAG_TAG_REMINDERS_SUCCESS';
export const GET_TAG_TAG_REMINDERS_ERROR = 'GET_TAG_TAG_REMINDERS_ERROR';

export const GET_TAG_REMINDERS_MAX_DUE_DATE_REQUESTED = 'GET_TAG_REMINDERS_MAX_DUE_DATE_REQUESTED';
export const GET_TAG_REMINDERS_MAX_DUE_DATE_SUCCESS = 'GET_TAG_REMINDERS_MAX_DUE_DATE_SUCCESS';
export const GET_TAG_REMINDERS_MAX_DUE_DATE_ERROR = 'GET_TAG_REMINDERS_MAX_DUE_DATE_ERROR';
