import React from 'react';
import PropTypes from 'prop-types';
import iosStoreButton from '../../images/ios-store-button.png';
import playStoreButton from '../../images/play-store-button.png';

export const AppStoreButtonComponent = ({
  store,
  width,
  height,
}) => {
  const url = store === 'ios'
    ? 'https://apps.apple.com/us/app/questri/id1634821513'
    : 'https://play.google.com/store/apps/details?id=app.questri&hl=en_US';
  const background = store === 'ios'
    ? iosStoreButton
    : playStoreButton;

  return (
    <div
      style={{
        height: (`${height}px`),
        width: (`${width}px`),
        display: 'inline-block',
      }}
    >
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          background: `url(${background}) 0% 0% / contain no-repeat`,
          display: 'inline-block',
          overflow: 'hidden',
          textDecoration: 'none',
          height: '100%',
          width: '100%',
        }}
      >&nbsp;
      </a>
    </div>
  );
};

AppStoreButtonComponent.propTypes = {
  store: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

AppStoreButtonComponent.defaultProps = {
  store: 'ios',
  width: 100,
  height: 35,
};

export default (AppStoreButtonComponent);
