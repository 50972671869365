// User
export const GET_LOCATION_REQUESTED = 'GET_LOCATION_REQUESTED';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_ERROR = 'GET_LOCATION_ERROR';
export const GET_LOCATION_HORSES_REQUESTED = 'GET_LOCATION_HORSES_REQUESTED';
export const GET_LOCATION_HORSES_SUCCESS = 'GET_LOCATION_HORSES_SUCCESS';
export const GET_LOCATION_HORSES_ERROR = 'GET_LOCATION_HORSES_ERROR';
export const GET_ALL_LOCATIONS_REQUESTED = 'GET_ALL_LOCATIONS_REQUESTED';
export const GET_ALL_LOCATIONS_SUCCESS = 'GET_ALL_LOCATIONS_SUCCESS';
export const UPDATE_LOCATION_REQUESTED = 'UPDATE_LOCATION_REQUESTED';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_ERROR = 'UPDATE_LOCATION_ERROR';
export const ADD_LOCATION_REQUESTED = 'ADD_LOCATION_REQUESTED';
export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS';
export const ADD_LOCATION_ERROR = 'ADD_LOCATION_ERROR';
export const DELETE_LOCATION_REQUESTED = 'DELETE_LOCATION_REQUESTED';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_ERROR = 'DELETE_LOCATION_ERROR';
export const SELECTED_LOCATION_CHANGED = 'SELECTED_LOCATION_CHANGED';
export const GET_LOCATION_HORSE_NOTES_REQUESTED = 'GET_LOCATION_HORSE_NOTES_REQUESTED';
export const GET_LOCATION_HORSE_NOTES_SUCCESS = 'GET_LOCATION_HORSE_NOTES_SUCCESS';
export const GET_LOCATION_HORSE_NOTES_ERROR = 'GET_LOCATION_HORSE_NOTES_ERROR';
export const GET_LOCATION_HORSE_FEEDS_REQUESTED = 'GET_LOCATION_HORSE_FEEDS_REQUESTED';
export const GET_LOCATION_HORSE_FEEDS_SUCCESS = 'GET_LOCATION_HORSE_FEEDS_SUCCESS';
export const GET_LOCATION_HORSE_FEEDS_ERROR = 'GET_LOCATION_HORSE_FEEDS_ERROR';
