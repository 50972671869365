import React from 'react';
import PropTypes from 'prop-types';
import {
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Typography,
  Icon,
} from '@mui/material';

const BillingAndPricingFaq = ({ includeOrgFaq }) => (
  <div>
    {
      includeOrgFaq
      && (
        <Accordion>
          <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
            <Typography variant="subtitle1" component="div">
              Do I need to pay or provide a credit card to create an organization?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography paragraph>
              Not at all!
            </Typography>
            <Typography paragraph>
              All new organization sign ups are NOT required to provide a
              credit card or any billing information. Instead, you will be given
              a 14 day FREE TRIAL of questri.
            </Typography>
          </AccordionDetails>
        </Accordion>
      )
    }

    <Accordion>
      <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
        <Typography variant="subtitle1" component="div">
          How do I keep access after my free trial ends?
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography paragraph>
          Simply provide a payment method on our secure portal. You will
          automatically be charged at the start of the billing period and
          keep access to Questri and all the features.
        </Typography>
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
        <Typography variant="subtitle1" component="div">
          How does Questri&#39;s pricing work?
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography paragraph>
          Questri offers monthly or annual pricing plans to best meet
          your organization&#39;s needs. Paying annually will
          give you a 10% overall discount.
          You can view the different plans on our <a href="https://getquestri.com/pricing" target="_blank" rel="noopener noreferrer">pricing</a> page
          .  If you have additional questions or requests,
          please <a href="https://getquestri.com/contact-us" target="_blank ">contact us</a>.
        </Typography>
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
        <Typography variant="subtitle1" component="div">
          What forms of payment do you accept?
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography paragraph>
          You can purchase Questri with any major Credit Card or with a Bank ACH Direct Debit.
        </Typography>
        <Typography paragraph>
          Invoices payable check can be made available
          if your invoice amount exceeds $2500.
          Please <a href="https://getquestri.com/contact-us" target="_blank ">contact us</a> to learn more and request this option.
        </Typography>
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
        <Typography variant="subtitle1" component="div">
          We need to change the billing period and pay frequency, what do we do?
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'block' }}>
        <Typography paragraph>
          You can change the billing frequency for your plan at any time. Simply
          select the &#34;Modify&#34; button next to the subscription you wish to
          update and you will be sent to our secure portal where you can change your
          selections.
        </Typography>
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
        <Typography variant="subtitle1" component="div">
          How does billing work for paid plans?
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'block' }}>
        <Typography paragraph>
          Before an organization can be on a paid plan, you first must provide
          a valid payment method. Once provided and after subscribing to
          the Barn Management plan, you will be invoiced and charged for the amount
          corresponding with your selected plan. If your organization is still on the
          14 day free trial, you will not be charged until after the
          trial completes.
        </Typography>
        <Typography paragraph>
          For organizations with paid plans, you can expect an invoice and charge
          at the start of each billing period, based on the payment amount corresponding
          with your selected plan. If choosing to pay annually, the combined amount of 12 months
          of usage will be charged at the start of the billing period (with a 10% discount).
        </Typography>
        <Typography paragraph>
          You can access all your past invoices and your incoming invoice
          by selecting the &#34;Manage Billing Information & Invoices&#34; button.
        </Typography>
      </AccordionDetails>
    </Accordion>

    {
      includeOrgFaq
      && (
        <Accordion>
          <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
            <Typography variant="subtitle1" component="div">
              How do I cancel or deactivate my organization?
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'block' }}>
            <Typography paragraph>
              To deactivate your organization, click on the &#34;Cancel&#34;
              button next to the Barn Management subscription. This will take
              you to our secure billing portal. From this portal you will
              be able to cancel both the Barn Management and Client Experience
              subscriptions (if applicable). Once you have confirmed and
              canceled the Barn Management subscription, you will stop being
              billed by Questri and access will be restricted to the application
              for you, your staff, and your clients.
            </Typography>
            <Typography paragraph>
              If you also have a Client Experience subscription, you should first unsubscribe from that plan.
            </Typography>
            <Typography paragraph>
              Any remaining credits for your current billing period will
              be prorated into your account. If and when you chose to
              subscribe again to Questri, those credits will be applied.
            </Typography>
            <Typography paragraph>
              You will be able to resubscribe at any time and get access again.
            </Typography>
            <Typography paragraph>
              If you have any questions you can always contact&nbsp;
              <a
                href="mailto:support@questri.app?Subject=Questri%20Subscription%20Cancelation%20Help"
                target="_top"
              >
                support@questri.app
              </a>.
            </Typography>
          </AccordionDetails>
        </Accordion>
      )
    }
  </div>
);

BillingAndPricingFaq.propTypes = { includeOrgFaq: PropTypes.bool };

BillingAndPricingFaq.defaultProps = { includeOrgFaq: false };

export default (BillingAndPricingFaq);
