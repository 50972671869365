import { filter } from 'lodash';

export const getTenantFromUrl = () => {
  const stripped = window.location.hostname.replace(env.REACT_APP_DOMAIN, '');
  if (!stripped) {
    return null;
  }
  return stripped.substr(0, stripped.indexOf('.'));
};

export const getHostWithoutTenant = () => {
  // since we can't callback to a subdomain, we need to make sure we do it on root domain
  const tenant = getTenantFromUrl();
  if (tenant === null) {
    return window.location.origin;
  }
  return window.location.origin.replace(`${tenant}.`, '');
};

export const getQueryParam = (param) => {
  const url = window.location.href;
  const name = param.replace(/[\\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getAllQueryParams = () => {
  const queryString = window.location.href.split('?')[1];
  if (!queryString) {
    return '';
  }
  return `?${queryString}`;
};

export const removeURLParameter = (url, parameter) => {
  const queryString = url.split('?');
  if (queryString.length <= 1) {
    return url;
  }

  const prefix = `${encodeURIComponent(parameter)}=`;
  const params = filter(queryString[1].split(/[&;]/g), (param) => param.indexOf(prefix) === -1);

  return queryString[0] + (params.length > 0
    ? `?${params.join('&')}`
    : '');
};

export const removeQueryParam = (parameter) => {
  const queryString = window.location.href.split('?');
  if (queryString.length <= 1) {
    return window.location.href;
  }

  const prefix = `${encodeURIComponent(parameter)}=`;
  const params = filter(queryString[1].split(/[&;]/g), (param) => param.indexOf(prefix) === -1);

  const newurl = queryString[0] + (params.length > 0
    ? `?${params.join('&')}`
    : '');
  return window.history.pushState({ path: newurl }, '', newurl);
};

export const setQueryParam = (key, value) => {
  if (window.history.pushState) {
    const currentUrl = removeURLParameter(
      window.location.href,
      key,
    );

    const queryDelimitter = currentUrl.indexOf('?') !== -1
      ? '&'
      : '?';
    const newurl = `${currentUrl}${queryDelimitter}${key}=${value}`;
    window.history.pushState({ path: newurl }, '', newurl);
  }
};
