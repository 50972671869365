// import Joi from 'joi-browser';
import HttpService from '@pingum/api-services/HttpService';

export default class BarnService {
  static getBarns() {
    return this.httpService.get('');
  }

  static getBarnNotices(id) {
    if (!id) {
      return Promise.reject(new Error('Missing Barn ID'));
    }

    return this.httpService.get(`/${id}/notices`);
  }

  static getBarnHorses(id, page, size, sort, search, lodgingDate, lodgingDateEnd, location, includeInactive) {
    if (!id) {
      return Promise.reject(new Error('Missing Barn ID'));
    }

    return this.httpService.get(`/${id}/horses?page=${page}&size=${size}${sort}&search=${search}&lodgingDate=${lodgingDate || new Date()}&lodgingDateEnd=${lodgingDateEnd || lodgingDate || new Date()}&locationId=${location === null
      ? ''
      : location}&inactive=${includeInactive
      ? 'true'
      : 'false'}`);
  }

  static updateBarnHorse(id, horseId, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Barn ID'));
    }
    if (!horseId) {
      return Promise.reject(new Error('Missing Horse ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.patch(`/${id}/horses/${horseId}`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static updateBarn(id, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Barn ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.patch(`/${id}`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  /* ********
   * Users
   ********* */

  static getBarnHorseAccessUsersByType(id, type) {
    if (!id) {
      return Promise.reject(new Error('Missing Barn ID'));
    }

    return this.httpService.get(`/${id}/users?type=${type}`);
  }
  /* ********
   * Lodging Request
   ********* */

  static getMyBarnsHorseAccessRequests(id, page, size, sort) {
    if (!id) {
      return Promise.reject(new Error('Missing Barn ID'));
    }

    return this.httpService.get(`/${id}/horses/requests?page=${page}&size=${size}${sort}`);
  }

  static requestAccessForHorse(barnId, horseId, request) {
    if (!barnId) {
      return Promise.reject(new Error('Missing Barn ID'));
    }
    if (!horseId) {
      return Promise.reject(new Error('Missing Horse ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.post(`/${barnId}/horses/${horseId}/requests`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static updateRequestAccessForHorse(barnId, horseId, requestId, request) {
    if (!barnId) {
      return Promise.reject(new Error('Missing Barn ID'));
    }
    if (!horseId) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    if (!requestId) {
      return Promise.reject(new Error('Missing Request ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.patch(`/${barnId}/horses/${horseId}/requests/${requestId}`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  /*
    HORSE BILLABLES
  */
  static getHorseBillablesRollup(id) {
    if (!id) {
      return Promise.reject(new Error('Missing Barn ID'));
    }

    return this.httpService.get(`/${id}/billables`);
  }

  /*
  HORSE SUGGESTIONs
  */
  static getHorseSuggestions(id, location) {
    if (!id) {
      return Promise.reject(new Error('Missing Barn ID'));
    }

    return this.httpService.get(`/${id}/horseSuggestions?locationId=${location === null
      ? ''
      : location}`);
  }
}

BarnService.httpService = new HttpService({
  baseURL: `${typeof window !== 'undefined'
    ? env.REACT_APP_HORSE_API
    : ''}/barns`,
});
