// Barn
export const GET_BOARD_REQUESTED = 'GET_BOARD_REQUESTED';
export const GET_BOARD_SUCCESS = 'GET_BOARD_SUCCESS';
export const GET_BOARD_ERROR = 'GET_BOARD_ERROR';
export const BOARD_TASK_UPDATE = 'BOARD_TASK_UPDATE';
export const BOARD_TASK_UPDATE_ERROR = 'BOARD_TASK_UPDATE_ERROR';
export const BOARD_SCHEDULE_ADDED_UPDATE = 'BOARD_created-schedule_UPDATE';
export const BOARD_SCHEDULE_CHANGED_UPDATE = 'BOARD_updated-schedule_UPDATE';
export const BOARD_SCHEDULE_CANCELED_UPDATE = 'BOARD_deleted-schedule_UPDATE';
export const BOARD_SCHEDULE_EXCEPTION_ADDED_UPDATE = 'BOARD_created-schedule-exception_UPDATE';
export const BOARD_SCHEDULE_EXCEPTION_CHANGED_UPDATE = 'BOARD_updated-schedule-exception_UPDATE';
export const BOARD_SCHEDULE_EXCEPTION_CANCELED_UPDATE = 'BOARD_deleted-schedule-exception_UPDATE';

export const GET_BOARD_SUGGESTION_REQUESTED = 'GET_BOARD_SUGGESTIONS_REQUESTED';
export const GET_BOARD_SUGGESTION_SUCCESS = 'GET_BOARD_SUGGESTIONS_SUCCESS';
export const GET_BOARD_SUGGESTION_ERROR = 'GET_BOARD_SUGGESTIONS_ERROR';
