export default () => ({
  // primary: { lineHeight: 1.3 },
  // secondary: {
  //   lineHeight: 1.3,
  //   fontSize: '12px',
  //   whiteSpace: 'normal',
  //   wordWap: 'break-word',
  // },
  root: { padding: '0 8px' },
  miniAvatar: {
    display: 'inline-block',
    height: 32,
    width: 32,
    '& svg': {
      height: '100% !important',
      width: '100% !important',
      padding: '2px',
    },
  },
  miniImg: { verticalAlign: 'top' },
  bordered: { border: '2px solid white' },
  listItem: {
    padding: 0,
    alignItems: 'center',
    width: '100%',
  },
  avatar: {
    width: 33,
    height: 33,
  },
});
