import * as types from './types';
import * as tagReminderTypes from '../tagReminder/types';

export default (state = {
  eventTemplateReminders: [],
  isFetchingEventTemplateReminders: false,
  initialLoadComplete: false,
  // Tag Reminders
  tagReminders: [],
  isFetchingEventTemplateReminderTagReminders: false,
}, action = {}) => {
  switch (action.type) {
    case types.GET_EVENT_TEMPLATE_REMINDER_REQUESTED:
      return {
        ...state,
        eventTemplateReminders: [],
        isFetchingEventTemplateReminders: true,
      };
    case types.GET_EVENT_TEMPLATE_REMINDER_SUCCESS:
      return {
        ...state,
        isFetchingEventTemplateReminders: false,
        eventTemplateReminders: action.eventTemplateReminders,
        initialLoadComplete: true,
      };
    case types.GET_EVENT_TEMPLATE_REMINDER_ERROR:
      return {
        ...state,
        isFetchingEventTemplateReminders: false,
      };
    case types.ADD_EVENT_TEMPLATE_REMINDER_REQUESTED:
      return {
        ...state,
        isFetchingEventTemplateReminders: true,
      };
    case types.ADD_EVENT_TEMPLATE_REMINDER_SUCCESS:
      return {
        ...state,
        isFetchingEventTemplateReminders: false,
        eventTemplateReminders: [action.newEventTemplateReminder].concat(state.eventTemplateReminders),
      };
    case types.ADD_EVENT_TEMPLATE_REMINDER_ERROR:
      return {
        ...state,
        isFetchingEventTemplateReminders: false,
      };
    case types.UPDATE_EVENT_TEMPLATE_REMINDER_REQUESTED:
      return {
        ...state,
        isFetchingEventTemplateReminders: true,
      };
    case types.UPDATE_EVENT_TEMPLATE_REMINDER_SUCCESS:
      return {
        ...state,
        isFetchingEventTemplateReminders: false,
        eventTemplateReminders: state.eventTemplateReminders.map((f) => (f.id === action.updatedEventTemplateReminder.id
          ? action.updatedEventTemplateReminder
          : f)),
      };
    case types.UPDATE_EVENT_TEMPLATE_REMINDER_ERROR:
      return {
        ...state,
        isFetchingEventTemplateReminders: false,
      };
    case types.DELETE_EVENT_TEMPLATE_REMINDER_REQUESTED:
      return {
        ...state,
        isFetchingEventTemplateReminders: true,
      };
    case types.DELETE_EVENT_TEMPLATE_REMINDER_SUCCESS:
      return {
        ...state,
        isFetchingEventTemplateReminders: false,
        eventTemplateReminders: state.eventTemplateReminders.filter((f) => f.id !== action.deletedEventTemplateReminderId),
      };
    case types.DELETE_EVENT_TEMPLATE_REMINDER_ERROR:
      return {
        ...state,
        isFetchingEventTemplateReminders: false,
      };

      // TAG REMINDERS
    case types.GET_EVENT_TEMPLATE_REMINDER_TAG_REMINDERS_REQUESTED:
      return {
        ...state,
        tagReminders: [],
        isFetchingEventTemplateReminderTagReminders: true,
      };
    case types.GET_EVENT_TEMPLATE_REMINDER_TAG_REMINDERS_SUCCESS:
      return {
        ...state,
        isFetchingEventTemplateReminderTagReminders: false,
        tagReminders: action.tagReminders,
      };
    case tagReminderTypes.GET_TAG_REMINDER_SUCCESS:
      return {
        ...state,
        isFetchingEventTemplateReminderTagReminders: false,
        tagReminders: state.tagReminders.map((tr) => ((action.tagReminder && tr.id === action.tagReminder.id)
          ? action.tagReminder
          : tr)),
      };
    case types.GET_EVENT_TEMPLATE_REMINDER_TAG_REMINDERS_ERROR:
      return {
        ...state,
        isFetchingEventTemplateReminderTagReminders: false,
      };
    default:
      return state;
  }
};

