import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import logo from '../../images/icon.png';
import styles from './styles';

const LoadingPlaceholder = ({ classes }) => (
  <div className={classes.loadingContainer}>
    <div className={classes.imageContainer}>
      <img src={logo} alt="Questri" className={classes.logo} />
      <LinearProgress />
    </div>
  </div>
);

LoadingPlaceholder.propTypes = { classes: PropTypes.object };

LoadingPlaceholder.defaultProps = { classes: {} };

export default withStyles(styles)(LoadingPlaceholder);
