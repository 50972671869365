import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  ListItem,
  ListItemText,
  Typography,
  Avatar,
  Tooltip,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { hideNotifications as hideNotificationsAction } from '@pingum/app-state-management/app/actions';
import { markNotificationsAsRead } from '@pingum/app-state-management/userNotification/actions';
import { formatDateString } from '../../utils/dateHelper';
import styles from './styles';

const Notification = ({
  notification,
  classes,
  hideNotifications,
  barnUsersMap,
  profile,
  markNotificationAsRead,
}) => {
  const users = barnUsersMap[notification.actionByAuth0UserId || notification.auth0UserId];
  const user = (users && users[0]) || {};
  const isUnread = notification.readTimestamp === 0;

  return (
    <Link
      to={notification.webRoute || ''}
      href={notification.webRoute || ''}
      onClick={() => {
        hideNotifications();
        markNotificationAsRead(profile, notification);
      }}
    >
      <Tooltip title={notification.description.substr(0, 250)}>
        <ListItem
          button
          className={
            isUnread
              ? classes.listItemUnread
              : classes.listItemRead
          }
        >
          <Avatar src={user.avatar} />
          <ListItemText
            classes={{
              primary: classes.listItemPrimary,
              root: classes.listItemRoot,
            }}
            primary={(
              <div className={classes.notificationTitleContainer}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  className={
                    isUnread
                      ? classes.notificationTitleUnread
                      : classes.notificationTitleRead
                  }
                >
                  {notification.title}
                </Typography>
                <Typography
                  component="span"
                  variant="caption"
                  className={classes.notificationDate}
                >
                  {formatDateString(notification.notificationTimestamp)}
                </Typography>
              </div>
            )}
            secondary={(
              <span
                className={
                  isUnread
                    ? classes.notificationDescriptionUnread
                    : classes.notificationDescriptionRead
                }
              >
                {notification.description}
              </span>
            )}
          />
        </ListItem>
      </Tooltip>
    </Link>
  );
};

Notification.propTypes = {
  notification: PropTypes.object,
  classes: PropTypes.object,
  hideNotifications: PropTypes.func,
  barnUsersMap: PropTypes.array,
  profile: PropTypes.object,
  markNotificationAsRead: PropTypes.func,
};

Notification.defaultProps = {
  notification: {},
  classes: {},
  hideNotifications: () => {},
  barnUsersMap: {},
  profile: {},
  markNotificationAsRead: () => {},
};

export const mapStateToProps = (state) => ({
  barnUsersMap: state.barn.barnUsersMap,
  profile: state.user.user,
});

export const mapDispatchToProps = (dispatch) => ({
  hideNotifications: () => dispatch(hideNotificationsAction()),
  markNotificationAsRead:
    (profile, notification) => dispatch(markNotificationsAsRead(profile.user_id, [notification])),
});

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notification));
