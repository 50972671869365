export default () => ({
  list: {
    maxWidth: '100%',
    flex: 1,
    overflowY: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
  },
  notificationListHeader: {
    padding: '.5em .5em .5em 1em',
    display: 'flex',
  },
  notificationListHeaderTitle: {
    flex: 1,
    lineHeight: 1,
    marginTop: 5,
    marginBottom: 0,
    fontSize: '28px',
  },
  close: { fontSize: '24px' },
  notificationContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '90vh',
    width: 500,
    maxWidth: '90vw',
  },
  notificationContainerFullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    maxWidth: '100vw',
  },
  popoverPaperFullScreen: {
    width: '100vw',
    height: '100vh',
    maxWidth: 'unset',
    maxHeight: 'unset',
    borderRadius: 'unset',
    top: '0 !important',
    left: '0 !important',
  },
  center: {
    textAlign: 'center',
    margin: '1em',
    width: '100%',
  },
});
