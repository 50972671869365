import Joi from 'joi-browser';
import HttpService from '@pingum/api-services/HttpService';
import PingumHttpService from '@pingum/api-services/UserService';

export const firstNameKey = 'https://pingum.io/firstName';
export const lastNameKey = 'https://pingum.io/lastName';
export const emailKey = 'https://pingum.io/email';
export const phoneKey = 'https://pingum.io/phone';
export const tenantsKey = 'https://pingum.io/tenants';
export const avatarKey = 'https://pingum.io/avatar';

let getMePromise;

const minNameLength = 2;

export const resetGetMePromise = () => {
  getMePromise = undefined;
};

export default class UserService {
  static normalizeName(displayName) {
    return displayName.toLowerCase().trim().replace(/[^\w\s-]|_/gi, '').replace(/\s+/g, '-');
  }

  static getTenantUrl(name) {
    return `https://${UserService.normalizeName(name)}.${typeof window !== 'undefined'
      ? env.REACT_APP_DOMAIN
      : ''}`;
  }

  static isAuth0UserIdValid(auth0UserId) {
    return auth0UserId !== null && auth0UserId !== undefined;
  }

  static isNameValid(name) {
    return name !== undefined && name !== null && name.length >= minNameLength && !name.match(/^-|-$/);
  }

  static isUpdateUserRequestValid(updateUserRequest) {
    if (updateUserRequest === null || updateUserRequest === undefined) {
      return false;
    }
    const schema = Joi.object().keys({
      phone: Joi.string().allow('').allow(null).optional(),
      email: Joi.string().allow('').allow(null).email()
        .optional(),
      firstName: Joi.string().allow('').optional(),
      lastName: Joi.string().allow('').optional(),
      avatar: Joi.string().allow(null).min(2).optional(),
      addressLine1: Joi.string().allow('').allow(null).optional(),
      addressLine2: Joi.string().allow('').allow(null).optional(),
      addressLine3: Joi.string().allow('').allow(null).optional(),
      city: Joi.string().allow('').allow(null).optional(),
      stateProvinceCounty: Joi.string().allow('').allow(null).optional(),
      zipOrPostalcode: Joi.string().allow('').allow(null).optional(),
      auth0UserId: Joi.string().allow('').allow(null).optional(),
      passive: Joi.allow(true).allow(false).allow(null).optional(),
      roles: Joi.string().allow('').allow(null).optional(),
      status: Joi.string().allow('').allow(null).optional(),
      statusIcon: Joi.any().allow('').allow(null).optional(),
      emergencyContact: Joi.any().allow({}).allow(null).optional(),
      referenceNumbers: Joi.any().allow({}).allow(null).optional(),
      removeAuth0Account: Joi.boolean().optional(),
    });

    return (Joi.validate(updateUserRequest, schema).error === null);
  }

  static isLocalStorageUserProfileValid() {
    const profileExpiration = global.window.localStorage.getItem('profile_expiration');
    if (profileExpiration === null) {
      return false;
    }
    const expirationTime = new Date(profileExpiration);
    const now = new Date();
    if (now > expirationTime) {
      return false;
    }
    return true;
  }

  static setLocalProfileFromTenantApiResponse(response) {
    const profile = UserService.getProfile();
    const userMetadata = response.user_metadata || {};
    profile[firstNameKey] = response.firstName || userMetadata.first_name || '';
    profile[lastNameKey] = response.lastName || userMetadata.last_name || '';
    profile[emailKey] = userMetadata.email || '';
    profile[phoneKey] = userMetadata.phone || '';
    profile[avatarKey] = response.avatar || userMetadata.avatar || '';
    profile[tenantsKey] = response.app_metadata
      ? response.app_metadata.tenants
      : {};
    // set for browser cache change of profile avatar, see UserMenu.js
    profile.updated_at = new Date().toDateString();
    global.window.localStorage.setItem('profile_expiration', new Date(new Date().getTime() + (30 * 60 * 1000)));
    UserService.setProfile(profile);
    return profile;
  }

  static getMe() {
    if (!getMePromise) {
      getMePromise = new Promise((resolve, reject) => {
        const localProfile = UserService.getProfile();
        if (!!localProfile.user_id && !!localProfile.roles && UserService.isLocalStorageUserProfileValid()) {
          resolve(localProfile);
        } else {
          // We want to grab full profile from Pingum tenant API because it has the AUTH0 metadata included
          UserService.getMeBarnUser().then((response) => {
            // now get the pingum user and combine fields (pingum user brings back auth0 data)
            PingumHttpService.getMe().then((pingummResponse) => {
              const pingumAndQuestriUserCombined = {
                ...pingummResponse,
                ...response,
              };
              UserService.setProfile(pingumAndQuestriUserCombined);
              resolve(this.setLocalProfileFromTenantApiResponse(pingumAndQuestriUserCombined));
            });
          }, () => {
            reject();
          });
        }
      });
    }
    return getMePromise;
  }

  static checkEmail(email) {
    return this.httpService.get(`/checkEmail?email=${email}`);
  }

  static getMeBarnUser() {
    return this.httpService.get('/me');
  }

  static linkMeBarnUser() {
    return this.httpService.post('/me/link');
  }

  static getMeHorseAccess() {
    return this.httpService.get('/me/horses');
  }

  static getUserHorseAccess(userId) {
    return this.httpService.get(`/${userId}/horses`);
  }

  static addUser(addUserRequest) {
    if (!UserService.isUpdateUserRequestValid(addUserRequest)) {
      return Promise.reject(new Error('Invalid add user request'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.post('', addUserRequest).then((response) => {
        resolve(response);
      }).catch((error) => { reject(error); });
    });
  }

  static updateUser(id, updateUserRequest, isSelf) {
    if (!UserService.isUpdateUserRequestValid(updateUserRequest)) {
      return Promise.reject(new Error('Invalid update user request'));
    }

    if (isSelf) {
      resetGetMePromise();
      global.window.localStorage.removeItem('profile_expiration');
    }

    return new Promise((resolve) => {
      this.httpService.patch(`/${isSelf
        ? 'me'
        : id}`, updateUserRequest).then((response) => {
        if (isSelf) {
          this.getMe();
          this.setLocalProfileFromTenantApiResponse(response);
          resolve(response);
        } else {
          resolve(response);
        }
      });
    });
  }

  static getUsers(page, size, sort) {
    return this.httpService.get(`?page=${page}&size=${size}${sort}`);
  }

  static deleteUser(id) {
    if (!id) {
      return Promise.reject(new Error('Missing user ID'));
    }

    return new Promise((resolve) => {
      this.httpService.delete(`/${id}`).then((response) => {
        resolve(response);
      });
    });
  }

  static userHasTenants(profile = UserService.getProfile()) {
    return profile
      && profile.app_metadata
      && profile.app_metadata.tenants
      && Object.keys(profile.app_metadata.tenants).length > 0;
  }

  static getProfile() {
    return global.window.localStorage.getItem('profile')
      ? JSON.parse(global.window.localStorage.getItem('profile'))
      : {};
  }

  static getSubscribedTenants(profile) {
    if (!UserService.userHasTenants(profile)) {
      return [];
    }
    return Object.keys(profile.app_metadata.tenants);
  }

  static setProfile(userProfile) {
    const profile = userProfile;
    profile.subscribedTenants = UserService.getSubscribedTenants(profile);
    global.window.localStorage.setItem('profile', JSON.stringify(profile));
  }

  static clearLocalStorage() {
    global.window.localStorage.removeItem('profile');
    global.window.localStorage.removeItem('profile_expiration');
    global.window.localStorage.removeItem('user_tutorials');
  }

  static userHasRoleForTenant(roleNeeded, tenant, user) {
    if (user === undefined || user === null) {
      return false;
    }
    const userTenants = user[tenantsKey];
    if (userTenants === undefined || userTenants === null) {
      return false;
    }
    const userTenant = userTenants[tenant];
    if (userTenant === undefined || userTenant === null) {
      return false;
    }
    const tenantRoles = userTenant.roles;
    if (tenantRoles === undefined || tenantRoles === null) {
      return false;
    }
    return tenantRoles.indexOf(roleNeeded) > -1;
  }

  static getUserRolesForTenant(user, tenant) {
    if (user === undefined || user === null) {
      return [];
    }
    const userTenants = user[tenantsKey];
    if (userTenants === undefined || userTenants === null) {
      return [];
    }
    const userTenant = userTenants[tenant];
    if (userTenant === undefined || userTenant === null) {
      return [];
    }
    const tenantRoles = userTenant.roles;
    if (tenantRoles === undefined || tenantRoles === null) {
      return [];
    }
    return userTenant.roles;
  }
}

UserService.httpService = new HttpService({
  baseURL: `${typeof window !== 'undefined'
    ? env.REACT_APP_HORSE_API
    : ''}/users`,
});
