import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Icon,
} from '@mui/material';
import TenantAvatar from './TenantAvatar';

const Tenant = (props) => (
  <ListItem
    button
    onClick={props.onClick}
  >
    <ListItemIcon><TenantAvatar tenant={props.tenant} /></ListItemIcon>
    <ListItemText
      primary={props.tenant.displayName}
      secondary={props.tenant.tenantUrl}
    />
    {props.secondaryActionOnClick
      && (
        <ListItemSecondaryAction>
          <IconButton onClick={props.secondaryActionOnClick}>
            <Icon>
              {props.secondaryActionIcon}
            </Icon>
          </IconButton>
        </ListItemSecondaryAction>
      )}
  </ListItem>
);

Tenant.propTypes = {
  onClick: PropTypes.func,
  secondaryActionOnClick: PropTypes.func,
  secondaryActionIcon: PropTypes.string,
  tenant: PropTypes.object.isRequired,
};

Tenant.defaultProps = {
  onClick: undefined,
  secondaryActionOnClick: undefined,
  secondaryActionIcon: undefined,
};

export default Tenant;
