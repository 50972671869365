/* eslint-disable prefer-destructuring */
import { rrulestr } from 'rrule';
import moment from 'moment';
import { primary } from './brandColors';
import colors from './colors';

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

export const getScheduleNextOccurrenceDateString = (schedule) => {
  if (schedule.endDate && new Date(schedule.endDate).getTime() < new Date().getTime()) {
    return `ended on ${moment(schedule.endDate).format('ll')}`
  }
  if (schedule.endDate && (new Date(schedule.startDate).getTime() === new Date(schedule.endDate).getTime())) {
    // this is a one off schedule
    return moment(schedule.startDate).fromNow();
  }

  const rule = rrulestr(`RRULE:${schedule.rrule}`);
  const nextOccurrence = rule.between(new Date(), schedule.endDate
    ? new Date(schedule.endDate)
    : new Date(new Date().setFullYear(new Date().getFullYear() + 1)), true, (date, i) => i < 1);
  return `${nextOccurrence[0]
    ? moment(nextOccurrence[0]).fromNow()
    : 'No more occurrences'}, ${rule.toText()}`;
};

export const getContrast = (hexcode) => {
  // All our colors should use white text, anything custom we can check for
  if (colors.indexOf(hexcode.toUpperCase()) >= 0) {
    return '#ffffff'
  }
  let hexcolor = hexcode;
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === '#') {
    hexcolor = hexcolor.slice(1);
  }

  // If a three-character hexcode, make six-character
  if (hexcolor.length === 3) {
    hexcolor = hexcolor.split('').map((hex) => hex + hex).join('');
  }

  // Convert to RGB value
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);

  // Get YIQ ratio
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  // Check contrast
  return (yiq >= 128)
    ? primary.main
    : '#ffffff';
};

export const getScheduleSeriesText = (schedule) => {
  if (!schedule.id || (!schedule.parentSchedule && (new Date(schedule.startDate).getTime() === new Date(schedule.endDate).getTime()))) {
    // this is a one off schedule
    return '';
  }
  if (!schedule.parentSchedule) {
    // no parent and is part of series
    const text = capitalize(rrulestr(schedule.rrule).toText());
    if (schedule.endDate) {
      return `${text} ending ${moment(schedule.endDate).format('ll')}`
    }
    return text;
  }
  return 'This occurrence was modified from the original series.';
};

export const getScheduleEventTimeText = (event) => {
  if (event.start.getTime() === event.end.getTime()) {
    // this is an "all day" task
    return moment(event.start).format('ll');
  }
  // has a parent so this is a modified occurrence, return the parent series string
  return `${moment(event.start).format('ll')} ${event.start !== event.end && `${moment(event.start).format('h:mm a')} - ${moment(event.end).format('h:mm a')}`}`;
};

const validateEmail = (email) => String(email)
  .toLowerCase()
  .match(
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

export const getBarnUsersForAssignmentString = (assignments, barnUsersMap) => (assignments || '').split(/(?=@\[)/)
  .filter((user) => user && user.length > 0)
  .map((user) => ((user.match(/@\[.*\]\((.*)\)/) && barnUsersMap[user.match(/@\[.*\]\((.*)\)/)[1]])
    ? barnUsersMap[user.match(/@\[.*\]\((.*)\)/)[1]][0]
    : {
      id: null,
      userFullName: (user && user.substr(2, user.indexOf('](') - 2)) || '',
      email: (user && validateEmail(user.substring(user.indexOf('](') + 2, user.length - 1)) && user.substring(user.indexOf('](') + 2, user.length - 1)) || null,
      auth0UserId: (user && !validateEmail(user.substring(user.indexOf('](') + 2, user.length - 1)) && user.substring(user.indexOf('](') + 2, user.length - 1)) || null,
      roles: '[user]',
    }
  ));

const getCustomStatusForWorkflows = (workflows, startStatusId, statusPendingId, statusEndId, mappedStatuses) => {
  const customStatus = null;
  if (workflows && workflows.length > 0) {
    const workflowStatusIds = workflows.map((w) => w.startingStatusId)
      .filter((s) => (s && s !== startStatusId && s !== statusPendingId && s !== statusEndId))
    if (workflowStatusIds.length === workflows.length && mappedStatuses[workflowStatusIds[0]]) {
      return mappedStatuses[workflowStatusIds[0]][0]
    }
  }
  // If the event has one workflow and a custom status, or all workflows have the same custome status, we will set that
  return customStatus;
}

export const generateEventsFromSchedules = (
  schedules,
  scheduleExceptions,
  startDate,
  endDate,
  barnUsersMap,
  barnMetadata = {},
  mappedStatuses = {},
) => {
  let result = [];
  const now = moment().toDate();
  const startStatusId = barnMetadata.startStatusId;
  const statusPendingId = barnMetadata.pendingStatusId;
  const statusEndId = barnMetadata.statusEndId;

  // Build schedule exception date lookup map
  const exceptionDatesMap = {};

  scheduleExceptions.forEach((se) => {
    if (se && se.schedule && se.schedule.id) {
      if (!exceptionDatesMap[se.schedule.id]) {
        exceptionDatesMap[se.schedule.id] = {};
      }
      exceptionDatesMap[se.schedule.id][moment(se.exceptionDate).toDate().getTime()] = true;
    }
  });

  /*
    NOTE on start/end date ranges. In order for the rrulestr occurance generator to properly find all occurances,
    the start date must be BEFORE the occurance start date and end date must be AFTER, otherwise it won't generate.
    That is why we append time in either direction
  */
  for (let i = 0; i < schedules.length; i += 1) {
    let scheduleStart = moment(schedules[i].startDate);
    const scheduleStartedInDST = scheduleStart.isDST();
    scheduleStart = scheduleStart.toDate();
    const scheduleEnd = schedules[i].endDate
      ? moment(schedules[i].endDate).toDate()
      : null;

    const rruleRangeStart = scheduleStart > startDate
      ? scheduleStart
      : startDate;
    const rruleRangeEnd = (scheduleEnd && scheduleEnd < endDate)
      ? moment(scheduleEnd).add(1, 'minutes').toDate()
      : endDate;

    result = [...result, ...rrulestr(`DTSTART:${moment.utc(schedules[i].startDate)
      .format('YYYYMMDDTHHmmss')}\nRRULE:${schedules[i].rrule}`)
      .between(moment(rruleRangeStart).add(-1, 'minutes').toDate(), moment(rruleRangeEnd).toDate()).map((event) => {
        const eventMoment = moment(event);
        const dateInDST = eventMoment.isDST();
        if ((scheduleStartedInDST && dateInDST) || (!scheduleStartedInDST && !dateInDST)) {
          // Do nothing, rrule should be accurate
        } else if (scheduleStartedInDST && !dateInDST) {
          // Jump by one hour
          eventMoment.add(1, 'hours');
        } else {
          // back by onoe hour
          eventMoment.add(-1, 'hours');
        }

        const start = moment(eventMoment).toDate();
        const end = moment(start.getTime() + (schedules[i].duration || 0)).toDate();
        const color = schedules[i].metadata.calendarDisplayColor
          || (schedules[i].eventTemplate && schedules[i].eventTemplate.eventDataDefaultValues
            && schedules[i].eventTemplate.eventDataDefaultValues.calendarDisplayColor) || primary.main;
        const customStatus = getCustomStatusForWorkflows(schedules[i].workflows, startStatusId, statusPendingId, statusEndId, mappedStatuses);
        return {
          id: `${schedules[i].id}|${new Date(start)}`,
          title: schedules[i].name,
          start,
          end,
          allDay: start.getTime() === end.getTime(),
          inPast: now.getTime() > moment(start.getTime() + (schedules[i].duration || 0)).toDate().getTime(),
          activeNow: now.getTime() >= start.getTime() && now.getTime() < moment(start.getTime() + (schedules[i].duration || 0)).toDate().getTime(),
          resource: schedules[i],
          seriesText: getScheduleSeriesText(schedules[i]),
          participants: [...getBarnUsersForAssignmentString(schedules[i].assignment || '', barnUsersMap).map((u) => ({
            ...u,
            type: 'participant',
          })), ...(schedules[i].workflows.map((w) => getBarnUsersForAssignmentString(w.startingAssignment || '', barnUsersMap).map((u) => ({
            ...u,
            type: 'assigned',
          }))).flat())],
          color,
          textColor: getContrast(color),
          horseTask: !!schedules[i].tags.find((t) => t.id === barnMetadata.horseTaskTypeTagId),
          barnTask: !!schedules[i].tags.find((t) => t.id === barnMetadata.barnTaskTypeTagId),
          eventTask: !!schedules[i].tags.find((t) => t.id === barnMetadata.eventTaskTypeTagId),
          customStatus,
        };
      })];
  }

  // filter out exceptions before returning results
  return result.filter((e) => !exceptionDatesMap[e.resource.id] || !exceptionDatesMap[e.resource.id][e.start.getTime()]);
};

export const eventStyleHandler = (event, start, end) => {
  const now = moment(new Date()).toDate();
  const styles = { fontSize: 14 };
  const color = event.resource.metadata.calendarDisplayColor
  || event.resource.eventTemplate.eventDataDefaultValues.calendarDisplayColor || primary.main;
  let className = 'greyFont';
  if (end < now) {
    styles.backgroundColor = color;
    styles.color = 'fff';
    className = 'whiteFont';
    styles.opacity = 0.5;
  } else {
    styles.backgroundColor = color;
    styles.color = 'fff';
    className = 'whiteFont';
  }
  return {
    style: styles,
    className,
  };
};

export const getScheduleExceptionScheduleReplacementObject = (replacementDate, schedule) => ({
  startDate: replacementDate,
  endDate: replacementDate,
  duration: schedule.duration,
  assignment: schedule.assignment,
  name: schedule.name,
  tags: schedule.tags.map((et) => et.id),
  description: schedule.timezone,
  location: schedule.location,
  type: schedule.type,
  overrideCancellations: false, // TODO implement/change this
  metadata: schedule.metadata,
  advanceNotice: schedule.advanceNotice,
  timezone: schedule.timezone,
  workflows: schedule.workflows,
  parenteScheduleId: (schedule.parentSchedule && schedule.parentSchedule.id) || schedule.id,
  rrule: 'FREQ=DAILY;INTERVAL=1',
})
