import Joi from 'joi-browser';
import HttpService from '@pingum/api-services/HttpService';

export default class HorseService {
  static isHorseRequestValid(request) {
    if (request === null || request === undefined) {
      return false;
    }
    const schema = Joi.object().keys({
      lodgeHorseWithBarn: Joi.boolean().optional(),
      usefId: Joi.string().min(2).max(50).allow(null, ''),
      referenceNumbers: Joi.any().allow({}).allow(null).optional(),
      name: Joi.string().min(1).max(100).allow(null, '')
        .optional(),
      showName: Joi.string().min(1).max(100).allow(null, '')
        .optional(),
      sex: Joi.string().valid('Mare', 'Gelding', 'Stallion').allow(null, '')
        .optional(),
      microchipNumber: Joi.string().allow(null, '').optional(),
      breed: Joi.string().allow(null, '').optional(),
      color: Joi.string().allow(null, '').optional(),
      hands: Joi.string().allow(null, '').optional(),
      stall: Joi.string().allow(null, '').optional(),
      dateOfBirth: Joi.date().allow(null).optional(),
      lodgingStartDate: Joi.date().allow(null).optional(),
      lodgingEndDate: Joi.date().allow(null).optional(),
      lodgingNote: Joi.string().allow(null, '').optional(),
      lodgingInactive: Joi.boolean().optional(),
      picture: Joi.string().allow(null).min(2).optional(),
    });
    // eslint-disable-next-line no-console
    return (Joi.validate(request, schema).error === null);
  }

  static isHorseNoteRequestValid(request) {
    if (request === null || request === undefined) {
      return false;
    }
    // Note, Medical, FeedChange, LocationChange, ProfileChange, Competition,
    const schema = Joi.object().keys({
      importance: Joi.string().valid('Alert', 'Moderate', 'Success', 'Inform').allow(null).optional(),
      information: Joi.object().optional(),
      note: Joi.string().min(0).max(2048).allow(null, '')
        .optional(),
      setAsHorseActiveNote: Joi.boolean().optional(),
      horseHighlight: Joi.number().allow(null).optional(),
      removeHorseHighlightOrder: Joi.boolean().allow(null).optional(),
      title: Joi.string().min(2).max(150).allow(null)
        .optional(),
      clientAccess: Joi.boolean().optional(),
      noteDate: Joi.date().allow(null).optional(),
      taskId: Joi.number().allow(null).optional(),
      type: Joi.string().valid('Note', 'Medical', 'FeedChange', 'LocationChange', 'ProfileChange', 'Competition', 'Billing', 'TackEquipment').allow(null).optional(),
    });
    return (Joi.validate(request, schema).error === null);
  }

  static isHorseFeedRequestValid(request) {
    if (request === null || request === undefined) {
      return false;
    }
    const schema = Joi.object().keys({
      frequency: Joi.object().optional(),
      notes: Joi.string().min(2).max(2048).allow(null, '')
        .optional(),
      quantity: Joi.number().allow(null, '').optional(),
      quantityMeasure: Joi.string().valid('lbs', 'oz', 'flakes', 'pills', 'mlcc', 'scoops', 'cups', 'quarts').optional(),
    });

    return (Joi.validate(request, schema).error === null);
  }

  /* *********
    General Horse Methods
  ********** */

  static addHorse(request) {
    const validation = HorseService.isHorseRequestValid(request);
    if (!validation) {
      console.error(validation);
      return Promise.reject(new Error(`Invalid add horse request: ${validation}`));
    }

    return new Promise((resolve, reject) => {
      this.httpService.post('/', request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static updateHorse(id, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }

    const validation = HorseService.isHorseRequestValid(request);
    if (!validation) {
      console.error(validation);
      return Promise.reject(new Error(`Invalid update horse request: ${validation}`));
    }

    return new Promise((resolve, reject) => {
      this.httpService.patch(`/${id}`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static getHorse(id) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    return this.httpService.get(`/${id}`);
  }

  static getHorses(page, size, sort) {
    return this.httpService.get(`?page=${page}&size=${size}${sort}`);
  }

  /* *********
    Barn Horse (Lodging) Methods
  ********** */

  static getLodgingsForHorse(id, barnId) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    if (!barnId) {
      return Promise.reject(new Error('Missing Barn ID'));
    }
    return this.httpService.get(`/${id}/barns/${barnId}`);
  }

  static getAllLodgingsForHorse(id, page, size, sort) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    return this.httpService.get(`/${id}/barns?page=${page}&size=${size}${sort}`);
  }

  /* *********
    Horse Billlables Methods
  ********** */

  static addHorseBillable(id, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.post(`/${id}/billables`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static getHorseBillableForTask(id, taskId) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    if (!taskId) {
      return Promise.reject(new Error('Missing Task ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.get(`/${id}/billables/${taskId}`).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  /* *********
    Horse Note Methods
  ********** */

  static getHorseNotes(id, page, size, sort, type) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    return this.httpService.get(`/${id}/notes?page=${page}&size=${size}${sort}&type=${type || ''}`);
  }

  static getHorseNoteHighlights(id) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    return this.httpService.get(`/${id}/notes/highlights`);
  }

  static addHorseNote(id, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }

    const validation = HorseService.isHorseNoteRequestValid(request);
    if (!validation) {
      console.error(validation);
      return Promise.reject(new Error(`Invalid add horse note request: ${validation}`));
    }

    return new Promise((resolve, reject) => {
      this.httpService.post(`/${id}/notes`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static updateHorseNote(id, noteId, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    if (!noteId) {
      return Promise.reject(new Error('Missing Note ID'));
    }

    const validation = HorseService.isHorseNoteRequestValid(request);
    if (!validation) {
      console.error(validation);
      return Promise.reject(new Error(`Invalid update horse note request: ${validation}`));
    }

    return new Promise((resolve, reject) => {
      this.httpService.patch(`/${id}/notes/${noteId}`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static deleteHorseNote(id, noteId) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    if (!noteId) {
      return Promise.reject(new Error('Missing Note ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.delete(`/${id}/notes/${noteId}`).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static getHorseNoteForTask(id, taskId) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    if (!taskId) {
      return Promise.reject(new Error('Missing Task ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.get(`/${id}/notes/${taskId}`).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  /* *********
    Horse Public Share Methods
  ********** */

  static getHorsePublicShares(id, page, size, sort) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    return this.httpService.get(`/${id}/horsePublicShares?page=${page}&size=${size}${sort}`);
  }

  static addHorsePublicShare(id, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.post(`/${id}/horsePublicShares`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  /* *********
    Horse Feed Methods
  ********** */

  static getHorseFeeds(id, page, size, sort) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    return this.httpService.get(`/${id}/feeds?page=${page}&size=${size}${sort}`);
  }

  static addHorseFeed(id, feedId, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    if (!feedId) {
      return Promise.reject(new Error('Missing Feed ID'));
    }

    const validation = HorseService.isHorseFeedRequestValid(request);
    if (!validation) {
      console.error(validation);
      return Promise.reject(new Error(`Invalid add horse feed request: ${validation}`));
    }

    return new Promise((resolve, reject) => {
      this.httpService.post(`/${id}/feeds/${feedId}`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static updateHorseFeed(id, feedId, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    if (!feedId) {
      return Promise.reject(new Error('Missing Feed ID'));
    }

    const validation = HorseService.isHorseFeedRequestValid(request);
    if (!validation) {
      console.error(validation);
      return Promise.reject(new Error(`Invalid update horse feed request: ${validation}`));
    }

    return new Promise((resolve, reject) => {
      this.httpService.patch(`/${id}/feeds/${feedId}`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static deleteHorseFeed(id, feedId) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    if (!feedId) {
      return Promise.reject(new Error('Missing Feed ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.delete(`/${id}/feeds/${feedId}`).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  /* *********
    Horse Location Methods
  ********** */

  static getHorseLocations(id, page, size, sort) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    return this.httpService.get(`/${id}/locations?page=${page}&size=${size}${sort}`);
  }

  /* *********
    Horse Files Methods
  ********** */

  static addHorseFile(id, fileId, file, clientAccess) {
    return this.httpService.postFile(`/${id}/files/${fileId}?clientData=${clientAccess
      ? 'true'
      : 'false'}`, file);
  }

  static getHorseFile(id, fileId, fileName) {
    return this.httpService.getFileDownload(`/${id}/files/${fileId}/${fileName}`);
  }

  /* *********
    Horse Users Methods
  ********** */

  static getHorseAccess(id, page, size, sort) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    return this.httpService.get(`/${id}/users?page=${page}&size=${size}${sort}`);
  }

  static addHorseAccess(id, barnUserId, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    if (!barnUserId) {
      return Promise.reject(new Error('Missing Barn User ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.post(`/${id}/users/${barnUserId}`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static updateHorseAccess(id, barnUserId, request) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    if (!barnUserId) {
      return Promise.reject(new Error('Missing Barn User ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.patch(`/${id}/users/${barnUserId}`, request).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  static deleteHorseAccessForHorse(id) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }

    return new Promise((resolve, reject) => {
      this.httpService.delete(`/${id}/users`).then((response) => {
        resolve(response);
      }, (error) => reject(new Error(error.data.message)));
    });
  }

  /* *********
    Horse Suggestion Methods
  ********** */

  static getHorseSuggestion(id) {
    if (!id) {
      return Promise.reject(new Error('Missing Horse ID'));
    }
    return this.httpService.get(`/${id}/horseSuggestions`);
  }
}

HorseService.httpService = new HttpService({
  baseURL: `${typeof window !== 'undefined'
    ? env.REACT_APP_HORSE_API
    : ''}/horses`,
});
