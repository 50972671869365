/* eslint-disable no-case-declarations */
import * as types from './types';
import { BOARD_TASK_UPDATE } from '../board/types'
import { primary } from '../../utils/brandColors';
import { generateEventsFromSchedules } from '../../utils/scheduleHelper';

export default (state = {
  schedules: [],
  schedule: {}, // Set if a schedule is selected
  event: {}, // Set if an event for a schedule is selected
  eventTasks: [], // Set if an event for a schedule is selected and in past and generated tasks
  scheduleConfig: { // used to populate create task/schedule modal
    locationId: null, // if set, will let us know to open calendar event modal
    taskTagIds: [], // if provided, a task will be created for each id
    eventTemplateTagTypeFilter: -1, // filter available task types
    openInSeries: true,
    startDate: null,
    duration: null,
    assignment: null,
    assignmentTaskMap: null,
    workflows: null,
    rescheduleDate: null,
  },
  scheduleEvents: [],
  scheduleScheduleExceptions: { content: [] },
  eventTemplateSchedules: [],
  scheduleExceptions: [],
  scheduleResponses: [],
  events: [],
  startDate: new Date(),
  endDate: new Date(),
  isFetchingSchedules: false,
  isFetchingSchedule: false,
  isFetchingEventTemplateSchedules: false,
  isFetchingScheduleScheduleExceptions: false,
  isFetchingScheduleResponses: true,
  scheduleResponsesLoaded: false,
  isAddingScheduleResponses: false,
}, action = {}) => {
  switch (action.type) {
    case types.SET_SCHEDULE_EVENTS:
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
        events: generateEventsFromSchedules(
          action.schedules,
          action.scheduleExceptions,
          action.startDate,
          action.endDate,
          action.barnUsersMap,
          action.barnMetadata,
          action.mappedStatuses || {},
        ),
      };
    case types.GET_SCHEDULES_REQUESTED:
      return {
        ...state,
        schedules: [],
        isFetchingSchedules: true,
      };
    case types.GET_SCHEDULES_SUCCESS:
      return {
        ...state,
        isFetchingSchedules: false,
        schedules: action.schedules,
      };
    case types.GET_SCHEDULES_ERROR:
      return {
        ...state,
        isFetchingSchedules: false,
      };
    case types.GET_SCHEDULE_REQUESTED:
      return {
        ...state,
        isFetchingSchedule: true,
        scheduleEvents: [],
        scheduleResponses: [],
      };
    case types.GET_SCHEDULE_SUCCESS:
      // Every child should map to an exception, do so here
      const updatedScheduleScheduleExceptions = {
        ...state.scheduleScheduleExceptions,
        content: state.scheduleScheduleExceptions.content.map((se) => {
          const replacementSchedule = (action.childrenSchedules.find((s) => s.scheduleExceptionId === se.id));
          const timeChange = replacementSchedule && new Date(replacementSchedule.startDate).getTime() !== new Date(se.exceptionDate).getTime();
          return {
            ...se,
            replacement: replacementSchedule
              ? {
                replacementSchedule,
                timeChange,
                calendarColor: timeChange
                  ? primary[300]
                  : primary[400],
                chipColor: timeChange
                  ? 'primary'
                  : 'default',
                title: timeChange
                  ? `RESCHEDULED - ${replacementSchedule.name}`
                  : `UPDATED - ${replacementSchedule.name}`,
              }
              : null,
          };
        }),
      };
      return {
        ...state,
        isFetchingSchedule: false,
        schedule: {
          ...action.schedule,
          children: action.childrenSchedules,
        },
        scheduleScheduleExceptions: updatedScheduleScheduleExceptions,
        scheduleEvents: generateEventsFromSchedules(
          [action.schedule, ...action.childrenSchedules],
          updatedScheduleScheduleExceptions.content,
          action.startDate,
          action.endDate,
          action.barnUsersMap,
          action.barnMetadata,
        ),
      };
    case types.SET_SCHEDULE_DATA_FOR_MODALS: {
      return {
        ...state,
        schedule: action.schedule,
        event: action.event || {},
        scheduleConfig: {
          ...state.scheduleConfig,
          ...action.scheduleConfig,
        },
      };
    }
    case types.GET_SCHEDULE_ERROR:
      return {
        ...state,
        isFetchingSchedule: false,
      };
    case types.REMOVE_SELECTED_SCHEDULE:
      return {
        ...state,
        schedule: {},
        scheduleEvent: {},
        scheduleScheduleExceptions: { content: [] },
        scheduleEvents: [],
      };
    case types.GET_SCHEDULE_SCHEDULE_EXCEPTIONS_REQUESTED:
      return {
        ...state,
        scheduleScheduleExceptions: { content: [] },
        isFetchingScheduleScheduleExceptions: true,
      };
    case types.GET_SCHEDULE_SCHEDULE_EXCEPTIONS_SUCCESS:
      const newScheduleScheduleExceptions = {
        ...action.scheduleExceptions,
        content: action.scheduleExceptions.content.map((se) => {
          const replacementSchedule = (state.schedule.children && state.schedule.children.find((s) => s.scheduleExceptionId === se.id));
          const timeChange = replacementSchedule && new Date(replacementSchedule.startDate).getTime() !== new Date(se.exceptionDate).getTime();
          return {
            ...se,
            replacement: replacementSchedule
              ? {
                replacementSchedule,
                timeChange,
                calendarColor: timeChange
                  ? primary[300]
                  : primary[400],
                chipColor: timeChange
                  ? 'primary'
                  : 'default',
                title: timeChange
                  ? `RESCHEDULED - ${replacementSchedule.name}`
                  : `UPDATED - ${replacementSchedule.name}`,
              }
              : null,
          };
        }),
      };

      return {
        ...state,
        isFetchingScheduleScheduleExceptions: false,
        scheduleScheduleExceptions: newScheduleScheduleExceptions,
      };
    case types.GET_SCHEDULE_SCHEDULE_EXCEPTIONS_ERROR:
      return {
        ...state,
        isFetchingScheduleScheduleExceptions: false,
      };
    case types.GET_EVENT_TEMPLATE_SCHEDULES_REQUESTED:
      return {
        ...state,
        eventTemplateSchedules: [],
        isFetchingEventTemplateSchedules: true,
      };
    case types.GET_EVENT_TEMPLATE_SCHEDULES_SUCCESS:
      return {
        ...state,
        isFetchingEventTemplateSchedules: false,
        eventTemplateSchedules: action.schedules,
      };
    case types.GET_EVENT_TEMPLATE_SCHEDULES_ERROR:
      return {
        ...state,
        isFetchingEventTemplateSchedules: false,
      };
    case types.GET_SCHEDULE_EXCEPTIONS_REQUESTED:
      return {
        ...state,
        scheduleExceptions: [],
        isFetchingSchedules: true,
      };
    case types.GET_SCHEDULE_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        isFetchingSchedules: false,
        scheduleExceptions: action.scheduleExceptions,
      };
    case types.GET_SCHEDULE_EXCEPTIONS_ERROR:
      return {
        ...state,
        isFetchingSchedules: false,
      };
    case types.GET_SCHEDULE_RESPONSES_REQUESTED:
      return {
        ...state,
        scheduleResponses: [],
        isFetchingScheduleResponses: true,
        scheduleResponsesLoaded: false,
      };
    case types.GET_SCHEDULE_RESPONSES_SUCCESS:
      return {
        ...state,
        isFetchingScheduleResponses: false,
        scheduleResponsesLoaded: true,
        scheduleResponses: action.scheduleResponses,
      };
    case types.GET_SCHEDULE_RESPONSES_ERROR:
      return {
        ...state,
        isFetchingScheduleResponses: false,
        scheduleResponsesLoaded: false,
      };
    case types.ADD_SCHEDULE_RESPONSES_REQUESTED:
      return {
        ...state,
        isAddingScheduleResponses: true,
      };
    case types.ADD_SCHEDULE_RESPONSES_SUCCESS:
      const foundExistingResponse = state.scheduleResponses.find((r) => r.responderId === action.scheduleResponse.responderId
        && new Date(r.occurrenceDate || 0).getTime() === new Date(action.scheduleResponse.occurrenceDate || 0).getTime())
      return {
        ...state,
        isAddingScheduleResponses: false,
        scheduleResponses: !foundExistingResponse
          ? [action.scheduleResponse, ...state.scheduleResponses]
          : state.scheduleResponses.map((r) => (r.id === foundExistingResponse.id
            ? action.scheduleResponse
            : r)),
      };
    case types.ADD_SCHEDULE_RESPONSES_ERROR:
      return {
        ...state,
        isAddingScheduleResponses: false,
      };
    case types.ADD_SCHEDULE_REQUESTED:
      return {
        ...state,
        isFetchingSchedules: true,
      };
    case types.ADD_SCHEDULE_SUCCESS:
      return {
        ...state,
        isFetchingSchedules: false,
        schedules: [...state.schedules, action.newSchedule],
        eventTemplateSchedules: [...state.eventTemplateSchedules, action.newSchedule],
      };
    case types.ADD_SCHEDULE_ERROR:
      return {
        ...state,
        isFetchingSchedules: false,
      };
    case types.UPDATE_SCHEDULE_REQUESTED:
      return {
        ...state,
        isFetchingSchedules: true,
      };
    case types.UPDATE_SCHEDULE_SUCCESS:
      const updatedSchedules = state.schedules.map((f) => (f.id === action.updatedSchedule.id
        ? action.updatedSchedule
        : f))
      return {
        ...state,
        isFetchingSchedules: false,
        schedules: updatedSchedules,
        eventTemplateSchedules: state.eventTemplateSchedules.map((f) => (f.id === action.updatedSchedule.id
          ? action.updatedSchedule
          : f)),
        events: generateEventsFromSchedules(
          updatedSchedules,
          state.scheduleExceptions,
          state.startDate,
          state.endDate,
          action.barnUsersMap,
          action.barnMetadata,
        ),
      };
    case types.UPDATE_SCHEDULE_ERROR:
      return {
        ...state,
        isFetchingSchedules: false,
      };
    case types.DELETE_SCHEDULE_REQUESTED:
      return {
        ...state,
        isFetchingSchedules: true,
      };
    case types.DELETE_SCHEDULE_SUCCESS:
      return {
        ...state,
        isFetchingSchedules: false,
        events: state.events.filter((e) => e.resource.id !== action.deletedScheduleId
          && (!e.resource.parentSchedule || e.resource.parentSchedule.id !== action.deletedScheduleId)),
        schedules: state.schedules.filter((f) => f.id !== action.deletedScheduleId
          && (!f.parentschedule || f.parentSchedule.id !== action.deletedScheduleId)),
        eventTemplateSchedules: state.eventTemplateSchedules.filter((f) => f.id !== action.deletedScheduleId
          && (!f.parentschedule || f.parentSchedule.id !== action.deletedScheduleId)),
      };
    case types.DELETE_SCHEDULE_ERROR:
      return {
        ...state,
        isFetchingSchedules: false,
      };
    case types.ADD_SCHEDULE_EXCEPTION_REQUESTED:
      return {
        ...state,
        isFetchingSchedules: true,
      };
    case types.ADD_SCHEDULE_EXCEPTION_SUCCESS:
      const newScheduleExceptions = [...state.scheduleExceptions, action.newScheduleException];
      const newSchedulesFromException = action.newChildSchedule
        ? [...state.schedules, action.newChildSchedule]
        : state.schedules;
      return {
        ...state,
        isFetchingSchedules: false,
        scheduleExceptions: newScheduleExceptions,
        schedules: newSchedulesFromException,
        events: generateEventsFromSchedules(
          newSchedulesFromException,
          newScheduleExceptions,
          state.startDate,
          state.endDate,
          action.barnUsersMap,
          action.barnMetadata,
        ),
      };
    case types.ADD_SCHEDULE_EXCEPTION_ERROR:
      return {
        ...state,
        isFetchingSchedules: false,
      };
    case types.DELETE_SCHEDULE_EXCEPTION_REQUESTED:
      return {
        ...state,
        isFetchingSchedules: true,
      };
    case types.DELETE_SCHEDULE_EXCEPTION_SUCCESS:
      return {
        ...state,
        isFetchingSchedules: false,
        scheduleExceptions: state.scheduleExceptions.filter((f) => f.id !== action.deletedScheduleExeptionId),
        scheduleScheduleExceptions: {
          ...state.scheduleScheduleExceptions,
          content: state.scheduleScheduleExceptions.filter((f) => f.id !== action.deletedScheduleExeptionId),
        },
      };
    case types.DELETE_SCHEDULE_EXCEPTION_ERROR:
      return {
        ...state,
        isFetchingSchedules: false,
      };
    case types.SET_SCHEDULE_EVENT_TASKS:
      return {
        ...state,
        eventTasks: action.eventTasks,
      };
    case BOARD_TASK_UPDATE:
      return {
        ...state,
        lastUpdatedDate: new Date(),
        eventTasks: state.eventTasks.map((t) => (t.id === action.task.event.id
          ? action.task.event
          : t)),
      };
    default:
      return state;
  }
};
