/* eslint-disable no-case-declarations */
import * as types from './types';
import * as horseTypes from '../horse/types';

export default (state = {
  // List of shares - edit page
  horsePublicShares: { content: [] },
  isFetchingHorsePublicShares: false,
  isAddingHorsePublicShare: false,
  isEditingHorsePublicShare: false,
  isDeletingHorsePublicShare: false,
  // Single share - public page
  horsePublicShare: null,
  isFetchingHorsePublicShare: false,
}, action = {}) => {
  switch (action.type) {
    case types.GET_HORSE_PUBLIC_SHARES_REQUESTED:
      return {
        ...state,
        horsePublicShares: { content: [] },
        isFetchingHorsePublicShares: true,
      };
    case types.GET_HORSE_PUBLIC_SHARES_SUCCESS:
      return {
        ...state,
        isFetchingHorsePublicShares: false,
        horsePublicShares: action.horsePublicShares,
      };
    case types.GET_HORSE_PUBLIC_SHARES_ERROR:
      return {
        ...state,
        isFetchingHorsePublicShares: false,
      };
    case types.GET_HORSE_PUBLIC_SHARE_REQUESTED:
      return {
        ...state,
        horsePublicShare: null,
        isFetchingHorsePublicShare: true,
      };
    case types.GET_HORSE_PUBLIC_SHARE_SUCCESS:
      return {
        ...state,
        isFetchingHorsePublicShare: false,
        horsePublicShare: action.horsePublicShare,
      };
    case types.GET_HORSE_PUBLIC_SHARE_ERROR:
      return {
        ...state,
        isFetchingHorsePublicShare: false,
      };

    case types.ADD_HORSE_PUBLIC_SHARE_REQUESTED:
      return {
        ...state,
        isAddingHorsePublicShare: true,
      };
    case types.ADD_HORSE_PUBLIC_SHARE_SUCCESS:
      return {
        ...state,
        isAddingHorsePublicShare: false,
        horsePublicShares: {
          ...state.horsePublicShares,
          content: [{
            ...action.newHorsePublicShare,
            new: true,
          }].concat(state.horsePublicShares.content),
        },
      };
    case types.ADD_HORSE_PUBLIC_SHARE_ERROR:
      return {
        ...state,
        isAddingHorsePublicShare: false,
      };

    case types.UPDATE_HORSE_PUBLIC_SHARE_REQUESTED:
      return {
        ...state,
        isEditingHorsePublicShare: true,
      };
    case types.UPDATE_HORSE_PUBLIC_SHARE_SUCCESS:
      return {
        ...state,
        isEditingHorsePublicShare: false,
        horsePublicShares: {
          ...state.horsePublicShares,
          content: state.horsePublicShares.content.map((f) => (f.id === action.updatedHorsePublicShare.id
            ? action.updatedHorsePublicShare
            : f)),
        }
        ,
      };
    case types.UPDATE_HORSE_PUBLIC_SHARE_ERROR:
      return {
        ...state,
        isEditingHorsePublicShare: false,
      };
    case types.DELETE_HORSE_PUBLIC_SHARE_REQUESTED:
      return {
        ...state,
        isDeletingHorsePublicShare: true,
      };
    case types.DELETE_HORSE_PUBLIC_SHARE_SUCCESS:
      return {
        ...state,
        isDeletingHorsePublicShare: false,
        horsePublicShares: {
          ...state.horsePublicShares,
          content: state.horsePublicShares.content.filter((f) => f.id !== action.deletedHorsePublicShareId),
        },
      };
    case types.DELETE_HORSE_PUBLIC_SHARE_ERROR:
      return {
        ...state,
        isDeletingHorsePublicShare: false,
      };
    case types.GET_HORSE_PUBLIC_SHARE_FOR_HORSE_REQUESTED:
      return {
        ...state,
        horsePublicShare: null,
        isFetchingHorsePublicShare: true,
      };
    case types.GET_HORSE_PUBLIC_SHARE_FOR_HORSE_SUCCESS:
      return {
        ...state,
        isFetchingHorsePublicShare: false,
        horsePublicShare: action.horsePublicShare,
      };
    case types.GET_HORSE_PUBLIC_SHARE_FOR_HORSE_ERROR:
      return {
        ...state,
        isFetchingHorsePublicShare: false,
      };
    case horseTypes.SET_HORSE_FILE_PREVIEWS:
      if (state.horsePublicShare) {
        const newHorseNotesByType = state.horsePublicShare.horseNotesByType;
        const notesByType = Object.keys(newHorseNotesByType)
          .filter((noteKey) => state.horsePublicShare.horseNotesByType[noteKey].length > 0)
        if (notesByType.length > 0) {
          for (let i = 0; i < notesByType.length; i += 1) {
            newHorseNotesByType[notesByType[i]] = newHorseNotesByType[notesByType[i]].map((n) => (n.id === action.noteId
              ? {
                ...n,
                information: {
                  ...n.information,
                  filePreviews: {
                    ...(n.information.filePreviews || {}),
                    [action.file]: action.base64data,
                  },
                },
              }
              : n))
          }
        }

        return {
          ...state,
          horsePublicShare: {
            ...state.horsePublicShare,
            sharedNotes: state.horsePublicShare.sharedNotes.map((n) => (n.id === action.noteId
              ? {
                ...n,
                information: {
                  ...n.information,
                  filePreviews: {
                    ...(n.information.filePreviews || {}),
                    [action.file]: action.base64data,
                  },
                },
              }
              : n)),
          },
          horseNotesByType: newHorseNotesByType,
        };
      }
      return state

    default:
      return state;
  }
};

