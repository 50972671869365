import * as types from './types';

export default (state = {
  // me
  myTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  contextTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  // barn horse
  selectedBarnHorse: null,

  // global modals
  openModalType: null,
  modalCallback: () => {},
  isFetchingBillableForTask: false,
  billableForTask: null,
  isFetchingNoteForTask: false,
  noteForTask: null,
}, action = {}) => {
  switch (action.type) {
    case types.SET_SELECTED_BARN_HORSE:
      return {
        ...state,
        selectedBarnHorse: action.barnHorse,
      };
    case types.SET_GLOABL_MODALS:
      return {
        ...state,
        openModalType: action.openModalType || null,
        modalCallback: action.callback || (() => {}),
      };
    case types.SET_MY_TIMEZONE:
      return {
        ...state,
        myTimezone: action.myTimezone,
        contextTimezone: action.contextTimezone,
      };
    case types.GET_BILLABLE_FOR_TASK_REQUESTED:
      return {
        ...state,
        billableForTask: null,
        isFetchingBillableForTask: true,
      };
    case types.GET_BILLABLE_FOR_TASK_SUCCESS:
      return {
        ...state,
        isFetchingBillableForTask: false,
        billableForTask: action.billableForTask,
      };
    case types.GET_BILLABLE_FOR_TASK_ERROR:
      return {
        ...state,
        isFetchingBillableForTask: false,
        billableForTask: null,
      }
    case types.GET_NOTE_FOR_TASK_REQUESTED:
      return {
        ...state,
        noteForTask: null,
        isFetchingNoteForTask: true,
      };
    case types.GET_NOTE_FOR_TASK_SUCCESS:
      return {
        ...state,
        isFetchingNoteForTask: false,
        noteForTask: action.noteForTask,
      };
    case types.GET_NOTE_FOR_TASK_ERROR:
      return {
        ...state,
        isFetchingNoteForTask: false,
        noteForTask: null,
      }
    default:
      return state;
  }
};
