// import Joi from 'joi-browser';
import HttpService from '@pingum/api-services/HttpService';

export default class StreamService {
  static getMe() {
    return this.httpService.get('/me');
  }
}

StreamService.httpService = new HttpService({
  baseURL: `${typeof window !== 'undefined'
  // eslint-disable-next-line no-undef
    ? env.REACT_APP_HORSE_API
    : ''}/streams`,
});
