import FileDownload from 'react-file-download';
import * as types from './types';

export default (state = {
  invoices: { content: [] },
  invoicesInDraft: 0,
  invoicesInOpen: 0,
  invoice: null,
  isFetchingInvoices: false,
  isAddingInvoice: false,
  isUpdatingInvoice: false,
  isDownloadingInvoicePdf: false,
}, action = {}) => {
  switch (action.type) {
    case types.GET_INVOICES_REQUESTED:
      return {
        ...state,
        invoices: { content: [] },
        isFetchingInvoices: true,
      };
    case types.GET_INVOICES_SUCCESS:
      return {
        ...state,
        isFetchingInvoices: false,
        invoices: action.invoices.data,
        invoicesInDraft: action.invoices.headers['x-invoices-draft'],
        invoicesInOpen: action.invoices.headers['x-invoices-open'],
      };
    case types.GET_INVOICES_ERROR:
      return {
        ...state,
        isFetchingInvoices: false,
      };
    case types.GET_INVOICE_REQUESTED:
      return {
        ...state,
        invoice: null,
        isFetchingInvoices: true,
      };
    case types.GET_INVOICE_SUCCESS:
      return {
        ...state,
        isFetchingInvoices: false,
        invoice: action.invoice,
      };
    case types.GET_INVOICE_ERROR:
      return {
        ...state,
        isFetchingInvoices: false,
      };

    case types.ADD_INVOICES_REQUESTED:
      return {
        ...state,
        isAddingInvoice: true,
      };
    case types.ADD_INVOICES_SUCCESS:
      return {
        ...state,
        isAddingInvoice: false,
        invoices: {
          ...state.invoices,
          content: [{ ...action.newInvoice }].concat(state.invoices.content),
        },
      };
    case types.ADD_INVOICES_ERROR:
      return {
        ...state,
        isAddingInvoice: false,
      };
    case types.UPDATE_INVOICES_REQUESTED:
      return {
        ...state,
        isUpdatingInvoice: true,
      };
    case types.UPDATE_INVOICES_SUCCESS:
      return {
        ...state,
        isUpdatingInvoice: false,
        invoices: {
          ...state.invoices,
          content: state.invoices.content.map((f) => (f.id === action.updatedInvoice.id
            ? action.updatedInvoice
            : f)),
        },
      };
    case types.UPDATE_INVOICES_ERROR:
      return {
        ...state,
        isUpdatingInvoice: false,
      };
    case types.DELETE_INVOICES_REQUESTED:
      return {
        ...state,
        isUpdatingInvoice: true,
      };
    case types.DELETE_INVOICES_SUCCESS:
      return {
        ...state,
        isUpdatingInvoice: false,
        invoices: {
          ...state.invoices,
          content: state.invoices.content.filter((f) => f.id !== action.deletedInvoiceId),
        },
      };
    case types.DELETE_INVOICES_ERROR:
      return {
        ...state,
        isUpdatingInvoice: false,
      };
    case types.GET_INVOICE_PDF_REQUESTED:
      return {
        ...state,
        isDownloadingInvoicePdf: true,
      };
      case types.GET_INVOICE_PDF_SUCCESS: //eslint-disable-line
      // eslint-disable-next-line no-case-declarations
      const blob = new Blob([action.download], { type: 'application/octet-stream' });
      FileDownload(blob, action.fileName);
      return {
        ...state,
        isDownloadingInvoicePdf: false,
      };
    case types.GET_INVOICE_PDF_ERROR:
      return {
        ...state,
        isDownloadingInvoicePdf: false,
      };
    default:
      return state;
  }
};

