import * as types from './types';

export default (state = {
  horseAccessForBarnAndType: [],
  horseAccess: { content: [] },
  isFetchingHorseAccess: false,
}, action = {}) => {
  switch (action.type) {
    case types.GET_HORSE_ACCESS_BY_TYPE_REQUESTED:
      return {
        ...state,
        horseAccessForBarnAndType: [],
        isFetchingHorseAccess: true,
      };
    case types.GET_HORSE_ACCESS_BY_TYPE_SUCCESS:
      return {
        ...state,
        isFetchingHorseAccess: false,
        horseAccessForBarnAndType: action.horseAccessForBarnAndType,
      };
    case types.GET_HORSE_ACCESS_BY_TYPE_ERROR:
      return {
        ...state,
        isFetchingHorseAccess: false,
      };
    case types.GET_HORSE_ACCESS_REQUESTED:
      return {
        ...state,
        horseAccess: { content: [] },
        isFetchingHorseAccess: true,
      };
    case types.GET_HORSE_ACCESS_SUCCESS:
      return {
        ...state,
        isFetchingHorseAccess: false,
        horseAccess: action.horseAccess,
      };
    case types.GET_HORSE_ACCESS_ERROR:
      return {
        ...state,
        isFetchingHorseAccess: false,
      };
    case types.ADD_HORSE_ACCESS_REQUESTED:
      return {
        ...state,
        isFetchingHorseAccess: true,
      };
    case types.ADD_HORSE_ACCESS_SUCCESS:
      return {
        ...state,
        isFetchingHorseAccess: false,
        horseAccess: {
          ...state.horseAccess,
          content: [{
            ...action.newHorseAccess,
            new: true, // signal in UI to show newly added HorseAccess
          }].concat(state.horseAccess.content),
        },
      };
    case types.ADD_HORSE_ACCESS_ERROR:
      return {
        ...state,
        isFetchingHorseAccess: false,
      };
    case types.UPDATE_HORSE_ACCESS_REQUESTED:
      return {
        ...state,
        isFetchingHorseAccess: true,
      };
    case types.UPDATE_HORSE_ACCESS_SUCCESS:
      return {
        ...state,
        isFetchingHorseAccess: false,
        horseAccess: {
          ...state.horseAccess,
          content: state.horseAccess.content.map((f) => (f.id === action.updatedHorseAccess.id
            ? action.updatedHorseAccess
            : f)),
        }
        ,
      };
    case types.UPDATE_HORSE_ACCESS_ERROR:
      return {
        ...state,
        isFetchingHorseAccess: false,
      };
    case types.DELETE_HORSE_ACCESS_REQUESTED:
      return {
        ...state,
        isFetchingHorseAccess: true,
      };
    case types.DELETE_HORSE_ACCESS_SUCCESS:
      return {
        ...state,
        isFetchingHorseAccess: false,
        horseAccess: {
          ...state.horseAccess,
          content: state.horseAccess.content.filter((f) => f.id !== action.deletedHorseAccessId),
        },
      };
    case types.DELETE_HORSE_ACCESS_ERROR:
      return {
        ...state,
        isFetchingHorseAccess: false,
      };
    case types.DELETE_HORSE_ACCESS_FOR_HORSE_REQUESTED:
      return {
        ...state,
        isFetchingHorseAccess: true,
      };
    case types.DELETE_HORSE_ACCESS_FOR_HORSE_SUCCESS:
      return {
        ...state,
        isFetchingHorseAccess: false,
        horseAccess: { content: [] },
      };
    case types.DELETE_HORSE_ACCESS_FOR_HORSE_ERROR:
      return {
        ...state,
        isFetchingHorseAccess: false,
      };
    default:
      return state;
  }
};

