import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Person from '@mui/icons-material/Person';
import { WorkspacePremium } from '@mui/icons-material';
import Chip from '../Chip';
import BarnIcon from '../CustomIcons/Barn';
import { grey } from '../../utils/brandColors';

import styles from './styles';

export const getLocationIcon = (type) => {
  if (type === 'Competition') {
    return <WorkspacePremium sx={{ color: grey[500] }} />
  } if (type === 'Trainer') {
    return <Person sx={{ color: grey[500] }} />
  }
  return <BarnIcon color={grey[500]} fontSize={20} margin="4" />
}

const LocationChip = ({
  bordered,
  location,
  classes,
  size,
  ...rest
}) => (
  <Chip
    avatar={getLocationIcon(location.type)}
    label={location.name}
    disableGutters
    showDelete={false}
    variant="filled"
    size="small"
    {...rest}
  />
);

LocationChip.propTypes = {
  bordered: PropTypes.bool,
  location: PropTypes.object.isRequired,
  classes: PropTypes.object,
  size: PropTypes.any,
};

LocationChip.defaultProps = {
  bordered: false,
  classes: {},
  size: 40,
};

export default withStyles(styles)(LocationChip);
