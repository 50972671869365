export const primary = {
  50: '#eceff1',
  100: '#cfd8dc',
  200: '#b0bec5',
  300: '#90a4ae',
  400: '#78909c',
  500: '#2F4858',
  600: '#546e7a',
  700: '#455a64',
  800: '#37474f',
  900: '#263238',
  light: '#b0bec5',
  main: '#2F4858',
  dark: '#546e7a',
};

export const secondary = {
  50: '#fafafa',
  100: '#f5f5f5',
  200: '#eeeeee',
  300: '#e0e0e0',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#1C364C',
  light: '#eeeeee',
  main: '#9e9e9e',
  dark: '#212121',
};

export const complementary = {
  50: '#efebe9',
  100: '#d7ccc8',
  200: '#ff842b',
  300: '#ff7a1f',
  400: '#ff7012',
  500: '#FF6600',
  600: '#f35c00',
  700: '#e75200',
  800: '#db4800',
  900: '#3e2723',
  light: '#ff842b',
  main: '#FF6600',
  dark: '#db4800',
};

export const success = {
  50: '#DCF6FC',
  100: '#C7F1FA',
  200: '#B1EBF8',
  300: '#9CE6F6',
  400: '#87E0F4',
  500: '#72DBF2',
  600: '#61BACE',
  700: '#5099A9',
  800: '#3F7885',
  900: '#2E5861',
  light: '#B1EBF8',
  main: '#72DBF2',
  dark: '#5099A9',
};

export const danger = {
  50: '#FDBFD5',
  100: '#FC99BC',
  200: '#FB73A3',
  300: '#FA4D8A',
  400: '#F92671',
  A400: '#F92671',
  500: '#F80058',
  600: '#D3004B',
  700: '#AE003E',
  800: '#880030',
  900: '#630023',
  light: '#FB73A3',
  main: '#F80058',
  dark: '#AE003E',
};

export const grey = {
  50: '#F6F6F6',
  100: '#F4F4F4',
  200: '#F2F2F2',
  300: '#CECECE',
  400: '#A9A9A9',
  500: '#858585',
  600: '#616161',
  700: '#3D3D3D',
  800: '#181818',
  900: '#020202',
  light: '#F2F2F2',
  main: '#858585',
  dark: '#3D3D3D',
};

export const blueGrey = {
  50: '#dadee1',
  100: '#b6bec4',
  200: '#929ea8',
  300: '#70808c',
  400: '#4f6372',
  500: '#2F4858',
  600: '#293c49',
  700: '#22313b',
  800: '#1c262e',
  900: '#151c20',
  light: '#b6bec4',
  main: '#2F4858',
  dark: '#1c262e',
};
