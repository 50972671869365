// import Joi from 'joi-browser';
import HttpService from '@pingum/api-services/HttpService';

export default class BarnService {
  static search(search, page, size, sort) {
    if (!search) {
      return Promise.reject(new Error('Missing Search Term'));
    }

    return this.httpService.get(`?page=${page}&size=${size}${sort}&search=${search}`);
  }
}

BarnService.httpService = new HttpService({
  baseURL: `${typeof window !== 'undefined'
    ? env.REACT_APP_HORSE_API
    : ''}/search`,
});
