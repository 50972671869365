import React from 'react';
import ReactAvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import {
  Typography,
  Grid,
  Icon,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Delete } from '@mui/icons-material';
import * as brandColors from '../../utils/brandColors';

const defaultSize = 150;

class AvatarEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = { scale: props.scale };
  }

  onChange = () => {
    let image = null;
    if (this.state.image && this.state.editor) {
      image = this.state.editor.getImageScaledToCanvas().toDataURL();
    }
    this.props.onChange(image);
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  onCancel = () => {
    this.setState({ image: null });
  }

  setEditorRef = (editor) => {
    this.setState({ editor });
  }

  handleDrop = (dropped) => {
    if (dropped && dropped[0]) {
      this.setState({ image: dropped[0] });
    }
  }

  handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  }

  render() {
    return (
      <div>
        {
          !this.state.image
          && (
            <Dropzone
              onDrop={this.handleDrop}
              accept="image/*"
            >
              {({
                getRootProps,
                getInputProps,
              }) => (
                <div
                  {...getRootProps()}
                  style={{
                    backgroundColor: brandColors.grey['50'],
                    border: `2px solid ${brandColors.grey['200']}`,
                    borderRadius: '4px',
                    cursor: 'pointer',
                    color: brandColors.grey['800'],
                    textAlign: 'center',
                    padding: '2rem 0rem',
                  }}
                >
                  <Icon>
                    file_upload
                  </Icon>
                  <div
                    style={{
                      paddingLeft: '8px',
                      paddingRight: '8 px',
                    }}
                  >
                    {this.props.title}
                    <br />
                    <Typography variant="caption">Drop files here</Typography>
                    <input {...getInputProps()} />
                  </div>
                </div>
              )}
            </Dropzone>
          )
        }
        {
          this.state.image
            && (
              <Grid container>
                <Grid item xs={12} md={6}>
                  <ReactAvatarEditor
                    ref={this.setEditorRef}
                    image={this.state.image}
                    width={this.props.width}
                    height={this.props.height}
                    border={this.props.border}
                    borderRadius={this.props.borderRadius}
                    scale={this.state.scale}
                    onImageReady={this.onChange}
                    onImageChange={this.onChange}
                    style={{
                      margin: 'auto',
                      display: 'block',
                      maxWidth: '100%',
                      cursor: 'all-scroll',
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <div
                    style={{
                      maxWidth: defaultSize,
                      margin: 'auto',
                      marginLeft: '1rem',
                    }}
                  >
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item>

                        <Typography variant="caption">
                          Zoom
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => this.props.onChange(null)}><Delete /></IconButton>
                      </Grid>
                    </Grid>
                    <input
                      name="scale"
                      type="range"
                      onChange={this.handleScale}
                      min="1"
                      max="2"
                      step="0.01"
                      defaultValue="1"
                      style={{ width: '100%' }}
                    />
                  </div>
                </Grid>
              </Grid>
            )
        }
      </div>
    );
  }
}

AvatarEditor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  border: PropTypes.number,
  borderRadius: PropTypes.number,
  scale: PropTypes.number,
  onChange: PropTypes.func,
  title: PropTypes.string,
};

AvatarEditor.defaultProps = {
  width: defaultSize,
  height: defaultSize,
  border: 0,
  borderRadius: defaultSize / 2,
  scale: 1,
  onChange: () => {},
  title: 'Upload an Image',
};

export default AvatarEditor;
