import * as types from './types';

export default (state = {
  billables: { content: [] },
  repeatingBillables: { content: [] },
  billablesOpen: '0.00',
  billablesPaid: '0.00',
  billablesVoid: '0.00',
  billablesExpense: '0.00',
  isFetchingBillables: false,
  isFetchingRepeatingBillables: false,
  isAddingBillable: false,
  isUpdatingBillable: false,
  rollup: {},
  analytics: {
    billablesDateBreakdown: null,
    billablesHorsesDateBreakdown: null,
    billablesByTaskTypeBreakdown: null,
  },
  isFetchingAnalytics: false,
  isFetchingRollup: false,
  billableInvoices: [],
  isFetchingBillableInvoices: false,
}, action = {}) => {
  switch (action.type) {
    case types.GET_BILLABLES_REQUESTED:
      return {
        ...state,
        billables: { content: [] },
        isFetchingBillables: true,
        billablesOpen: '0.00',
        billablesPaid: '0.00',
        billablesVoid: '0.00',
        billablesExpense: '0.00',
      };
    case types.GET_BILLABLES_SUCCESS:
      return {
        ...state,
        isFetchingBillables: false,
        billables: action.billables.data,
        billablesOpen: action.billables.headers['x-billables-open'],
        billablesPaid: action.billables.headers['x-billables-paid'],
        billablesVoid: action.billables.headers['x-billables-void'],
        billablesExpense: action.billables.headers['x-billables-expense'],
      };
    case types.GET_BILLABLES_ERROR:
      return {
        ...state,
        isFetchingBillables: false,
      };
    case types.GET_REPEATING_BILLABLES_REQUESTED:
      return {
        ...state,
        repeatingBillables: { content: [] },
        isFetchingRepeatingBillables: true,
      };
    case types.GET_REPEATING_BILLABLES_SUCCESS:
      return {
        ...state,
        isFetchingRepeatingBillables: false,
        repeatingBillables: action.billables.data,
      };
    case types.GET_REPEATING_BILLABLES_ERROR:
      return {
        ...state,
        isFetchingRepeatingBillables: false,
      };
    case types.ADD_BILLABLES_REQUESTED:
      return {
        ...state,
        isAddingBillable: true,
      };
    case types.ADD_BILLABLES_SUCCESS:
      return {
        ...state,
        isAddingBillable: false,
        billables: {
          ...state.billables,
          content: [{ ...action.newBillable }].concat(state.billables.content),
        },
        repeatingBillables: {
          ...state.repeatingBillables,
          content: [{ ...action.newBillable }].concat(state.repeatingBillables.content),
        },
      };
    case types.ADD_BILLABLES_ERROR:
      return {
        ...state,
        isAddingBillable: false,
      };
    case types.UPDATE_BILLABLES_REQUESTED:
      return {
        ...state,
        isUpdatingBillable: true,
      };
    case types.UPDATE_BILLABLES_SUCCESS:
      return {
        ...state,
        isUpdatingBillable: false,
        billables: {
          ...state.billables,
          content: state.billables.content.map((f) => (f.id === action.updatedBillable.id
            ? action.updatedBillable
            : f)),
        },
        repeatingBillables: {
          ...state.repeatingBillables,
          content: state.repeatingBillables.content.map((f) => (f.id === action.updatedBillable.id
            ? action.updatedBillable
            : f)),
        },
      };
    case types.UPDATE_BILLABLES_ERROR:
      return {
        ...state,
        isUpdatingBillable: false,
      };
    case types.DELETE_BILLABLES_REQUESTED:
      return {
        ...state,
        isUpdatingBillable: true,
      };
    case types.DELETE_BILLABLES_SUCCESS:
      return {
        ...state,
        isUpdatingBillable: false,
        billables: {
          ...state.billables,
          content: state.billables.content.filter((f) => f.id !== action.deletedBillableId),
        },
        repeatingBillables: {
          ...state.repeatingBillables,
          content: state.repeatingBillables.content.filter((f) => f.id !== action.deletedBillableId),
        },
      };
    case types.DELETE_BILLABLES_ERROR:
      return {
        ...state,
        isUpdatingBillable: false,
      };
    case types.GET_BARN_BILLABLES_ROLLUP_REQUESTED:
      return {
        ...state,
        rollup: {},
        isFetchingRollup: true,
      };
    case types.GET_BARN_BILLABLES_ROLLUP_SUCCESS:
      return {
        ...state,
        isFetchingRollup: false,
        rollup: action.rollup,
      };
    case types.GET_BARN_BILLABLES_ROLLUP_ERROR:
      return {
        ...state,
        isFetchingRollup: false,
      }
    case types.GET_BARN_BILLABLES_ANALYTICS_REQUESTED:
      return {
        ...state,
        analytics: {
          billablesDateBreakdown: null,
          billablesHorsesDateBreakdown: null,
          billablesByTaskTypeBreakdown: null,
        },
        isFetchingAnalytics: true,
      };
    case types.GET_BARN_BILLABLES_ANALYTICS_SUCCESS:
      return {
        ...state,
        isFetchingAnalytics: false,
        analytics: action.analytics,
      };
    case types.GET_BARN_BILLABLES_ANALYTICS_ERROR:
      return {
        ...state,
        isFetchingAnalytics: false,
        analytics: {
          billablesDateBreakdown: null,
          billablesHorsesDateBreakdown: null,
          billablesByTaskTypeBreakdown: null,
        },
      }
    case types.GET_BILLABLE_INVOICES_REQUESTED:
      return {
        ...state,
        billableInvoices: [],
        isFetchingBillableInvoices: true,
      };
    case types.GET_BILLABLE_INVOICES_SUCCESS:
      return {
        ...state,
        isFetchingBillableInvoices: false,
        billableInvoices: action.billableInvoices,
      };
    case types.GET_BILLABLE_INVOICES_ERROR:
      return {
        ...state,
        isFetchingBillableInvoices: false,
      }
    default:
      return state;
  }
};

