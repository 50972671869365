import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Chip from '@mui/material/Chip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import styles from './styles';

class ChipComponent extends React.Component {
  render() {
    const {
      avatar,
      icon,
      label,
      showDelete,
      onDelete,
      deleteIcon,
      onClick,
      clickable,
      classes,
      disableGutters,
      size,
      ...props
    } = this.props;

    return (
      <Chip
        component="div"
        avatar={avatar}
        icon={icon}
        size={size}
        label={label}
        onDelete={showDelete
          ? onDelete
          : null}
        deleteIcon={deleteIcon || <HighlightOffIcon />}
        clickable={clickable}
        onClick={onClick}
        classes={{
          label: size !== 'small'
            ? classes.labelLarge
            : classes.label,
          icon: size !== 'small'
            ? classes.iconLarge
            : classes.icon,
          deletable: classes.deletableChip,
          root:
            disableGutters
              ? classes.chipNoGutters
              : classes.chip,
          deleteIcon: classes.deleteIcon,
        }}
        {...props}
      />
    );
  }
}

ChipComponent.propTypes = {
  icon: PropTypes.any,
  avatar: PropTypes.any,
  label: PropTypes.any,
  showDelete: PropTypes.bool,
  clickable: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  deleteIcon: PropTypes.object,
  classes: PropTypes.object,
  disableGutters: PropTypes.bool,
  size: PropTypes.string,
  variant: PropTypes.string,
};

ChipComponent.defaultProps = {
  icon: null,
  avatar: null,
  label: '',
  showDelete: false,
  clickable: false,
  onDelete: () => {},
  onClick: () => {},
  deleteIcon: null,
  classes: {},
  disableGutters: false,
  size: 'medium',
  variant: 'default',
};

export default withStyles(styles)(ChipComponent);
