import { createTheme } from '@mui/material/styles';
import * as brandColors from './brandColors';

export const brandPalette = {
  primary: {
    ...brandColors.primary,
    contrastText: '#fff',
  },
  secondary: {
    ...brandColors.secondary,
    contrastText: '#fff',
  },
  error: {
    ...brandColors.danger,
    contrastText: '#fff',
  },
  grey: {
    ...brandColors.grey,
    contrastText: '#fff',
  },
};

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1400,
      xl: 2000,
    },
  },
  props: {
    MuiTextField: {
      // size: 'small',
      variant: 'outlined',
      autoComplete: 'off',
    },
    MuiFilledInput: { size: 'small' },
    MuiInput: { autoComplete: 'off' },
  },
  palette: {
    ...brandPalette,
    contrastThreshold: 3,
  },
  typography: {
    fontWeight: 300,
    useNextVariants: true,
    fontFamily: 'Lato',
    h4: { fontSize: 28 },
    h5: { fontSize: 18 },
  },
  overrides: {
    MuiAvatar: {
      colorDefault: {
        color: brandColors.grey[500],
        backgroundColor: brandColors.grey[200],
      },
    },
    MuiListItemText: { root: { padding: '0 16px' } },
    MuiTableCell: {
      root: {
        padding: '.5em 1em',
        minWidth: 120,
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: '#F8F8F8',
        boxShadow: 'none',
        color: brandColors.primary[600],
        fontWeight: 900,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: brandColors.secondary[50],
          boxShadow: 'none',
          textDecoration: 'none',
        },
      },
      containedPrimary: {
        color: 'white',
        textTransform: 'none',
        '&:hover': { color: 'white' },
      },
      containedSecondary: { background: brandColors.danger.main },
      textSecondary: { color: brandColors.danger.main },
      outlinedSecondary: {
        color: brandColors.danger.main,
        borderColor: brandColors.danger.main,
      },
      text: { textTransform: 'none' },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'white',
        color: brandColors.grey.dark,
      },
    },
    MuiCard: { root: { marginBottom: 10 } },
    MuiTypography: {
      root: { fontWeight: 300 },
      h1: {
        fontSize: '3em',
        lineHeight: '1.15em',
      },
      h2: { fontSize: '2.2em' },
      h3: {
        fontSize: '1.825em',
        margin: '20px 0 10px',
        lineHeight: '1.4em',
      },
      h4: {
        marginTop: '10px',
        marginBottom: '10px',
        fontSize: '1.3em',
      },
      h5: {
        fontSize: '1.25em',
        lineHeight: '1.4em',
      },
      subtitle1: { textTransform: 'unset' },
      body1: {
        fontSize: '1em',
        fontWeight: '300',
        lineHeight: 1.5,
        letterSpacing: '.5px',
      },
      body2: {
        fontSize: '.875rem',
        fontWeight: '300',
        lineHeight: 1.5,
        letterSpacing: '.5px',
      },
      overline: {
        fontSize: '.8em',
        fontWeight: 500,
        margin: '10px 0',
        lineHeight: 1,
      },
      button: { textTransform: 'none' },
    },
    MuiBadge: { badge: { fontWeight: 600 } },
    MuiFab: {
      root: {
        backgroundColor: brandColors.grey[200],
        color: brandColors.grey.main,
      },
    },
    MuiTab: { root: { textTransform: 'none' } },
    MuiTabs: {
      indicator: { backgroundColor: 'transparent' },
      scroller: { overflowX: 'auto' },
    },
    MuiPaper: {
      elevation3: {
        boxShadow: '0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.125) !important',
        border: `1px solid ${brandColors.grey[200]}`,
      },
      elevation4: {
        boxShadow: '0px 0px 1px -1px rgba(0,0,0,0.2), 0px -5px 0px 0px rgba(0,0,0,0.14), 0px 1px 16px 0px rgba(0,0,0,0.12)',
        border: `1px solid ${brandColors.grey[200]}`,
      },
    },
    MuiStepIcon: {
      text: {
        stroke: 'white',
        fontSize: '0.8rem',
      },
    },
    MuiStepper: { root: { padding: '1em' } },
    MuiChip: { root: { backgroundColor: brandColors.grey[200] } },
    MuiInput: { input: { fontSize: '14px' } },
  },
});
