import BarnService from '../../services/BarnService';
import QuestriUserService from '../../services/QuestriUserService';
import * as types from './types';
import { dispatchNetworkRequest } from '../networkDispatcher';

const getAllPagedBarnUsers = async (page, pageSize, allContent = []) => {
  const response = await QuestriUserService.getUsers(page, pageSize, '&sort=firstName,desc');
  const newAllContent = [...allContent, ...response.content];

  if (response.last) { return newAllContent; }
  return getAllPagedBarnUsers(page + 1, pageSize, newAllContent);
};

const getAllPagedBarnHorses = async (barnId, page, pageSize, sort, search, lodgingDate, lodgingDateEnd, locationId, allContent = []) => {
  const response = await BarnService.getBarnHorses(barnId, page, pageSize, sort, search, lodgingDate, lodgingDateEnd, locationId, false);
  const newAllContent = [...allContent, ...response.content];

  if (response.last) { return newAllContent; }
  return getAllPagedBarnHorses(barnId, page + 1, pageSize, sort, search, lodgingDate, lodgingDateEnd, locationId, newAllContent);
};

export const getBarns = () => (dispatch) => {
  const promise = BarnService.getBarns();
  dispatchNetworkRequest({
    dispatch,
    requestType: types.GET_BARNS_REQUESTED,
    successType: types.GET_BARNS_SUCCESS,
    errorType: types.GET_BARNS_ERROR,
    promise,
    defaultErrorMessage: 'There was a problem loading your barns',
    responseName: 'barns',
  });
  return promise;
};

export const getBarnNotices = (id) => (dispatch) => {
  const promise = BarnService.getBarnNotices(id);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.GET_BARN_NOTICES_REQUESTED,
    successType: types.GET_BARN_NOTICES_SUCCESS,
    errorType: types.GET_BARN_NOTICES_ERROR,
    promise,
    defaultErrorMessage: 'There was a problem loading barn notices',
    responseName: 'barnNotices',
  });
  return promise;
};

export const getBarnHorses = (id, page = 0, pageSize = 25, sort = '', search = '', lodgingDate = new Date(), lodgingDateEnd = new Date(), location = '', includeInactive = false) => (dispatch) => {
  const promise = BarnService.getBarnHorses(id, page, pageSize, sort, search, lodgingDate, lodgingDateEnd, location, includeInactive);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.GET_BARN_HORSES_REQUESTED,
    successType: types.GET_BARN_HORSES_SUCCESS,
    errorType: types.GET_BARN_HORSES_ERROR,
    promise,
    defaultErrorMessage: 'There was a problem loading horses for this barn',
    responseName: 'barnHorses',
  });
  return promise;
};

// eslint-disable-next-line no-unused-vars
export const getAllBarnHorses = (id, search = '', lodgingDate = new Date(), lodgingDateEnd = new Date(), location = '') => (dispatch) => {
  dispatch({ type: types.GET_ALL_BARN_HORSES_REQUESTED });

  const promise = getAllPagedBarnHorses(id, 0, 100, '', '', lodgingDate.toISOString(), lodgingDateEnd.toISOString(), location);

  promise.then((response) => {
    dispatch({
      type: types.GET_ALL_BARN_HORSES_SUCCESS,
      barnHorses: response,
    });
  }).catch((error) => {
    console.error(error);
    dispatch({
      type: types.GET_ALL_BARN_HORSES_ERROR,
      message: 'There was an error getting barn horses at this barn. Please contact Questri support.',
    });
  });

  return promise;
};

export const updateBarnHorse = (id, horseId, request) => (dispatch) => {
  const promise = BarnService.updateBarnHorse(id, horseId, request);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.UPDATE_BARN_HORSE_REQUESTED,
    successType: types.UPDATE_BARN_HORSE_SUCCESS,
    errorType: types.UPDATE_BARN_HORSE_ERROR,
    promise,
    successMessage: 'Successfully updated the barn horse',
    defaultErrorMessage: 'There was a problem updating the barn horse',
    responseName: 'updatedBarnHorse',
  });
  return promise;
};

export const updateBarn = (id, request) => (dispatch) => {
  const promise = BarnService.updateBarn(id, request);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.UPDATE_BARN_REQUESTED,
    successType: types.UPDATE_BARN_SUCCESS,
    errorType: types.UPDATE_BARN_ERROR,
    promise,
    successMessage: 'Successfully updated the barn',
    defaultErrorMessage: 'There was a problem updating the barn',
    responseName: 'updatedBarn',
  });
  return promise;
};

export const getBarnUsers = () => (dispatch) => {
  dispatch({ type: types.GET_BARN_USERS_REQUESTED });

  const promise = getAllPagedBarnUsers(0, 100);

  promise.then((response) => {
    dispatch({
      type: types.GET_BARN_USERS_SUCCESS,
      barnUsers: response,
    });
  }).catch((error) => {
    console.error(error);
    dispatch({
      type: types.GET_BARN_USERS_ERROR,
      message: 'There was an error getting barn users. Please contact Questri support.',
    });
  });
};

const getSubscriptionCommentForBarnManagement = (status, plan) => {
  if (status === 'active') {
    return `Your subscription is currently active. You have full access to ${plan}!`
  }
  if (status === 'trialing') {
    return `Your subscription is currently in a free trial. To continue using ${plan} after your trial ends, you must provide a valid payment method or you will lose access.`
  }
  if (status === 'past_due' || status === 'incomplete') {
    return `Your subscription is past due without being paid. You still have access to ${plan} but it will be removed if payment is not received.`
  }
  if (status === 'paused') {
    return 'Your subscription is paused. This happens when a payment method is not provided to Questri and your free trial never converts to a paid subscription. You can provide a payment method and start your paid subscription at any time.'
  }
  if (status === 'canceled') {
    return `Your subscription is cancled and you have no access to ${plan}.`
  }
  if (status === 'incomplete_expired') {
    return 'Your subscription invoice was not paid. Contact support@questri.app.'
  }
  return 'There is an unknown status on your Questri subscription, please contact support@questri.app.';
}

const getPlan = (priceId) => {
  if (priceId === env.stripe_client_access_annual_plan || priceId === env.stripe_client_access_monthly_plan) {
    return 'Questri Premium (Client Experience)'
  } if (priceId === env.stripe_barn_management_annual_plan || priceId === env.stripe_barn_management_monthly_plan) {
    return 'Professional - Barn Management'
  }
  return 'Horse Management - Pay Per Horse'
}

export const getSubscriptions = (barn) => (dispatch) => {
  const questriSubscription = {
    name: 'Questri Subscription',
    active: false,
    status: 'No Subscription',
    statusComment: 'Your account does not have a subscription. Purchase one to continue using Questri or contact support@questri.app.',
    freeTrialEndDate: null,
  }
  if (barn && barn.barnMetadata && barn.barnMetadata.stripeSubscription) {
    fetch(`/get-customer-subscription/${barn.barnMetadata.stripeSubscription}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.id) {
          const plan = getPlan(data.plan.price || data.plan.id)
          const trialEnd = (data.trial_end && new Date(data.trial_end * 1000)) || null;
          questriSubscription.name = plan;
          questriSubscription.freeTrialEndDate = trialEnd;
          questriSubscription.stripeSubscription = data;
          questriSubscription.active = data.status === 'active' || data.status === 'trialing' || data.status === 'past_due';
          questriSubscription.status = `${plan} subscription is ${data.status.replace('_', ' ')}`;
          questriSubscription.statusComment = getSubscriptionCommentForBarnManagement(data.status, plan);
          questriSubscription.currentPeriodStart = new Date(data.current_period_start * 1000);
          questriSubscription.currentPeriodEnd = new Date(data.current_period_end * 1000);

          dispatch({
            type: types.SET_QUESTRI_SUBSCRIPTION,
            questriSubscription,
          })
        } else {
          dispatch({
            type: types.SET_QUESTRI_SUBSCRIPTION,
            questriSubscription,
          })
        }
      });
  } else {
    dispatch({
      type: types.SET_QUESTRI_SUBSCRIPTION,
      questriSubscription,
    })
  }
}

