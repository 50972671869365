/* eslint-disable no-nested-ternary */
import moment from 'moment';
import 'moment-timezone';
import { EVENT_SUCCESS } from '@pingum/app-state-management/event/types';
import { getEvent } from '@pingum/app-state-management/event/actions';
import { EventService } from '@pingum/api-services';
import { groupBy } from 'lodash';
import * as types from './types';
import { getSchedule } from '../schedule/actions';
import {
  SET_SCHEDULE_DATA_FOR_MODALS,
  SET_SCHEDULE_EVENT_TASKS,
} from '../schedule/types';
import { dispatchNetworkRequest } from '../networkDispatcher';
import HorseService from '../../services/HorseService';
import { generateEventsFromSchedules } from '../../utils/scheduleHelper';

export const setSelectedBarnHorse = (barnHorse) => (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_BARN_HORSE,
    barnHorse,
  });
};

export const setTimezones = (contextTimezone) => (dispatch) => {
  if (contextTimezone) {
    moment.tz.setDefault(contextTimezone);
  } else {
    moment.tz.setDefault(); // reset to browser TZ on unmount
  }
  dispatch({
    type: types.SET_MY_TIMEZONE,
    myTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    contextTimezone: contextTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};

export const getBillableForTask = (horseId, taskId) => (dispatch) => {
  const promise = HorseService.getHorseBillableForTask(horseId, taskId);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.GET_BILLABLE_FOR_TASK_REQUESTED,
    successType: types.GET_BILLABLE_FOR_TASK_SUCCESS,
    errorType: types.GET_BILLABLE_FOR_TASK_ERROR,
    promise,
    defaultErrorMessage: 'There was a problem loading billable associated to this task',
    responseName: 'billableForTask',
  });
  return promise;
};

export const getNoteForTask = (horseId, taskId) => (dispatch) => {
  const promise = HorseService.getHorseNoteForTask(horseId, taskId);
  dispatchNetworkRequest({
    dispatch,
    requestType: types.GET_NOTE_FOR_TASK_REQUESTED,
    successType: types.GET_NOTE_FOR_TASK_SUCCESS,
    errorType: types.GET_NOTE_FOR_TASK_ERROR,
    promise,
    defaultErrorMessage: 'There was a problem loading note associated to this task',
    responseName: 'noteForTask',
  });
  return promise;
};

const getAssociatedDataForSelectedTask = (task, selectedBarn, barnHorsesMap, dispatch) => {
  const horseTag = task && task.tags && task.tags.find((t) => t.tagType.id === selectedBarn.barnMetadata.horseTagTypeId);
  if (task && task.id && horseTag && barnHorsesMap[horseTag.id]) {
    // load any associated horse billable and/or note
    dispatch(getBillableForTask(barnHorsesMap[horseTag.id][0].horse.id, task.id))
    dispatch(getNoteForTask(barnHorsesMap[horseTag.id][0].horse.id, task.id))
  }
}

const getAssociatedTasks = (start, scheduleId, dispatch, selectedBarn, barnHorsesMap, openModalType, task) => {
  EventService.searchEvents({
    startDate: moment(start.getTime()).add(-46, 'minutes'), // Go back for 45 min advance time lookup
    endDate: moment(start.getTime()).add(1, 'minutes'), // tasks created from schedule should trigger at schedule start
    scheduleId,
    includeArchived: true,
    includeComplete: true,
    searchText: '',
    sortStartDateDesc: true,
    statusIds: [],
    tagConditions: [],
  }, 0, 100, true).then((data) => {
    dispatch({
      type: SET_SCHEDULE_EVENT_TASKS,
      eventTasks: data.content,
    })
    if (openModalType === types.TASK_MODAL_TYPE && task === null && data.content.length > 0) {
      // default select first one
      dispatch({
        type: EVENT_SUCCESS,
        event: data.content[0] || {},
      })
      getAssociatedDataForSelectedTask(data.content[0], selectedBarn, barnHorsesMap, dispatch)
    }
  })
}

export const setGlobalModals = (
  passedInOpenModalType,
  task,
  schedule,
  event,
  {
    locationId = null, // if set, this is a calendar event
    taskTagIds = [],
    eventTemplateTagTypeFilter = -1,
    openInSeries = true,
    startDate = null,
    duration = null,
    assignment = null,
    assignmentTaskMap = null,
    workflows = null,
    rescheduleDate = null,
  },
  callback,
) => (dispatch, getState) => {
  let openModalType = passedInOpenModalType;
  const {
    barn: {
      selectedBarn,
      barnHorsesMap,
    },
    status: { statuses },
  } = getState();
  const mappedStatuses = groupBy(statuses, 'id')

  /*
    If modal type is Schedule  event is passed in as NON event type and in past, we always show task modal
  */
  if (event && (event.inPast || event.activeNow) && !event.eventTask && !rescheduleDate) {
    openModalType = types.TASK_MODAL_TYPE
  }

  dispatch({
    type: types.SET_GLOABL_MODALS,
    openModalType,
    callback,
  });
  dispatch({
    type: SET_SCHEDULE_EVENT_TASKS,
    eventTasks: [], // reset this field
  })

  let taskPromise = null;
  let schedulePromise = null;

  if (task && task.id) {
    if (!task.name) {
      // We only got the ID so we need to get the full object from the API
      taskPromise = dispatch(getEvent(task.id));
      taskPromise.then((e) => {
        // set selected event≈
        if ((!schedule || !schedule.id) && e.scheduleId) {
          schedulePromise = dispatch(getSchedule(e.scheduleId, new Date(e.startDate), new Date(new Date(e.startDate).getTime() + 1000)))
        }
        // if the original task passed in is empty (just id) and no event passed in, load associated data for tasks page
        if (e && e.id && (task && task.id && !task.name)) {
          getAssociatedTasks(
            new Date(e.startDate),
            e.scheduleId,
            dispatch,
            selectedBarn,
            barnHorsesMap,
            openModalType,
            task,
          )
          getAssociatedDataForSelectedTask(e, selectedBarn, barnHorsesMap, dispatch)
        }
      })
    } else {
      // Full object was passed in, we can return that
      taskPromise = Promise.resolve(task)
    }
  } else {
    taskPromise = Promise.resolve(null)
  }

  if (schedule && schedule.id && !(task && task.id && !task.name)) {
    schedulePromise = Promise.resolve(schedule)
  } else if (task && task.scheduleId) {
    schedulePromise = dispatch(getSchedule(task.scheduleId, new Date(task.startDate), new Date(new Date(task.startDate).getTime() + 1000)))
  } else {
    schedulePromise = Promise.resolve(null)
  }

  schedulePromise.then((selectedSchedule) => {
    let eventToSelect = event;
    if (eventToSelect && eventToSelect.start) {
      eventToSelect = { ...event }
    } else if (task && task.id) {
      const generatedEvents = generateEventsFromSchedules(
        [selectedSchedule],
        [],
        new Date(task.startDate),
        new Date(new Date(task.startDate).getTime() + 1000),
        {},
        selectedBarn.barnMetadata,
        mappedStatuses,
      )
      eventToSelect = generatedEvents && generatedEvents.length === 1 && generatedEvents[0]
    }
    dispatch({
      type: SET_SCHEDULE_DATA_FOR_MODALS,
      schedule: selectedSchedule,
      event: eventToSelect,
      scheduleConfig: {
        locationId,
        taskTagIds,
        eventTemplateTagTypeFilter,
        openInSeries,
        startDate,
        duration,
        assignment,
        assignmentTaskMap,
        workflows,
        rescheduleDate,
      },
    });
  })

  if ((event && event.start) || (task && task.scheduleId)) {
    // load data if we have event, or if we only have task id (means we are on task page)
    getAssociatedTasks(
      (event && event.start) || new Date(task.startDate),
      (event && event.resource && event.resource.id) || (schedule && schedule.id) || task.scheduleId,
      dispatch,
      selectedBarn,
      barnHorsesMap,
      openModalType,
      task,
    )
  }

  taskPromise.then((selectedEvent) => {
    dispatch({
      type: EVENT_SUCCESS,
      event: selectedEvent || {},
    })
    getAssociatedDataForSelectedTask(selectedEvent, selectedBarn, barnHorsesMap, dispatch)
  })
}

export const setSelectedTaskForSchedule = (task) => (dispatch, getState) => {
  const { schedule: { event } } = getState();

  // Only select if task is part of currently selected schedule
  if (task && task.scheduleId && event && event.resource && event.resource.id === task.scheduleId) {
    dispatch({
      type: EVENT_SUCCESS,
      event: task || {},
    })
  }
}
