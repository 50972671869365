import moment from 'moment';

export const formatDateOnly = (date) => moment(date).format('l');
export const formatDate = (date) => moment(date).format('l h:mma');
export const formatDateString = (dateString) => {
  let returnDateString = '';
  const dt = moment(dateString);
  const midnight = dt.hours() === 0 && dt.minutes() === 0 && dt.seconds() === 0;

  try {
    returnDateString = dt.calendar(null, {
      sameDay: midnight
        ? '[Today]'
        : 'h:mma',
      lastDay: midnight
        ? '[Yesterday]'
        : '[Yesterday] h:mma',
      lastWeek: 'l',
      sameElse: 'l',
    });
  } catch (e) {
    // Unable to format date string
  }
  return returnDateString;
};
export const getRelativeTime = (start, end) => moment.duration(moment(end || moment.now()).diff(moment(start || moment.now()))).humanize();
export const getTodayAtMidnight = () => {
  const todayAtMidnight = new Date();
  todayAtMidnight.setHours(0, 0, 0, 0);
  return todayAtMidnight;
};
export const getThisWeekAtMidnight = () => {
  const today = new Date();
  const weekAtMidnight = new Date(today.setDate(today.getDate() - today.getDay()));
  weekAtMidnight.setHours(0, 0, 0, 0);
  return weekAtMidnight;
};
export const getThisMonthAtMidnight = () => {
  const today = new Date();
  const monthAtMidnight = new Date(today.getFullYear(), today.getMonth(), 1);
  monthAtMidnight.setHours(0, 0, 0, 0);
  return monthAtMidnight;
};

export const daysRemaining = (checkDate) => {
  const eventdate = moment(checkDate);
  const todaysdate = moment();
  return eventdate.diff(todaysdate, 'days');
};

export const durationAsString = (ms, maxPrecission = 3) => {
  const duration = moment.duration(ms);

  const items = [];
  items.push({
    timeUnit: 'd',
    value: Math.floor(duration.asDays()),
  });
  items.push({
    timeUnit: 'h',
    value: duration.hours(),
  });
  items.push({
    timeUnit: 'm',
    value: duration.minutes(),
  });

  const formattedItems = items.reduce((accumulator, {
    value,
    timeUnit,
  }) => {
    if (accumulator.length >= maxPrecission || (accumulator.length === 0 && value === 0)) {
      return accumulator;
    }

    accumulator.push(`${value}${timeUnit}`);
    return accumulator;
  }, []);

  return formattedItems.length !== 0
    ? formattedItems.join(' ')
    : '-';
};

export const getTimeZoneOffset = (timeZone) => {
  const now = moment();
  const localOffset = now.utcOffset();
  now.tz(timeZone); // your time zone, not necessarily the server's
  const centralOffset = now.utcOffset();
  const diffInMinutes = localOffset - centralOffset;
  return diffInMinutes * 60000
}
