import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useAuth0 } from '@auth0/auth0-react';

import loginLogo from '../../../images/unused-undraw-illustrations/undraw_secure_login_pdn4.svg';
import { removeQueryParam } from '../../../utils/urlHelper';

const defaultCardStyle = {
  width: '500px',
  margin: 'auto',
  maxWidth: '90vw',
};

const getHeader = (error, description) => {
  if (description === 'Please verify your email before logging in.') {
    return 'Email Verification Required'
  } if (description && description.indexOf('already has a') >= 0) {
    return 'Questri Has Linked Your Accounts';
  }
  return 'Authentication Error'
}
const getTitle = (error, description) => {
  if (description === 'Please verify your email before logging in.') {
    return 'Verify Your Email First'
  } if (description && description.indexOf('already has a') >= 0) {
    return 'The email your provided to login or signup already exists in Questri.';
  }
  return error || 'Error Logging In'
}

const getDescription = (error, description) => {
  if (description === 'Please verify your email before logging in.') {
    return 'You will receive and email at the address you used when you signed up. Note that it can sometimes take a few minutes for this email to arrive!'
  } if (description && description.indexOf('already has a') >= 0) {
    return description;
  }
  return error || 'There was an error logging in, please try again. If the issue persists, please contact support.'
}

const LimitedAccessCardComponent = ({ query: parmQuery }) => {
  const [query, setQuery] = React.useState({});
  const { loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    if (parmQuery && parmQuery.error && parmQuery.error_description) {
      setQuery(parmQuery);
      removeQueryParam('error')
      removeQueryParam('error_description')
      removeQueryParam('state')
    }
  }, [parmQuery])

  return (
    <Box sx={{ mt: 2 }}>
      <Card style={defaultCardStyle}>
        <CardHeader title={getHeader(query.error, query.error_description)} />
        <CardContent>
          <Typography variant="h5">{getTitle(query.error, query.error_description)}</Typography>
          <Typography
            gutterBottom
            sx={{
              mt: 4,
              mb: 2,
            }}
          >
            {getDescription(query.error, query.error_description)}
          </Typography>
          <Box
            sx={{
              textAlign: 'center',
              m: 4,
            }}
          >
            <img
              src={loginLogo}
              alt="woman with laptop"
              style={{
                width: 300,
                display: 'block',
                margin: 'auto',
                marginBottom: '1rem',
              }}
            />
          </Box>
          {
            (query.error_description === 'Please verify your email before logging in.') && (
              <Box
                sx={{
                  textAlign: 'center',
                  mt: 4,
                }}
              ><Button variant="contained" onClick={() => { window.location.href = '/' }}>I Have Verified My Email</Button>
              </Box>
            )
          }
          {
            (query.error_description && query.error_description.indexOf('already has a') >= 0) && (
              <Box
                sx={{
                  textAlign: 'center',
                  mt: 4,
                }}
              >
                <Typography gutterBottom variant="caption" textAlign="center">
                  To ensure the security of your accounts after linking, you will need to sign in again.
                </Typography>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    loginWithRedirect({ appState: { returnTo: window.location.pathname + window.location.search } });
                  }}
                >
                  Log In Again
                </Button>
              </Box>
            )
          }
        </CardContent>
      </Card>
    </Box>
  )
}

LimitedAccessCardComponent.propTypes = { query: PropTypes.any };

LimitedAccessCardComponent.defaultProps = { query: {} };

export default LimitedAccessCardComponent;
