// EVENT_TEMPLATE_REMINDER
export const GET_EVENT_TEMPLATE_REMINDER_REQUESTED = 'GET_EVENT_TEMPLATE_REMINDER_REQUESTED';
export const GET_EVENT_TEMPLATE_REMINDER_SUCCESS = 'GET_EVENT_TEMPLATE_REMINDER_SUCCESS';
export const GET_EVENT_TEMPLATE_REMINDER_ERROR = 'GET_EVENT_TEMPLATE_REMINDER_ERROR';
export const UPDATE_EVENT_TEMPLATE_REMINDER_REQUESTED = 'UPDATE_EVENT_TEMPLATE_REMINDER_REQUESTED';
export const UPDATE_EVENT_TEMPLATE_REMINDER_SUCCESS = 'UPDATE_EVENT_TEMPLATE_REMINDER_SUCCESS';
export const UPDATE_EVENT_TEMPLATE_REMINDER_ERROR = 'UPDATE_EVENT_TEMPLATE_REMINDER_ERROR';
export const ADD_EVENT_TEMPLATE_REMINDER_REQUESTED = 'ADD_EVENT_TEMPLATE_REMINDER_REQUESTED';
export const ADD_EVENT_TEMPLATE_REMINDER_SUCCESS = 'ADD_EVENT_TEMPLATE_REMINDER_SUCCESS';
export const ADD_EVENT_TEMPLATE_REMINDER_ERROR = 'ADD_EVENT_TEMPLATE_REMINDER_ERROR';
export const DELETE_EVENT_TEMPLATE_REMINDER_REQUESTED = 'DELETE_EVENT_TEMPLATE_REMINDER_REQUESTED';
export const DELETE_EVENT_TEMPLATE_REMINDER_SUCCESS = 'DELETE_EVENT_TEMPLATE_REMINDER_SUCCESS';
export const DELETE_EVENT_TEMPLATE_REMINDER_ERROR = 'DELETE_EVENT_TEMPLATE_REMINDER_ERROR';

// TAG REMINDERS
export const GET_EVENT_TEMPLATE_REMINDER_TAG_REMINDERS_REQUESTED = 'GET_EVENT_TEMPLATE_REMINDER_TAG_REMINDERS_REQUESTED';
export const GET_EVENT_TEMPLATE_REMINDER_TAG_REMINDERS_SUCCESS = 'GET_EVENT_TEMPLATE_REMINDER_TAG_REMINDERS_SUCCESS';
export const GET_EVENT_TEMPLATE_REMINDER_TAG_REMINDERS_ERROR = 'GET_EVENT_TEMPLATE_REMINDER_TAG_REMINDERS_ERROR';
